import React from "react";
import arrowDown from "../../assets/images/icons/icon-angle-down.png";
import deleteicon from "../../assets/images/icons/trash.svg";
import xicon from "../../assets/images/icons/icon-x.png";

//Columns for American signature portal table.
export const asiColumns = (updateQuantity) => [
	{ title: "Item", value: "Name" },
	{
		title: "Dimensions (in)",
		value: "Name",
		formatter: (data, i) => {
			return data.Width && data.Depth && data.Height ? (
				<>
					{data.Width?.split(" ")[0]} W x {data.Depth?.split(" ")[0]} D x{" "}
					{data.Height?.split(" ")[0]} H
				</>
			) : (
				"NA"
			);
		},
	},
	{
		title: (
			<React.Fragment>
				Volume (ft<sup>3</sup>)
			</React.Fragment>
		),
		value: "Volume",
		formatter: (data, i) => {
			return data.Volume ?? "N/A";
		},
	},
	{
		title: "Weight (lbs)",
		value: "Weight",
		formatter: (data, i) => {
			return data.Weight ? data.Weight?.split(" ")[0] : "N/A";
		},
	},
	{
		title: "Quantity",
		value: "Quantity",
		formatter: (data, i) => {
			return (
				<React.Fragment>
					<input
						type="text"
						autoComplete="off"
						id={"quantity_" + i}
						name={"quantity_" + i}
						className="quantity"
						data-index={i}
						value={data.Quantity}
					/>
					<div className="counter">
						<img
							src={arrowDown}
							className="close-details decrement"
							data-field="decrement"
							onClick={(e) => updateQuantity(i, 'DEC')}
							data-index={i}
							alt="Arrow Down"
						/>
						<img
							src={arrowDown}
							className="increment"
							data-field="increment"
							onClick={(e) => updateQuantity(i, 'INC')}
							data-index={i}
							alt="Arrow Up"
						/>
					</div>
				</React.Fragment>
			);
		},
	},
	{
		title: "",
		value: "",
		formatter: (data, i) => {
			return (
				<React.Fragment>
					<div
						onClick={() => updateQuantity(i, 'REMOVE')}
						className="cursor-pointer"
					>
						<img src={deleteicon} alt="Remove" />
					</div>
				</React.Fragment>
			);
		},
	},
];

//Columns for bestbuy portal which has SKU.
export const skuColumns = (updateQuantity) => [
	{ title: "SKU", value: "SKU" },
	{ title: "Item", value: "Name" },
	{
		title: "Quantity",
		value: "Quantity",
		formatter: (data, i) => {
			return (
				<React.Fragment>
					<input
						type="text"
						autoComplete="off"
						id={"quantity_" + i}
						name={"quantity_" + i}
						className="quantity"
						onChange={(e) => updateQuantity(i, 'INC')}
						data-index={i}
						value={data.Quantity}
						onKeyDown={(e) => updateQuantity(i, 'INC')}
					/>
					<div className="counter">
						<img
							src={arrowDown}
							className="close-details decrement"
							data-field="decrement"
							onClick={(e) => updateQuantity(i, 'DEC')}
							data-index={i}
							alt="Arrow Down"
						/>
						<img
							src={arrowDown}
							className="increment"
							data-field="increment"
							onClick={(e) => updateQuantity(i, 'INC')}
							data-index={i}
							alt="Arrow Up"
						/>
					</div>
				</React.Fragment>
			);
		},
	},
	{
		title: "",
		value: "",
		formatter: (data, i) => {
			return (
				<React.Fragment>
					<div onClick={() => updateQuantity(i, 'REMOVE')} className="delete">
						<img src={xicon} alt="Remove" />
					</div>
				</React.Fragment>
			);
		},
	},
];

// UI description
export const UITypeDesc = [
	undefined,
	{
		name: "PickupNote",
		type: "text",
	},
	{
		name: "ItemsToDeliver",
		type: "text",
		description: "sku",
		maxQtyAllowed: 5,
		showTable: true,
		tableClass: "sku-table mt-4",
		tableHeader: (updateQty) => skuColumns(updateQty),
	},
	{
		name: "ItemsToDeliver",
		type: "text",
		showTootip: true,
	},
	{
		name: "ItemsToDeliver",
		type: "select",
		description: "pallet",
		maxQtyAllowed: 99,
		showTable: true,
		tableClass: "items-table mt-4",
		tableHeader: (updateQty) => asiColumns(updateQty),
		tableFooter: (
			DropDownItems,
			{ totalItemsWeight, MaxItemWeight, totalItemsVolume, MaxItemVolume },
		) => (
			<tfoot
				className={
					DropDownItems[0].Weight && DropDownItems[0].Volume ? "" : "d-none"
				}
			>
				{
					<tr>
						<td>Total:</td>
						<td>
							{
								// If 1st item has no weight, don't show total weight count
								DropDownItems[0].Weight && (
									<>
										Weight = {totalItemsWeight}
										{MaxItemWeight ? `/${MaxItemWeight}` : ""} lbs
									</>
								)
							}
						</td>
						<td colSpan="4">
							{
								// If 1st item has no volume, don't show total volume count
								DropDownItems[0].Volume && (
									<>
										Volume = {totalItemsVolume}
										{MaxItemVolume ? `/${MaxItemVolume}` : ""} (ft<sup>3</sup>)
									</>
								)
							}
						</td>
					</tr>
				}
			</tfoot>
		),
	},
	{
		name: "ItemsToDeliver",
		type: "text",
		showTootip: true,
	},
];