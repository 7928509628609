import moment from 'moment-timezone';
import { dateUtils } from '../utilities/dateUtils';

/* Redux Import Start */
import {store} from '../redux/store';
/* Redux Import End */

/**
 * getWorkingHours()
 * This function is used to get final working timings after intersaction of bugii and portal timings
 */
export const getWorkingHours = () => {
    const {appSetting} = store.getState().common;
    const {pickupDate} = store.getState().getEstimate;
    const {ScheduledPickupFrom, ScheduledPickupTo} = appSetting.BungiiSettings;
    const {PartnerOperationHours} = appSetting.PartnerLocationSettings;
    const bungiiStartTime = ScheduledPickupFrom;
    const bungiiEndTime = ScheduledPickupTo;
    let portalStartTime = null, 
        portalEndTime = null;
         
     
    if(PartnerOperationHours.length > 0){
        const weekArray = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        const dayOfWeek = moment(dateUtils.getDate(new Date(pickupDate))).format("dddd");  
        var dataRes = PartnerOperationHours.filter((day,i) => (day.Day === dayOfWeek));
        

        if(dataRes.length === 0){
            var index = weekArray.indexOf(dayOfWeek)
            var isPresent = false;
            for(let i = index+1; i < weekArray.length; i++){
                isPresent = PartnerOperationHours.filter((val) => val.Day === weekArray[i]).length > 0
                if(isPresent) {
                    dataRes = PartnerOperationHours.filter((val) => val.Day === weekArray[i]);
                    break;
                }
            }
            if(!isPresent){
                for(let i = 0; i < index-1; i++){
                    isPresent = PartnerOperationHours.filter((val) => val.Day === weekArray[i]).length > 0
                    if(isPresent) {
                        dataRes = PartnerOperationHours.filter((val) => val.Day === weekArray[i]);
                        break;
                    }
                }
            }
            
        }
        portalStartTime = dataRes[0].StartTime;
        portalEndTime = dataRes[0].CloseTime;
    }
    if((!portalStartTime && !portalEndTime)) {
        return {
            WorkingHoursStart: bungiiStartTime,
            WorkingHoursEnd: bungiiEndTime
        }
    }
    
    const WorkingHoursStart = (bungiiStartTime > portalStartTime) ? bungiiStartTime : portalStartTime;
    const WorkingHoursEnd = (bungiiEndTime < portalEndTime) ? bungiiEndTime : portalEndTime;
    
    return {
        WorkingHoursStart,
        WorkingHoursEnd
    }
}

