/* Redux Import Start */
import {store} from '../redux/store';

/* Redux Import End */

const defaultGeofence = () => {
    const { appSetting } = store.getState().common;
    for (let i = 0; i < appSetting.GeofenceSettings.length; i++) {
        if(appSetting.GeofenceSettings[i]['IsDefault'] === true){
            return i;
        }
    }
}

export default defaultGeofence;