import React, { createRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import appConfig from "../shared/appConfig/appConfig";
import {
	handleGetReq,
	handlePostReq,
	handlePatchReq,
} from "../shared/services/AppendHeadersService";
import DropIn from "braintree-web-drop-in-react";
import toasterService from "../shared/services/ToasterService";
import languageConstants from "../shared/appConfig/languageConstants";
import { dateUtils } from "../shared/utilities/dateUtils";
import Password from "../shared/layouts/common/Password";
import AuthService from "../shared/services/AuthService";
import clearAppsettingData from "../shared/helpers/clearAppsettingData";
import ReactGA from "react-ga4";
import PickupExists from "../shared/layouts/common/PickupExists";
import defaultGeofence from "../shared/helpers/defaultGeofence";
import { withStyles } from "@material-ui/core/styles";
import "react-tagsinput/react-tagsinput.css";

/* Redux Import Start */
import { connect } from "react-redux";
import {
	setActiveTab,
	setShowLoader,
	setFormIsFilled,
	setKioskMode,
	setAppSetting,
	setCommonInitialState,
} from "../shared/redux/actions/common.actions";
import {
	setPickupRequestID,
	setPickupAdd,
	setDropoffAdd,
	resetDataOnPageChange,
} from "../shared/redux/actions/getEstimate.actions";
import {
	setPickupDetails,
	setLoadingUnloadingTime,
	setNoOfDrivers,
	setScheduledTimestamp,
	setLastFour,
	setPaymentMethodSelected,
	setOrderNumber,
	setExternalIdLabel,
	setDeliveryDetailsInitialState,
	setPickupToken,
	setBackToQuote,
} from "../shared/redux/actions/deliveryDetails.actions";
import { withRouter } from 'react-router-dom';
import Summary from "../Components/Summary";
import DeliveryDetailsForm from './Forms';
import { sendEvent } from "../shared/utilities/sendEvent";
import globalConstants from "../shared/appConfig/globalConstants";

/* Redux Import End */

const styles = {
	formControl: {
		minWidth: 220,
	},
};

class DeliveryDetails extends React.Component {
	constructor(props) {
		super(props);
		this.formikRef = createRef();
		this.state = {
			showPaymentMethod:
				this.props.appSetting.PartnerLocationSettings
					.PaymentMethodSelectionRequired,
			paymentMethod: this.props.quickAccessUser
				? [{ PaymentCode: "CC", IsDefault: true }]
				: this.props.appSetting.PartnerLocationSettings.PaymentMethods,
			nonce: "",
			open: false,
			clickedPay: false,
			clickedKioskMode: false,
			clickedtrackDeliveries: false,
			selectedPayMethod: "",
			category: "",
			action: "",
			label: "",
			isVisibleSoloDuo:
				this.props.appSetting.PartnerLocationSettings.EstimatePageFields[0]
					.IsVisible,
			disclaimerUnchecked: "",
			isValid : false,
			errors: {
				password: "",
			},
			whatsNeededData:
				this.props.appSetting.PartnerLocationSettings.EstimatePageFields[0],
			showAlertModal: false,
			disableScheduleBtn: false,
		};
		this.onPasswordChange = this.onPasswordChange.bind(this);
	}

	/**
	 * sendEvent()
	 * This function is used to send trigger event to GA
	 */
	sendEvent = async (val) => {
		await this.setState({
			category: `CTA ${val} Button`,
			action: "Click",
			label: val,
		});
		ReactGA.event(this.state);
	};


	/**
	 * componentDidMount()
	 * This function will be called only after the first render.
	 */
	componentDidMount = async () => {
		try {
			const {
				accessToken,
				pickupRequestID,
				setPickupDetails,
				setLoadingUnloadingTime,
				setPaymentMethodSelected,
				setShowLoader,
				orderRef,
				tripType,
				setBackToQuote,
				isBoxTruck,
			} = this.props;
			if (pickupRequestID || orderRef) {
				setShowLoader(true);
				let estimateResponse = await handleGetReq(
					appConfig.urls.pickupDetails,
					(tripType > 2 && !isBoxTruck) ? {orderref: orderRef} : { pickuprequestid: pickupRequestID },
					accessToken
				);
				/**
				 * Set redux state
				 */
				setPickupDetails(estimateResponse.data.PickupDetails);
				setLoadingUnloadingTime(
					estimateResponse.data.PickupDetails.LoadingUnloadingTime
				);
				this.state.paymentMethod.map((val, index) => {
					if (val.IsDefault === true) {
						setPaymentMethodSelected(
							this.state.paymentMethodSelected
								? this.state.paymentMethodSelected
								: this.state.paymentMethod[index].PaymentCode
						);
					}
					return true;
				});

				await this.setState({
					paymentMethodSelected: this.state.paymentMethodSelected
						? this.state.paymentMethodSelected
						: this.state.paymentMethod[0].PaymentCode,
					serviceName: estimateResponse.data.PickupDetails.PickupServiceLevel
						? estimateResponse.data.PickupDetails.PickupServiceLevel.ServiceName
						: "",
					serviceDescription: estimateResponse.data.PickupDetails
						.PickupServiceLevel
						? estimateResponse.data.PickupDetails.PickupServiceLevel
							.PartnerServiceNameDescription
						: "",
				});

				await this.getPickupDetails();
				if (this.state.paymentMethodSelected === "CC") {
					await this.showCard();
				}
				setBackToQuote(false)
				setShowLoader(false);
			}
			await this.showDriverHelpCarryField();
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * updatedisclaimerFieldMessage()
	 * This function is used to update the disclaimer messages.
	 */
	updatedisclaimerFieldMessage = async () => {
		try {
			const { appSetting, defaultGeofenceIndex, loadingUnloadingTime } =
				this.props;
			let pickupCostPerMinute =
				appSetting.GeofenceSettings[defaultGeofenceIndex].PickupCostPerMinute;
			let pickupCostPerMile =
				appSetting.GeofenceSettings[defaultGeofenceIndex].PickupCostPerMile;
			let loadingUnloadingTimeVal = Math.floor(
				loadingUnloadingTime / (1000 * 60)
			); /*convert milliseconds to minutes*/
			let loadingUnloadingCost = pickupCostPerMinute * loadingUnloadingTimeVal;
			let myString = this.state.disclaimerField,
				replaceArray = [
					"pickupCostPerMinute",
					"pickupCostPerMile",
					"loadingUnloadingTime",
					"loadingUnloadingCost",
				],
				replaceWith = [
					pickupCostPerMinute,
					pickupCostPerMile,
					loadingUnloadingTimeVal,
					loadingUnloadingCost,
				];
			let that = this;
			replaceArray.forEach(function (value, i) {
				myString = myString.replace(
					new RegExp("{{" + replaceArray[i] + "}}"),
					replaceWith[i]
				);
				that.setState({
					disclaimerField: myString,
				});
			});
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * getPickupDetails()
	 * This function is used to fetch the pickup details.
	 */
	getPickupDetails = async () => {
		try {
			const { pickupDetails, setNoOfDrivers, setScheduledTimestamp } =
				this.props;
			const {
				ScheduledTimestamp: scheduledTimestamp,
				TimezoneIdentifier: timezoneIdentifier,
				TimezoneDisplayName: timezoneDisplayName,
			} = pickupDetails;
			let scheduledDatetime = dateUtils.convertTimezone(
				scheduledTimestamp,
				timezoneIdentifier,
				`MMM DD [at] h:mm A [${timezoneDisplayName}]`
			);
			/**
			 * Set redux state
			 */
			setNoOfDrivers(pickupDetails.NoOfDrivers);
			setScheduledTimestamp(scheduledDatetime);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * showCard()
	 * This function is used to show the payment card.
	 */
	showCard = async () => {
		const { accessToken, setShowLoader } = this.props;
		setShowLoader(true);
		/*getPaymentToken*/
		try {
			const getPaymentToken = await handleGetReq(
				appConfig.urls.getPaymentToken,
				"",
				accessToken
			);
			this.setState({
				clientToken: getPaymentToken.data.ClientToken,
			});
		} catch (e) {
			console.log(e);
		}
		setShowLoader(false);
	};

	/**
	 * onRadioButtonChange()
	 * This Function is called when the user clicks on the payment option radio buttons.
	 */
	onRadioButtonChange = async (e) => {
		e.persist();
		const { setShowLoader } = this.props;

		try {
			setShowLoader(true);

			const { setPaymentMethodSelected, kioskMode } = this.props;
			if (e.target.value !== "CC") {
				if (kioskMode === false) {
					await this.setState({
						open: true,
						selectedPayMethod: e.target.value,
						clickedPay: true,
					});
				} else {
					await this.setState({
						selectedPayMethod: e.target.value,
					});
					/**
					 * Set redux states
					 */
					setPaymentMethodSelected(e.target.value);
					this.paymentMode();
				}
			} else {
				await this.setState({
					selectedPayMethod: e.target.value,
				});
				/**
				 * Set redux states
				 */
				setPaymentMethodSelected(e.target.value);
				this.paymentMode();
			}
			setShowLoader(false);
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * paymentMode()
	 * This function is used to change the paymentMode based on the user selection.
	 */
	paymentMode = async (e) => {
		const { setShowLoader } = this.props;
		setShowLoader(true);
		toasterService.hideCustomToasty();
		try {
			const { accessToken } = this.props;
			if (this.state.selectedPayMethod === "CC") {
				/*getPaymentToken*/
				try {
					if (
						this.state.clientToken === null ||
						this.state.clientToken === ""
					) {
						const getPaymentToken = await handleGetReq(
							appConfig.urls.getPaymentToken,
							"",
							accessToken
						);
						this.setState({
							clientToken: getPaymentToken.data.ClientToken,
						});
					}
				} catch (e) {
					console.log(e);
				}
				await this.showCard();
			} else {
				this.showDriverHelpCarryField();
			}

			setShowLoader(false);
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * addCard()
	 * This function is called when you enter the card details and then click on "Schedule Bungii".
	 */
	addCard = async () => {
		const { setLastFour, paymentMethodSelected } = this.props;
		if (paymentMethodSelected === "CC") {
			toasterService.hideCustomToasty();
			try {
				/*Send the nonce to your server*/
				let cardResponse = await this.instance.requestPaymentMethod();
				const nonce = cardResponse.nonce;
				const lastFour = cardResponse.details.lastFour;
				/**
				 * Set redux states
				 */
				setLastFour(lastFour);

				await this.setState({
					nonce,
				});
				return true;
			} catch (e) {
				console.log(e);
				return false;
			}
		} else {
			return true;
		}
	};


	/**
	 * submitDetails()
	 * This function is called on click of "Schedule Bungii" button.
	 */
	submitDetails = async (values) => {
		toasterService.hideCustomToasty();
		try {
			const { setShowLoader } = this.props;
				if (
					this.state.showCheckbox &&
					document.getElementById("DisclaimerField").checked === false
				) {
					this.setState({ disclaimerUnchecked: true });
				} else if (!this.state.showCheckbox) {
					await setShowLoader(true);
					await this.performUpdateDeliveryInformationAPIRequest(values);
					await setShowLoader(false);
				} else if (
					this.state.showCheckbox &&
					document.getElementById("DisclaimerField").checked === true
				) {
					await setShowLoader(true);
					await this.performUpdateDeliveryInformationAPIRequest(values);
					await setShowLoader(false);
				} else {
					if (this.state.showCheckbox) {
						this.setState({ disclaimerUnchecked: true });
					}
				}
			 
		} catch (e) {
			console.log(e);
		}
	};

	/**
	 * performUpdateDeliveryInformationAPIRequest()
	 * This function is used to fire the API request.
	 */
	performUpdateDeliveryInformationAPIRequest = async (values) => {
		const {
			pickupRequestID,
			accessToken,
			setActiveTab,
			paymentMethodSelected,
			setShowLoader,
			setFormIsFilled,
			appSetting,
			setExternalIdLabel,
			setOrderNumber,
			setPickupToken,
			tripType,
			orderRef,
			isBoxTruck,
		} = this.props;
		toasterService.hideCustomToasty();

		if (!(await this.addCard())) {
			return false;
		}

		let DisplayOnSuccessPageField = appSetting.PartnerLocationSettings?.StaticFields?.find(field =>field.DisplayOnSuccessPage)	
		
		if(DisplayOnSuccessPageField){
			let orderNumber = values.StaticFields.find(field => field.FieldRef === DisplayOnSuccessPageField.FieldRef)?.FieldValue
			setExternalIdLabel(DisplayOnSuccessPageField.DisplayName)
			setOrderNumber(orderNumber);
		}
		delete values.skuNumber;
		delete values.UIType;

		let body = {
			...values,
			StaticFields: values.StaticFields.map((field)=>{
				return {FieldRef : field.FieldRef, FieldValue: field.FieldValue}
			}),
			CustomFields: values.CustomFields.map((field)=>{
				return {FieldRef : field.FieldRef, FieldValue: field.FieldValue}
			}),
			PaymentOption: paymentMethodSelected,
			PaymentMethodNonce: this.state.nonce ?? null,
		};

		(tripType > 2 && !isBoxTruck) ? body.OrderRef = orderRef : body.PickupRequestID = pickupRequestID;

		setShowLoader(true);
		this.setState({
			disableScheduleBtn: true,
		});
		let deliveryInformation = await handlePatchReq(
			appConfig.urls.deliveryInformation,
			body,
			accessToken
		);
		const paymentFailureCodes = [
			languageConstants.errors.paymentFailure.code,
			languageConstants.errors.paymentPreAuthFailure1.code,
			languageConstants.errors.paymentPreAuthFailure2.code,
		];
		if (
			deliveryInformation !== undefined &&
			deliveryInformation.data.Error === null
		) {
			let bodyPickupConfirm = (tripType > 2 && !isBoxTruck) ? {
				OrderRef: orderRef,
			} : { PickupRequestID: pickupRequestID };

			let pickupConfirmResponse = await handlePostReq(
				appConfig.urls.confirmpickup,
				bodyPickupConfirm,
				accessToken
			);
			if (
				pickupConfirmResponse !== undefined &&
				pickupConfirmResponse.data.Error === null
			) {
				/**
				 * Clear appSetting data
				 */
				clearAppsettingData();
				sendEvent(globalConstants.GAEventNames.ScheduleBungii,"Button Click",{TrackingID:pickupConfirmResponse.data.PickupToken})
				setPickupToken(pickupConfirmResponse.data.PickupToken);
				setFormIsFilled(false);
				setActiveTab("done");
				setShowLoader(false);
				this.props.history.push("/success")
			} else {
				toasterService.showCustomToasty(
					pickupConfirmResponse.data.Error.Message,
					"error"
				);
				if (
					paymentFailureCodes.includes(pickupConfirmResponse.data.Error.Code)
				) {
					this.setState({
						nonce: "",
						disableScheduleBtn: false,
					});
				}
			}
		} else if (deliveryInformation.data.Error.Code === 20042) {
			this.setState({
				showAlertModal: true,
				disableScheduleBtn: false,
			});
		} else if (deliveryInformation.data.Error.Code === 20009) {
			setShowLoader(false);
			toasterService.showCustomToasty(
				languageConstants.errors.invalidUser.message,
				"error"
			);
			this.setState({
				disableScheduleBtn: false,
			});
			setTimeout(function () {
				window.location.replace(`/`);
			}, 2000);
		} else {
			let errorMessage = deliveryInformation.data.Error.Message;
			if (
				deliveryInformation.data.Error.Code ===
				languageConstants.errors.itemsToDeliverMaxLengthExceeded.code
			) {
				errorMessage =
					languageConstants.errors.itemsToDeliverMaxLengthExceeded.message;
			} else if (
				paymentFailureCodes.includes(deliveryInformation.data.Error.Code)
			) {
				this.setState({
					nonce: "",
					disableScheduleBtn: false,
				});
			}
			toasterService.showCustomToasty(errorMessage, "error");
		}
		setShowLoader(false);
	};

	/**
	 * editBungii()
	 * This function is called on click of "Edit" and "Back to Quote" button.
	 */
	editBungii = async () => {
		toasterService.hideCustomToasty();
		try {
			const { setActiveTab } = this.props;
			setActiveTab("getEstimate");
			this.props.setBackToQuote(true);
			this.props.history.push("/request-bungii");
			sendEvent(globalConstants.GAEventNames.BackToQuote,"Click")
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * showDriverHelpCarryField()
	 * This function is used to show the message below card details.
	 */
	showDriverHelpCarryField = () => {
		try {
			const { noOfDrivers, appSetting } = this.props;
			let DisclaimerField = appSetting.PartnerLocationSettings.DisclaimerFields;
			let tripType = noOfDrivers === 1 ? "solo" : "duo";
			let that = this;
			if (DisclaimerField.length > 0) {
				DisclaimerField.forEach(function (value, i) {
					if (
						tripType === DisclaimerField[i].FieldName.toLowerCase() &&
						DisclaimerField[i].IsRequired
					) {
						that.setState({
							showCheckbox: DisclaimerField[i].IsRequired,
							disclaimerField: DisclaimerField[i].FieldValue,
						});
						that.updatedisclaimerFieldMessage();
					} else if (
						tripType === DisclaimerField[i].FieldName.toLowerCase() &&
						!DisclaimerField[i].IsRequired
					) {
						that.setState({
							showCheckbox: false,
							disclaimerField: "",
						});
					}
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * handleBtnChange()
	 * This function is called when user selects/de-selects the disclaimer message.
	 */
	handleBtnChange = (event) => {
		const { target } = event;
		try {
			if (target.checked === true) {
				this.setState({ disclaimerUnchecked: false });
			} else {
				this.setState({ disclaimerUnchecked: true });
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * cancelSubscription()
	 * This Function is called when the user clicks on close icon.
	 */
	cancelSubscription = (event) => {
		event.preventDefault();
		this.setState({
			errors: {
				...this.state.errors, password: ''
			}
		})
		if (this.state.clickedPay) {
			this.setState({
				open: false,
				clickedPay: false,
			});
		}
	};

	/**
	 * subscribe()
	 * This Function is called when the user clicks on Gain Access button.
	 */
	subscribe = async (event) => {
		event.preventDefault();
		if (this.state.password === undefined || this.state.password === '' || this.state.password === null) {
			this.setState({
				errors: {
					...this.state.errors, password: 'Password is required.'
				}
			})
			return;
		}
		const { setShowLoader } = this.props;
		try {
			setShowLoader(true);
			const { partnerLocationReference, setPaymentMethodSelected } = this.props;
			let data = {
				PartnerLocationReference: partnerLocationReference,
				Password: this.state.password,
			};

			let response = await AuthService.isUserAuthorized(data);
			if (response.data.AccessToken !== null) {
				/**
				 * Set redux state
				 */
				setPaymentMethodSelected(this.state.selectedPayMethod);
				this.setState({
					open: false,
					errors: {
						...this.state.errors,
						password: "",
					},
				});
				this.paymentMode();
			} else {
				this.setState({
					errors: {
						...this.state.errors,
						password: response.data.Error.Message + ".",
					},
				});
			}
			setShowLoader(false);
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * onPasswordChange()
	 * This function is called when user enters the password.
	 */
	onPasswordChange = (event) => {
		try {
			this.setState({ password: event.target.value });
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * appendUrl()
	 * This function is used to append the url to the message.
	 */
	appendUrl = (mge) => {
		if(!mge) return;
		try {
			const { appSetting } = this.props;
			let { CustomOrderURL, CustomOrderURLLabel } = "";
			if (
				appSetting &&
				appSetting.PartnerLocationSettings.DeliveryDetailsFields.length > 0
			) {
				appSetting.PartnerLocationSettings.DeliveryDetailsFields.map(
					(field, index) => {
						if (field["Fields"].length > 0) {
							CustomOrderURL = field["CustomOrderURL"];
							CustomOrderURLLabel = field["CustomOrderURLLabel"];
							return true;
						}
						return true;
					}
				);
			}
			let data = mge?.split("{{custom_order_url}}");
			let dataToAppend = (
				<a href={CustomOrderURL} target="_blank" rel="noopener noreferrer">
					{CustomOrderURLLabel.charAt(0).toUpperCase() +
						CustomOrderURLLabel.slice(1)}
				</a>
			);
			if (data.length > 1) {
				return (
					<div>
						{data[0]}
						{dataToAppend}
						{data[1]}
					</div>
				);
			} else {
				return data;
			}
		} catch (err) {
			console.log(err);
		}
	};


	/**
	 * newBungii()
	 * Tis function is called on click of newBungii button.
	 */
	newBungii = async () => {
		try {
			const {
				setActiveTab,
				appSetting,
				setDeliveryDetailsInitialState,
				setCommonInitialState,
				resetDataOnPageChange,
			} = this.props;
			let defaultGeofenceVal = defaultGeofence();
			let data = {
				defaultGeofenceIndex: defaultGeofenceVal,
				defaultGeofence: appSetting.GeofenceSettings[defaultGeofenceVal],
				timeZone:
					appSetting.GeofenceSettings[defaultGeofenceVal]["TimezoneIdentifier"],
			};
			/**
			 * Set redux state
			 */
			setDeliveryDetailsInitialState();
			resetDataOnPageChange(data);
			setCommonInitialState();

			/**
			 * Clear appSetting data
			 */
			clearAppsettingData();

			setActiveTab("getEstimate");
			sendEvent(globalConstants.GAEventNames.NewBungii,"Button Click");
			this.props.history.push("/request-bungii");
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * handleCloseAlert()
	 * This function is used to close the popup
	 */
	handleCloseAlert = () => {
		this.setState({
			showAlertModal: false,
		});
	};

	setSubmited = (val)=>{
		this.setState({
			submitted : val 
		})
	}
	
	setIsValid = (val)=>{
		this.setState({
			isValid : val 
		})
	}

	render() {
		const {
			paymentMethodSelected,
			appSetting,
			pickupRequestID,
		} = this.props;
		/*Char limit*/
		const { whatsNeededData } = this.state;
		
		return (
			<React.Fragment>
				<h1>Delivery Details</h1>
				<h1>
					Delivery Details{" "}
					<span className="back" onClick={this.editBungii}>
						back to{" "}
						{appSetting.PartnerLocationSettings.EstimatePageLabel ===
							"Get Quote"
							? "quote"
							: "estimate"}
					</span>
				</h1>
				<Row className='delivery-details'>
					<Col xs="12" lg="8">
						<DeliveryDetailsForm 
							formikRef={this.formikRef} 
							handleSubmit={this.submitDetails} 
							appendUrl={this.appendUrl}
							setIsValid={this.setIsValid}
							isValid={this.state.isValid}
						/>		
						<React.Fragment>
							{/* Payment methods */}
							{this.state.showPaymentMethod ? (
								<React.Fragment>
									<h2>Payment Methods</h2>
									<Form.Group className="payment-methods">
										<Row className="col-padding">
											{this.state.paymentMethod.map((method) => {
												let paymentLabel;
												switch (method.PaymentCode) {
													case "CC":
														paymentLabel = "Customer Card";
														break;
													case "PC":
														paymentLabel = "Partner Pay";
														break;
													case "MI":
														paymentLabel = "Partner Invoice";
														break;
													default:
														paymentLabel = "Unknown";
														break;
												}

												return (
													<Col md="4" key={method.PaymentCode}>
														<Form.Check
															type="radio"
															id={method.PaymentCode}
															label={paymentLabel}
															value={method.PaymentCode}
															name="payment-type"
															className="payment-method mb-2"
															checked={
																paymentMethodSelected === method.PaymentCode
															}
															onChange={(event) =>
																this.onRadioButtonChange(event)
															}
														/>
													</Col>
												);
											})}
										</Row>
									</Form.Group>
								</React.Fragment>
							) : (
								""
							)}

							{paymentMethodSelected === "CC" &&
								this.state.clientToken &&
								!this.state.nonce ? (
								<div>
									<DropIn
										options={{ authorization: this.state.clientToken }}
										onInstance={(instance) => (this.instance = instance)}
									/>
								</div>
							) : (
								""
							)}

							<React.Fragment>
								{this.state.showCheckbox && (
									<Form.Group
										id="helperCheckbox"
										style={{
											color: this.state.submitted 
												&& (this.state.disclaimerUnchecked === '' || this.state.disclaimerUnchecked)
													? "#CE230C"
													: "#131415",
										}}
										className="mandateCheckbox full-width"
									>
										<Form.Check
											type="checkbox"
											id="DisclaimerField"
											label={this.state.disclaimerField}
											onClick={this.handleBtnChange}
										/>
									</Form.Group>
								)}
								<div className="text-center text-md-left">
									<button
										id="submit-details"
										form="formik-form"
										className="btn"
										type="submit"
										onClick={()=>this.setSubmited(true)}
										disabled={this.state.disableScheduleBtn}
									>
										Schedule Bungii{" "}
										{/* <img src={arrowIcon} alt="Schedule Bungii" width="14" /> */}
									</button>
								</div>
									<React.Fragment>
										{this.state.submitted && 
											(!this.state.isValid || this.state.disclaimerUnchecked) ? (
											<Row>
												<Col
													sm="12"
													className="err-msg text-danger warning-icon mt-3"
												>
													Please verify the highlighted fields above.
												</Col>
											</Row>
											) : ""}
									</React.Fragment>
							</React.Fragment>
						</React.Fragment>
					</Col>
					<Col lg="4" className="d-none d-lg-block">
						<Summary
							editBungii={this.editBungii}
							isVisibleSoloDuo={this.state.isVisibleSoloDuo}
							whatsNeededData={whatsNeededData}
							state={this.state}
						/>
					</Col>
				</Row>
				<Password
					open={this.state.open}
					cancelSubscription={this.cancelSubscription}
					subscribe={this.subscribe}
					onPasswordChange={this.onPasswordChange}
					value={this.state.value}
					authenticateUser={this.authenticateUser}
					errors={this.state.errors.password}
					clickedPay={this.state.clickedPay}
				/>

				{this.state.showAlertModal && (
					<PickupExists
						showAlertModal={this.state.showAlertModal}
						resetBungii={this.newBungii}
						handleCloseAlert={this.handleCloseAlert}
						pickupRequestID={pickupRequestID}
					/>
				)}
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(
	connect(
		({
			getEstimate: {
				editPickup,
				pickupPoint,
				minDate,
				geofence,
				defaultGeofenceIndex,
				rerenderDropoff,
				pickupDate,
				isDuo,
				defaultPoint,
				pickupAdd,
				pickupAddObj,
				defaultAdd,
				loadUnloadTimeShow,
				defaultGeofence,
				pickupTime,
				tripType,
				dropoffAdd,
				dropoffPoint,
				timeZone,
				dropoffAddObj,
				nextCalculatedDate,
				pickupTimeValues,
				loadUnload,
				estimate,
				loadTime,
				pickupRequestID,
				serviceLevel,
				serviceName,
				deliveryTime,
				distance,
				pickupAddressess,
				partnerUpchargeValue,
				orderRef,
				isMuscleHelpRequired,
				isServiceLevelUpdated,
				isBoxTruck,
			},
			user: { accessToken, partnerLocationReference, quickAccessUser },
			common: {
				appSetting,
				activeTab,
				showLoader,
				formFilled,
				kioskMode,
				showPrice,
			},
			deliveryDetails: {
				pickupDetails,
				loadingUnloadingTime,
				noOfDrivers,
				scheduledTimestamp,
				lastFour,
				paymentMethodSelected,
				orderNumber,
				externalIdLabel,
				palletData,
				backToQuote,
			},
		}) => ({
			accessToken,
			activeTab,
			pickupRequestID,
			appSetting,
			pickupDetails,
			loadingUnloadingTime,
			noOfDrivers,
			scheduledTimestamp,
			editPickup,
			pickupPoint,
			minDate,
			geofence,
			defaultGeofenceIndex,
			rerenderDropoff,
			pickupDate,
			isDuo,
			defaultPoint,
			pickupAdd,
			pickupAddObj,
			defaultAdd,
			loadUnloadTimeShow,
			defaultGeofence,
			pickupTime,
			tripType,
			orderRef,
			dropoffAdd,
			dropoffPoint,
			timeZone,
			dropoffAddObj,
			nextCalculatedDate,
			pickupTimeValues,
			loadUnload,
			estimate,
			loadTime,
			lastFour,
			paymentMethodSelected,
			showLoader,
			formFilled,
			partnerLocationReference,
			serviceLevel,
			serviceName,
			kioskMode,
			quickAccessUser,
			showPrice,
			orderNumber,
			externalIdLabel,
			deliveryTime,
			distance,
			palletData,
			pickupAddressess,
			partnerUpchargeValue,
			isMuscleHelpRequired,
			backToQuote,
			isServiceLevelUpdated,
			isBoxTruck,
		}),
		(dispatch) => ({
			setActiveTab: (activeTab) => dispatch(setActiveTab(activeTab)),
			setPickupRequestID: (pickupRequestID) =>
				dispatch(setPickupRequestID(pickupRequestID)),
			setPickupDetails: (pickupDetails) =>
				dispatch(setPickupDetails(pickupDetails)),
			setLoadingUnloadingTime: (loadingUnloadingTime) =>
				dispatch(setLoadingUnloadingTime(loadingUnloadingTime)),
			setPickupAdd: (pickupAdd) => dispatch(setPickupAdd(pickupAdd)),
			setDropoffAdd: (dropoffAdd) => dispatch(setDropoffAdd(dropoffAdd)),
			setNoOfDrivers: (noOfDrivers) => dispatch(setNoOfDrivers(noOfDrivers)),
			setScheduledTimestamp: (scheduledTimestamp) =>
				dispatch(setScheduledTimestamp(scheduledTimestamp)),
			setLastFour: (lastFour) => dispatch(setLastFour(lastFour)),
			setPaymentMethodSelected: (paymentMethodSelected) =>
				dispatch(setPaymentMethodSelected(paymentMethodSelected)),
			setShowLoader: (showLoader) => dispatch(setShowLoader(showLoader)),
			setFormIsFilled: (formFilled) => dispatch(setFormIsFilled(formFilled)),
			setKioskMode: (kioskMode) => dispatch(setKioskMode(kioskMode)),
			setAppSetting: (appSetting) => dispatch(setAppSetting(appSetting)),
			setOrderNumber: (orderNumber) => dispatch(setOrderNumber(orderNumber)),
			setExternalIdLabel: (externalIdLabel) => dispatch(setExternalIdLabel(externalIdLabel)),
			setCommonInitialState: (commonInitialState) =>
				dispatch(setCommonInitialState(commonInitialState)),
			resetDataOnPageChange: (data) => dispatch(resetDataOnPageChange(data)),
			setDeliveryDetailsInitialState: (newValues1) =>
				dispatch(setDeliveryDetailsInitialState(newValues1)),
			setPickupToken: (pickupToken) => dispatch(setPickupToken(pickupToken)),
			setBackToQuote: (backToQuote) => dispatch(setBackToQuote(backToQuote)),
		})
	)(withRouter(DeliveryDetails))
);
