const languageConstants = {
	errors: {
		awsAuthErrors: {
			undefinedError: "Undefiend error: Something went wrong.",
		},
		inValidAddress: {
			message: "Please select a more specific address.",
		},
		inValidPickupPlace: {
			message:
				"Sorry, this delivery address is outside our standard operating area. Please enter a Custom Quote or contact support@bungii.com to request this delivery.",
		},
		inValidDropoffPlace: {
			message: "Drop Off Address is invalid.",
		},
		inValidPickupTime: {
			message:
				"Pickup time is invalid due to delay, please select a new time for pickup.",
		},
		invalidPhoneNumber: {
			message: "Enter valid phone number.",
		},
		requiredField: {
			message: "is required.",
		},
		invalidField: {
			message: "is invalid.",
		},
		invalidUser: {
			message:
				"There was an error processing your request. You will be redirected to the login screen.",
		},
		emptyField:{
			message: 'This field is required.'
		},
		itemsToDeliverMaxLengthExceeded: {
			code: 20033,
			message: "Items to deliver max length exceeded",
		},
		paymentFailure: {
			code: 3002,
			message:
				"There seems to be an issue processing your payment. Please try again later.",
		},
		paymentPreAuthFailure1: {
			code: 3003,
			message:
				"There seems to be an issue processing your payment. Please try again later.",
		},
		paymentPreAuthFailure2: {
			code: 3004,
			message:
				"There seems to be an issue processing your payment. Please try again later.",
		},
		skuItemsToDeliver: {
			itemExists: `Entered SKU number is already added.`,
			emptySku: `Enter SKU number.`,
			skuLength: `Enter valid 7 digit SKU number.`,
			emptyField: `Items to deliver is required.`,
			maxItemCount: `You cannot add more then 5 items.`,
			maxItemWeight: `The item selected exceeds the maximum delivery weight of `,
			maxItemWeight2: `total item weight should be less than or equal to`,
			maxItemVolume: `Total item volume should be less than or equal to`,
		},
		pallets: {
			emptyPallet: `Product description, dimensions and weight are required.`,
			emptyXlTilePallet: `Product description and # of slabs are required.`,
		},
		pickupAddressRequired: {
			message: "Pickup Address is required.",
		},
	},
	pallets: {
		palletInfo: `Pallet weight upto`,
	},
	cardAdded: "Card added for payment",
	modals: {
		title: `Password Please`,
		gainAccess: "You’ll need the admin password to access admin settings.",
		exitAccess: "You’ll need the admin password to exit access.",
		clickedPay: "You’ll need the admin password to access this payment option.",
	},
	portraitMode: `This application can be best viewed on an iPad in landscape mode or on a Desktop system.`,
	redirectAlertBox: {
		title: `Heads Up!`,
		description: `By continuing, you will abandon the delivery you are currently scheduling. All entered information will be lost.`,
	},
	serviceLevel: {
		modal: {
			title: `Service Level`,
			description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris porta sagittis convallis. Donec vel enim a quam interdum imperdiet.`,
		},
		noService: {
			serviceName: `No service selected.`,
			serviceDescription: `Select pickup and dropoff address to enable Service Level.`,
		},
	},
	defaultPrice: {
		bestBuy: "N/A",
		default: "$0.00",
	},
	deliveryStatus: {
		ratingSelect: "Please select ratings.",
		ratingSuccess: "Ratings submitted successfully.",
	},
	pickupExisits: {
		header: `Delivery Details`,
		description1: `It looks like this is a duplicate delivery request. Please see the already scheduled delivery details below for your reference.`,
		label1: `Delivery Date & Time:`,
		label2: `Pickup Address:`,
		label3: `Dropoff Address:`,
		description2: `If you have any questions, please contact Bungii Support.`,
	},
	deliveryWindow: {
		supportInfoText : 'If you have any questions about your pickup, please text Bungii support at'
	},
	timeSlots: {
		noSlotsAvailable: `No slots available.`,
	},
	getEstimate: {
		pickupAddressEditText1: "Edit",
		pickupAddressEditText2: "Back",
		isMuscleHelpRequiredText : 'Does this delivery include a Vanity or any items that would require 2 people to unload?'
	},
	deliveryReport: {
		noDataAvailable: "No deliveries for selected dates",
	},
	callRequest: {
		success: "Thank you. We are initiating the call request.",
		error: "Error while initiating the call",
	},
};

export default languageConstants;
