/* Redux Import Start */
import {store} from '../redux/store';
import { dateUtils } from '../utilities/dateUtils';
import moment from 'moment-timezone';

/* Redux Import End */

const getDeliveryDateTimeValues = (date) => {
    const { pickupDate, pickupTime, timeZone } = store.getState().getEstimate;

    let DateTime = `${dateUtils.getDate(new Date(pickupDate))} ${pickupTime}`;
    DateTime = moment.tz(DateTime, timeZone);
    let DateTimeUTC = moment.utc(DateTime).format('YYYY-MM-DD HH:mm:ss.SSS');
    return DateTimeUTC;
}

export default getDeliveryDateTimeValues;