import React, { useState , useEffect } from "react";
import { Col, Row ,Table } from "react-bootstrap";
import arrowDown from '../assets/images/icons/icon-angle-down.png';

const TripDetailsTable = ({uiType,pickupNote,expandedData,isTrackDeliveriesEnabled,isMuscleHelpRequired,onExpand}) => {
    const [tableData, setTableData] = useState([]);
    const [skuType, setSkuType] = useState(false);

    /* UIType 4 */
    const eapiHeader = [
        { 
            title: uiType === 4 ? 'Item' : 'Product Description',
            value: "Name"
        },
        { title: "Quantity", value: "Quantity" },
        {
            title: "Dimensions (IN)", value: "Dimensions",
            formatter: (data) => {
                return (
                    uiType === 4 ?
                        `${data.Width.split(" ")[0]} W x ${data.Depth.split(" ")[0]} D x ${data.Height.split(" ")[0]} H`
                        :
                        (data?.Depth ? `Depth: ${data.Depth}` + (data.Width ? ', ' : '' ) : '')
                        + (data?.Width ? `Width: ${data.Width}` + (data.Height ? ', ' : '') : '')
                        + (data?.Height ? `Height: ${data.Height}` : '')
                        + (!data?.Depth && !data?.Width && !data?.Height ? '-' : '')
                );
            },
        },
        { title: "Weight (LBS)", value: "Weight" }
    ]

    /* UIType 3 */
    const palletHeader = [
        { title: 'Product Description', value: "Name" },
        {
            title: "Dimensions (IN)", value: "Dimensions",
            formatter: (data) => {
                return (data?.Dimensions ? data.Dimensions : data?.Depth+"X"+data?.Width+"X"+data?.Height);
            },
        },
        { title: "Weight (LBS)", value: "Weight" }
    ]

    /* UIType 5 */
    const xlTileHeader = [
        { title: 'Product Description', value: "Name" },
        { title: "# of Slabs", value: "noOfSlabs" },
        { title: "Appx Weight", value: "Weight" }
    ]

    const skuHeader = [
        { title: "SKU", value: "SKU" },
        { title: "Qty", value: "Quantity" },
        { title: "Item", value: "Item",
        formatter: (data,i) => {
            return (
                <React.Fragment>
                    {data.Name}
                    <Row className={(expandedData[i].openDetails) ? 'd-flex' : 'd-none'}>
                        {(data.Height) && <Col md="3" className="item-details">
                            Height: {data.Height}
                        </Col>}
                        {(data.Width) && <Col md="3" className="item-details">
                            Width: {data.Width}
                        </Col>}
                        {(data.Weight) && <Col md="3" className="item-details">
                            Weight: {data.Weight}
                        </Col>}
                        {(data.Depth) && <Col md="3" className="item-details">
                            Depth: {data.Depth}
                        </Col>}
                    </Row>
                </React.Fragment>
            );
        }, },
        { title: "", value: "",
        formatter: (data) => {
            return (
                (data.Height || data.Width || data.Weight || data.Depth) && <img src={arrowDown} alt="Arrow Down"/>
            );
        },
     }
    ]

    useEffect(() => {
        if (uiType === 3) {
            return setTableData(palletHeader);
        } else if (uiType === 4 || isTrackDeliveriesEnabled) {
            return setTableData(eapiHeader);
        } else if (uiType === 5) {
            return setTableData(xlTileHeader);
        } else {
            setSkuType(true)
            return setTableData(skuHeader);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uiType])

	return (
		<React.Fragment>
            <Table className={(uiType === 3 || uiType === 4 || uiType === 5 || isTrackDeliveriesEnabled) ? "pallet-details" : ""}>
                <thead>
                    <tr>
                        {tableData.map((data) => {
                            return <th>{data.title}</th>
                        })}
                    </tr>
                </thead>
                <tbody>{pickupNote.map((item, key) =>
                    // if there is a vanity item trip, show first pallet info, otherwise show all pallet info 
                    (isMuscleHelpRequired ? key===0 : true) && 
                    <tr key={key} onClick={() => skuType && onExpand(key)} className={(skuType) ? "items" : "empty-items"} >
                        {tableData.map((data,index)=>{
                            return <td key={index} className={expandedData[key].openDetails ? "open-details" : "close-details"}>{data.formatter ? data.formatter(item,key) : item[data.value]}</td>
                        })}
                    </tr>
                )}</tbody>
            </Table>
		</React.Fragment>
	);
};

export default TripDetailsTable;
