import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import globalConstants from '../../shared/appConfig/globalConstants';

class GoogleCaptcha extends Component {
    render() {
        return (
            <React.Fragment>
                <Form.Group className="Captcha">
                    <div>
                        <ReCAPTCHA
                            style={{ display: "inline-block" }}
                            sitekey={globalConstants.SiteKey}
                            onChange={this.props.onSelectCaptcha}
                            ref={this.props.captchaComponent}
                            // asyncScriptOnLoad={this.asyncScriptOnLoad}
                        />
                        <div className="invalid-feedback gcaptcha d-block">{this.props.errors}</div>
                    </div>
                </Form.Group>
            </React.Fragment >
        );
    }
}

export default GoogleCaptcha;
