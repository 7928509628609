import React  from 'react';
import { withRouter } from 'react-router';
import storageService from '../services/StorageService';
import AuthService from '../../shared/services/AuthService';
import globalConstants from '../../shared/appConfig/globalConstants';
import clearAppsettingData from '../../shared/helpers/clearAppsettingData';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setAccessToken, setQuickAccessUser, setQuoteOnlyUser } from '../../shared/redux/actions/user.actions';

import { setActiveTab, setKioskMode, setDisplayKioskMode, setLockedAccess, setCommonInitialState, setAppSetting, setShowLoader } from '../../shared/redux/actions/common.actions';
import { resetDataOnPageChange, resetDataOnLogout } from '../../shared/redux/actions/getEstimate.actions';
import { setDeliveryDetailsInitialState } from '../../shared/redux/actions/deliveryDetails.actions';
/* Redux Import End */

function quickAccessGuard(Component) {
    class quickAccessGuardComponent extends React.Component {
        constructor(props){
            super(props);
            this.state = {
                renderData: false
            }
        }

        /**
         * componentWillMount()
         * This function Executes just before rendering takes place.
         */
        componentWillMount = async() => {
            try{
                const { setDeliveryDetailsInitialState, setAppSetting, appSetting, resetDataOnPageChange, setActiveTab, setLockedAccess, setKioskMode, setDisplayKioskMode, setCommonInitialState, resetDataOnLogout, setQuoteOnlyUser } = this.props;
                let data = {
                    defaultGeofenceIndex: null,
                    defaultGeofence: '',
                    timeZone: ''
                }
                if(typeof appSetting === 'object'){
                    setActiveTab('getEstimate');
                    /**
                     * Reset Data
                     */
                    setDeliveryDetailsInitialState(); 
                    resetDataOnPageChange(data); 
                    resetDataOnLogout();
                    await setCommonInitialState();
                    setKioskMode(null);
                    setLockedAccess(false);
                    setDisplayKioskMode(false);
                    setQuickAccessUser(false);
                    setAppSetting('');
                    setQuoteOnlyUser(false);
                    
                    /**
                     * Clear appSetting data
                     */
                    clearAppsettingData();
                    
                    localStorage.removeItem('persist:root');
                    localStorage.clear();
                    sessionStorage.clear();
                    
                }
                this.checkAuth();
            } catch(err){
                console.log(err);
            }
        }
        
        /**
         * getAccessType()
         * This function is used to get the access type based on the pathname
         */
         getAccessType = (path) => {
            try{
                let accessType = '';
                switch (path) {
                    case '/quote-only': 
                        accessType = 1; 
                        break;
                    case '/quick-access': 
                        accessType = 0; 
                        break;
                    default: 
                        accessType = ''
                        break;
                }
                return accessType;

            } catch(err){
                console.log(err)
            }
         }
        
        /**
         * checkAuth()
         * This function will act as a guard to redirect based on the conditions.
         */
        checkAuth = async() => {
            try{
                const { setAccessToken, setQuickAccessUser, setShowLoader, setQuoteOnlyUser } = this.props;
                setShowLoader(true);
                //To be used on Deployed Serves
                const fullUrl = window.location.host
                const pathname = window.location.pathname
                //Common code
                const subdomain = (globalConstants.FullUrl) ? globalConstants.FullUrl.split('.')[0] : fullUrl.split('.')[0]
                let data = {
                    "SubDomainName": subdomain,
                    "AccessType": this.getAccessType(pathname)  //Passed to identify the page
                }
                let response = await AuthService.quickAccess(data);
                if (response.data.AccessToken !== null) {
                    await storageService.setSessionStorageItem("accessToken", response.data.AccessToken);
                    await setAccessToken(response.data.AccessToken)
                    if(pathname === '/quick-access') {
                        setQuickAccessUser(response.data.QuickAccessUser)
                        setQuoteOnlyUser(false)
                    } else if(pathname === '/quote-only') {
                        setQuoteOnlyUser(response.data.HasQuoteAccess)
                        setQuickAccessUser(false)
                    }
                    await setShowLoader(false);
                    if(pathname === '/quick-access') {
                        this.props.history.push("/request-bungii");
                    } else if(pathname === '/quote-only') {
                        this.setState({
                            renderData: true
                        })
                    }
                }
                else {
                    if(response.data.Error !== null) {
                        await setShowLoader(false);
                        this.props.history.push("/page-not-found");
                    } else {
                        await setShowLoader(false);
                        this.props.history.push("/login");
                    }
                    
                }
            } catch (err) {
                console.log(err)
            }
        }

        render() {
            return ((this.state.renderData) ? <Component { ...this.props } /> : <div></div>);
        }
    }
    
    return connect(
        (
            { 
                user: {
                    accessToken
                },
                common: {
                    activeTab, appSetting
                }
            }
        ) => 
        (
            {
                accessToken,
                activeTab,
                appSetting
            }
        ),
        dispatch => ( 
            {
                setAccessToken: accessToken => dispatch(setAccessToken(accessToken)),
                setQuickAccessUser: quickAccessUser => dispatch(setQuickAccessUser(quickAccessUser)),
                setShowLoader: showLoader => dispatch(setShowLoader(showLoader)),
                setQuoteOnlyUser: quoteOnlyUser => dispatch(setQuoteOnlyUser(quoteOnlyUser)),

                setActiveTab: activeTab => dispatch(setActiveTab(activeTab)),
                setDeliveryDetailsInitialState: newValues1 => dispatch(setDeliveryDetailsInitialState(newValues1)),
                setKioskMode: kioskMode => dispatch(setKioskMode(kioskMode)),
                setDisplayKioskMode: displayKioskMode => dispatch(setDisplayKioskMode(displayKioskMode)),
                setLockedAccess: lockedAccess => dispatch(setLockedAccess(lockedAccess)),
                resetDataOnPageChange: data => dispatch(resetDataOnPageChange(data)),
                resetDataOnLogout: data => dispatch(resetDataOnLogout(data)),
                setCommonInitialState: commonInitialState1 => dispatch(setCommonInitialState(commonInitialState1)),
                setAppSetting: appSetting => dispatch(setAppSetting(appSetting)),
            }
        )
    )(withRouter(quickAccessGuardComponent));
}

export default quickAccessGuard;