import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import './assets/css/style.css';
import './App.scss';
import ReactGA from "react-ga4";


/* Redux Import Start */
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import {store, persistor} from './shared/redux/store';
import Routes from './Routes';
/* Redux Import End */

/**
 * GA
 */
ReactGA.initialize(process.env.REACT_APP_ANALYTICS_KEY);

function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Router>
            <Routes/>
      </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
