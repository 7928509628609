export const commonActionTypes = {
    SET_APP_SETTING: 'SET_APP_SETTING',
    SET_SHOW_LOADER: 'SET_SHOW_LOADER',
    SET_FORM_IS_FILLED: 'SET_FORM_IS_FILLED',
    SET_COMMON_INITIAL_STATE: 'SET_COMMON_INITIAL_STATE',
    SET_KIOSK_MODE: 'SET_KIOSK_MODE', 
    SET_DISPLAY_KIOSK_MODE: 'SET_DISPLAY_KIOSK_MODE', 
    SET_LOCKED_ACCESS: 'SET_LOCKED_ACCESS',
    SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
    SET_SHOW_PRICE: 'SET_SHOW_PRICE',
    SET_SHOW_SOLO_DUO: 'SET_SHOW_SOLO_DUO',
}

export const setAppSetting = appSetting => ({
    type: commonActionTypes.SET_APP_SETTING,
    payload: appSetting
})

export const setActiveTab = activeTab => ({
    type: commonActionTypes.SET_ACTIVE_TAB,
    payload: activeTab
})

export const setShowLoader = showLoader => ({
    type: commonActionTypes.SET_SHOW_LOADER,
    payload: showLoader
});

export const setFormIsFilled = formFilled => ({
    type: commonActionTypes.SET_FORM_IS_FILLED,
    payload: formFilled
});

export const setCommonInitialState = commonInitialState => ({
    type: commonActionTypes.SET_COMMON_INITIAL_STATE,
    payload: commonInitialState
})

export const setKioskMode = kioskMode => ({
    type: commonActionTypes.SET_KIOSK_MODE,
    payload: kioskMode
})

export const setDisplayKioskMode = displayKioskMode => ({
    type: commonActionTypes.SET_DISPLAY_KIOSK_MODE,
    payload: displayKioskMode
})

export const setLockedAccess = lockedAccess => ({
    type: commonActionTypes.SET_LOCKED_ACCESS,
    payload: lockedAccess
})

export const setShowPrice = showPrice => ({
    type: commonActionTypes.SET_SHOW_PRICE,
    payload: showPrice
})

export const setIsVisibleSoloDuo = isVisibleSoloDuo => ({
    type: commonActionTypes.SET_SHOW_SOLO_DUO,
    payload: isVisibleSoloDuo
})
