import decodePolyline from 'decode-google-map-polyline';

const decodePolylines = (settings) => {
    let finalPolyline = [];
    for (let i = 0; i < settings.length; i++) {
        let decodedPolylines = decodePolyline(settings[i]['Polyline']);
        finalPolyline.push(decodedPolylines)
    }
    return finalPolyline;
}

export default decodePolylines;