import React, { forwardRef, useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Footer from "../shared/layouts/common/Footer";
import logo from "../assets/images/logo.png";
import languageConstants from "../shared/appConfig/languageConstants";
import DatePicker from "react-datepicker";
import FormControl from "@material-ui/core/FormControl";
import calenderPng from "../assets/images/icons/calendar.png";
import { MenuItem, Select } from "@material-ui/core";
import arrowIcon from "../assets/images/icons/arrow.png";
import { dateUtils } from "../shared/utilities/dateUtils";
import appConfig from "../shared/appConfig/appConfig";
import {
	handleGetReq,
	handlePatchReq,
} from "../shared/services/AppendHeadersService";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoader } from "../shared/redux/actions/common.actions";
import floorFoundLogo from "../assets/images/Floor_Found_logo.png";
import { getFullAddress2 } from "../shared/modules/Address";
import toasterService from "../shared/services/ToasterService";
import { ToastContainer } from "react-toastify";

function DeliveryWindow(props) {
	let history = useHistory();
	let { trackingID } = useParams();
	const { partnerLocationReference } = useSelector((state) => state.user);
	let dispatch = useDispatch();
	const [partnerLogo, setPartnerLogo] = useState();
	const [startDate, setStartDate] = useState(new Date());
	const [selectedTimeFrame, setSelectedTimeFrames] = useState("");
	const [waypoints, setWaypoints] = useState([]);
	const [pickupLevels, setPickupLevels] = useState([]);
	const [selectedPickupLevel, setSelectedPickupLevel] = useState('');
	const [externalId, setExternalId] = useState();
	const [timeFrames, setTimeFrames] = useState([]);
	const [timezone, setTimezone] = useState(moment.tz.guess());

	const setLoader = (show) => {
		dispatch(setShowLoader(show));
	};
	const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => {
		return (
			<div className="custom-date-picker" onClick={onClick} ref={ref}>
				<div className="input-wrapper">
					<img alt="Calender" src={calenderPng} />
					<input
						type="text"
						value={dateUtils.getDatePickerDate(new Date(startDate))}
						readOnly
					/>
					<div className="icon">
						<svg
							className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined"
							focusable="false"
							viewBox="0 0 24 24"
							aria-hidden="true"
						>
							<path d="M7 10l5 5 5-5z"></path>
						</svg>
					</div>
				</div>
			</div>
		);
	});

	/**
	 *
	 * @param {*} data
	 * @returns items to deliver in string format
	 */
	const getItemsToDeliver = (data) => {
		let itemName = data.name;

		let dimensionsProperties = [
			"height",
			"width",
			"weight",
			"sku",
			"depth",
			"quantity",
			"dimensions",
		];
		let dimensions = [];
		dimensionsProperties.forEach((prop, index) => {
			if (data[prop]) {
				dimensions.push(prop);
			}
		});

		let itemDimentions = "";
		dimensions.forEach((prop, index) => {
			let name = prop.charAt(0).toUpperCase() + prop.slice(1);
			itemDimentions +=
				name +
				": " +
				data[prop] +
				(index === dimensions.length - 1 ? "" : ", ");
		});

		return `${itemName} (${itemDimentions})`;
	};

	const convertDateToTimezone = (date) => {
		return new Date(moment(date).tz(timezone).format("L"));
	};

	/**
	 * This function calls endpoint to fetch trip info
	 */
	const getWaypoints = async () => {
		try {
			const response = await handleGetReq(
				appConfig.urls.getWaypoints + "/" + trackingID,
			);
			if (response.data.error) {
				history.push("/page-not-found");
				throw response.data.error;
			} else {
				setWaypoints(response.data.delivery?.waypoints);
				setExternalId(response.data.delivery?.externalId);
				setPartnerLogo(response.data.delivery?.partnerLogoUrl);
				setTimezone(response.data.delivery?.timezone);
			}
		} catch (error) {
			console.log(error);
			throw new Error();
		}
	};

	/**
	 * This function calls endpoint to fetch time frames
	 */
	const getTimeFrames = async () => {
		try {
			const response = await handleGetReq(
				appConfig.urls.getWindows + "/" + trackingID,
			);
			if (response.data.error) {
				history.push("/delivery/link-expired");
				throw response.data.error;
			} else {
				let windows = response.data?.windows?.map((frame, index) => {
					frame.key = index;
					return frame;
				});
				if (windows?.length > 0) {
					setTimeFrames(windows);
					// set the earliest time
					setSelectedTimeFrames(0);
					setStartDate(convertDateToTimezone(windows[0].startTime));
				} else history.push("/delivery/link-expired");
			}
		} catch (error) {
			console.log(error);
		}
	};

	/**
	 * This function calls endpoint to fetch pickup levels
	 */
	const getPickupLevels = async () => {
		try {
			const response = await handleGetReq(appConfig.urls.serviceLevels, {
				PartnerLocationRef: partnerLocationReference,
				type: "AUP",
			});
			if (response.data.error) {
				throw response.data.error;
			} else {
				setSelectedPickupLevel(
					response.data.ServiceLevelModels?.find(
						(level) => level.isDefaultService === 1
					)?.serviceLevelRef
				);
				setPickupLevels(response.data.ServiceLevelModels);
			}
		} catch (error) {
			console.log(error);
		}
	};
	/**
	 *
	 * @param {*} event
	 * This function is called when user selects date, time and clicks on Schedule button
	 */
	const handleSchedule = async (event) => {
		setLoader(true);
		try {
			let params = {
				window: timeFrames[selectedTimeFrame],
				serviceLevelRef: selectedPickupLevel,
			};
			const response = await handlePatchReq(
				appConfig.urls.getWaypoints + "/" + trackingID,
				params,
			);
			if (response.data.error) {
				toasterService.showCustomToasty(response.data.error.message, "error");
				throw response.data.error;
			} else {
				history.push({
					pathname: "/delivery/success",
					state: {
						partnerLogo,
						externalId,
						window: response.data?.window,
						servicePickupLevel: response.data?.servicePickupLevel,
						pickupWaypoint: waypoints[0],
						timezone,
					},
				});
			}
		} catch (error) {
			console.log(error);
		}
		setLoader(false);
	};

	const handleTimeChange = (e) => {
		setSelectedTimeFrames(e.target.value);
	};

	/**
	 *
	 * @param {*} date
	 * This function is called when user change date from timepicker
	 * It will also update time window to first window of selected date
	 */
	const handleDateChange = (date) => {
		setStartDate(date);
		// find first window of selected date
		let firstWindowOfSelectedDay = timeFrames.findIndex((window) =>
			moment(convertDateToTimezone(window.startTime)).isSame(date, "day"),
		);
		setSelectedTimeFrames(firstWindowOfSelectedDay);
	};

	/**
	 * Called on first render
	 * It will fetch tripData first and if no error, it will fetch windows
	 */
	const getTripData = async () => {
		setLoader(true);
		await getWaypoints().then(async (res) => {
			await getTimeFrames();
			await getPickupLevels();
		});
		setLoader(false);
	};

	useEffect(() => {
		if(partnerLocationReference){
			getTripData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partnerLocationReference]);

	return (
		<React.Fragment>
			<main className="main-wrapper show-delivery-status" id="main-wrapper">
				{/* Header */}
				<ToastContainer position="bottom-right" closeOnClick={false} />
				<header className="delivery-status-header">
					<Container>
						<Row>
							<Col
								className="pr-0 partner-logo d-flex align-items-center"
								md="auto"
							>
								<img src={floorFoundLogo} alt="Partner Logo" />
							</Col>
							<Col className="pl-0 powered-by align-self-center d-block">
								<img src={logo} width="93" className="logo" alt="Bungii Logo" />
							</Col>
						</Row>
					</Container>
				</header>

				<Container className="main-container detailed-information ">
					<div className="quote-only"></div>
					<h1>Schedule Pickup</h1>
					<Row className="m-auto">
						<Col xs="12" className="px-0 pr-lg-10">
							<div className="vanity_item_box mt-0">
								<span className="form-description support-text">
									{languageConstants.deliveryWindow.supportInfoText}{" "}
									<a href="tel:+9806555006">(980) 655-5006</a> or email{" "}
									<a href="mailto:support@bungii.com">support@bungii.com</a>
								</span>
							</div>
						</Col>
					</Row>
					<Form id="customer-pickup-window">
						<Row className="my-3">
							<Col>
								<span className="form-label">ORDER ID: </span>
								<span>{externalId}</span>
							</Col>
						</Row>
						<Row className="my-3">
							<Form.Group
								as={Col}
								xs="9"
								md="4"
								className="my-0 select-date"
								controlId="formGridStartDate"
							>
								<Form.Label className="mb-2">Select Date: </Form.Label>
								<DatePicker
									selected={startDate}
									onChange={handleDateChange}
									customInput={<CustomDatePickerInput />}
									includeDates={timeFrames?.map((frame) =>
										convertDateToTimezone(frame?.startTime),
									)}
								/>
							</Form.Group>
							<Form.Group
								as={Col}
								xs="9"
								md="4"
								className="my-0"
								controlId="formGridStartDate"
							>
								<Form.Label className="mb-2">Select Time: </Form.Label>
								<FormControl
									variant="outlined"
									data-size="10"
									className="pickup-time "
								>
									<Select
										required
										className={"load-time-select w-100"}
										displayEmpty
										size="small"
										value={selectedTimeFrame}
										onChange={handleTimeChange}
									>
										<MenuItem value={""} disabled>
											Select
										</MenuItem>
										{timeFrames
											?.filter((frame) =>
												moment(convertDateToTimezone(frame.startTime)).isSame(
													startDate,
													"day",
												),
											)
											?.map((frame, i) => {
												return (
													<MenuItem key={i} value={frame.key}>
														{moment(frame.startTime)
															.tz(timezone)
															.format("hh:mm A")}{" "}
														-{" "}
														{moment(frame.endTime)
															.tz(timezone)
															.format("hh:mm A")}
													</MenuItem>
												);
											})}
									</Select>
								</FormControl>
							</Form.Group>
						</Row>
						<Row className="my-3">
							<Form.Group
								as={Col}
								xs="9"
								md="4"
								className="my-0"
								controlId="formGridPickupLevel"
							>
								<Form.Label className="mb-2">Pick up items from: </Form.Label>
								<FormControl
									variant="outlined"
									className="w-100 pickup-level-select"
									size="small"
								>
									<Select
										required
										className={"w-100"}
										displayEmpty
										value={selectedPickupLevel}
										onChange={(event)=> setSelectedPickupLevel(event.target.value)}
									>
										<MenuItem value={""} disabled>
											Select an option
										</MenuItem>
										{pickupLevels?.map((level, i) => {
											return (
												<MenuItem key={i} value={level.serviceLevelRef}>
													{level.serviceName}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Form.Group>
						</Row>
						<Form.Group className="position-relative my-3">
							<Form.Label>Pickup Address: </Form.Label>
							<p>{getFullAddress2(waypoints?.length > 0 && waypoints[0])}</p>
						</Form.Group>

						<Row className="my-3">
							<Col lg="9">
								<Form.Group className="position-relative">
									<Form.Label>Item(s) to Pick up:</Form.Label>
									{waypoints
										?.filter((stop) => stop.stopType === 1)
										.map((stops, index) => {
											return stops?.deliveryItems?.map((item, i) => {
												return (
													<p key={i}>
														Item {i + 1}: {getItemsToDeliver(item)}
													</p>
												);
											});
										})}
								</Form.Group>
							</Col>
						</Row>

						<div className="estimate h-max-content">
							<button
								id="get-estimate"
								className="btn w-100"
								type="button"
								onClick={handleSchedule}
							>
								<span className="">
									<span>Schedule</span>
									<img className="ml-1 sm" src={arrowIcon} alt="Submit" />
								</span>
							</button>
						</div>
					</Form>
				</Container>

				<Footer />
			</main>
		</React.Fragment>
	);
}

export default DeliveryWindow;
