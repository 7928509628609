const loadUnloadingValues = (appSetting) => {
    let items = [];
    let min = appSetting.BungiiSettings.MinLoadUnloadTime / 1000 / 60;
    let max = appSetting.BungiiSettings.MaxLoadUnloadTime / 1000 / 60;
    let interval = appSetting.BungiiSettings.LoadUnloadTimeInterval / 1000 / 60;
    for (let i = min; i <= max; i = i + interval) {
        items.push({ value: i * 1000 * 60, name: i + ' minutes' });
    }
    items[items.length - 1]['name'] = `${items[items.length - 1]['value'] / 1000 / 60}+ minutes`;
    return items;
}

export default loadUnloadingValues;