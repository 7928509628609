import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
    pickupPoint: '',
    minDate: new Date(),
    geofence: [],
    defaultGeofenceIndex: null,
    pickupDate: new Date(),
    isDuo: false,
    defaultPoint: {},
    pickupAdd: '',
    pickupAddObj: '',
    defaultAdd: '',
    loadUnloadTimeShow: false,
    defaultGeofence: '',
    pickupTime: '',
    tripType: '',
    isMuscleHelpRequired : '',
    dropoffAdd: '',
    dropoffAddObj: {},
    loadTime: '',
    dropoffPoint: '',
    timeZone: '',
    estimate: null,
    mileageCap: '',
    pickupRequestID: null,
    orderRef: null,
    showCheckbox: false,
    driverHelpCarryFieldMessage: '',
    serviceLevel: null,
    serviceLevelValues: '',
    directions: '',
    deliveryTime: '',
    distance: '',
    pickupAddressess: [],
    timeSlots: [],
    pickUpTimeFirstEntryValue: '',
    selectedAddressRef: '',
    editPickupValue: false,
    isDateChanged: false,
    partnerUpchargeValue: '',
    isServiceLevelUpdated: false,
    isBoxTruck: false,
}

export const getEstimateSlice = createSlice({
	name: "getEstimate",
	initialState: INITIAL_STATE,
	reducers: {
		setPickupPoint: (state, action) => {
            state.pickupPoint = action.payload;
		},
		setMinDate: (state, action) => {
			state.minDate = action.payload;
		},
		setGeofence: (state, action) => {
			state.geofence = action.payload;
		},
		setDefaultGeofenceIndex: (state, action) => {
			state.defaultGeofenceIndex = action.payload
		},
		setPickupDate: (state, action) => {
			state.pickupDate = action.payload;
		},
		setIsDuo: (state, action) => {
			state.isDuo = action.payload
		},
        setDefaultPoint : (state, action) => {
            state.defaultPoint = action.payload
        },
        setPickupAdd : (state, action) => {
            state.pickupAdd= action.payload
        },
        setPickupAddObj : (state, action) => {
            state.pickupAddObj = action.payload
        },
        setDefaultAdd : (state, action) => {
            state.defaultAdd = action.payload
        },
        setLoadUnloadTimeShow : (state, action) => {
            state.loadUnloadTimeShow = action.payload
        },
        setMaxDate : (state, action) => {
            // state.
        },
        setDefaultGeofence : (state, action) => {
            state.defaultGeofence = action.payload
        },
        setPckupTime : (state, action) => {
            state.pickupTime = action.payload
        },
        setTripTypeValue : (state, action) => {
            state.tripType = action.payload
        },
        setIsMuscleHelpRequired : (state, action) => {
            state.isMuscleHelpRequired = action.payload
        },
        setDropoffAdd : (state, action) => {
            state.dropoffAdd = action.payload
        },
        setDropoffAddObj : (state, action) => {
            state.dropoffAddObj = action.payload
        },
        setLoadTimeValue : (state, action) => {
            state.loadTime = action.payload
        },
        setDropoffPoint : (state, action) => {
            state.dropoffPoint = action.payload
        },
        setTimeZone : (state, action) => {
            state.timeZone = action.payload
        },
        setEstimate : (state, action) => {
            state.estimate = action.payload
        },
        setPickupRequestID : (state, action) => {
            state.pickupRequestID = action.payload
        },
        setOrderRef : (state, action) => {
            state.orderRef = action.payload
        },
        setShowCheckbox : (state, action) => {
            state.showCheckbox = action.payload
        },
        setDriverHelpCarryFieldMessage : (state, action) => {
            state.driverHelpCarryFieldMessage = action.payload
        },
        setServiceLevel : (state, action) => {
            state.serviceLevel = action.payload
        },
        setDirections : (state, action) => {
            state.directions = action.payload
        },
        setServiceLevelValues : (state, action) => {
            state.serviceLevelValues = action.payload
        },
        setDeliveryTime : (state, action) => {
            state.deliveryTime = action.payload
        },
        setDistance : (state, action) => {
            state.distance = action.payload
        },
        setPickupAddressess : (state, action) => {
            state.pickupAddressess = action.payload
        },
        setTimeSlots : (state, action) => {
            state.timeSlots = action.payload
        },
        setPickUpTimeFirstEntryValue : (state, action) => {
            state.pickUpTimeFirstEntryValue = action.payload
        },
        setSelectedAddressRef : (state, action) => {
            state.selectedAddressRef =  action.payload
        },
        setEditPickup : (state, action) => {
            state.editPickupValue = action.payload
        },
        setIsDateChanged : (state, action) => {
            state.isDateChanged = action.payload
        },
        setPartnerUpchargeValue : (state, action) => {
            state.partnerUpchargeValue = action.payload
        },
        setIsServiceLevelUpdated : (state, action) => {
            state.isServiceLevelUpdated = action.payload
        },
        setIsBoxTruck: (state, action) => {
            state.isBoxTruck = action.payload
        },
        resetDataOnPageChange : (state, action)=>{
                state.tripType = ''
                state.isMuscleHelpRequired = ''
                state.pickupAdd = ''
                state.dropoffAdd = ''
                state.loadTime = ''
                state.pickupDate = new Date()
                state.dropoffPoint = ''
                state.pickupPoint = ''
                state.pickupTime = ''
                state.serviceLevel = null
                state.defaultGeofenceIndex = (action.payload.defaultGeofenceIndex > -1) ? action.payload.defaultGeofenceIndex : null
                state.defaultGeofence = (action.payload.defaultGeofence) ? action.payload.defaultGeofence : ''
                state.timeZone = (action.payload.timeZone) ? action.payload.timeZone : ''
                state.estimate = null
                state.pickupAddObj = ''
                state.dropoffAddObj = ''
                state.pickupRequestID = null
                state.orderRef = null
                state.serviceLevelValues = ''
                state.deliveryTime = ''
                state.distance = ''
                state.pickupAddressess = []
                state.timeSlots = []
                state.pickUpTimeFirstEntryValue = ''
                state.selectedAddressRef = ''
                state.editPickupValue = false
                state.isDateChanged = false
                state.partnerUpchargeValue = ''
                state.isServiceLevelUpdated = false
                state.isBoxTruck = false
        },
        resetDataOnLogout : (state, action)=>{
            state.geofence = []
            state.minDate = new Date()
            state.isDuo = false
            state.defaultPoint = {}
            state.defaultAdd = ''
            state.loadUnloadTimeShow = false
            state.mileageCap = ''
            state.showCheckbox = false
            state.driverHelpCarryFieldMessage = ''
            state.directions = ''
            state.isDateChanged = false
            state.partnerUpchargeValue = ''
            state.isServiceLevelUpdated = false
            state.isBoxTruck = false
        }
	},
});

// Action creators are generated for each case reducer function
export const {
    setPickupPoint,
    setMinDate,
    setGeofence,
    setDefaultGeofenceIndex,
    setPickupDate,
    setIsDuo,
    setDefaultPoint,
    setPickupAdd,
    setPickupAddObj,
    setDefaultAdd,
    setLoadUnloadTimeShow,
    setMaxDate,
    setDefaultGeofence,
    setPckupTime,
    setTripTypeValue,
    setIsMuscleHelpRequired,
    setDropoffAdd,
    setDropoffAddObj,
    setLoadTimeValue,
    setDropoffPoint,
    setTimeZone,
    setEstimate,
    setPickupRequestID,
    setOrderRef,
    setShowCheckbox,
    setDriverHelpCarryFieldMessage,
    setServiceLevel,
    setDirections,
    setServiceLevelValues,
    setDeliveryTime,
    setDistance,
    setPickupAddressess,
    setTimeSlots,
    setPickUpTimeFirstEntryValue,
    setSelectedAddressRef,
    setEditPickup,
    setIsDateChanged,
    setPartnerUpchargeValue,
    setIsServiceLevelUpdated,
    setIsBoxTruck,
	resetDataOnPageChange,
	resetDataOnLogout,
} = getEstimateSlice.actions;

export default getEstimateSlice.reducer;


// export default getEstimateReducer;