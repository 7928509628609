import React  from 'react';
import { withRouter } from 'react-router';
import storageService from '../../shared/services/StorageService';

/* Redux Import Start */
import { connect } from 'react-redux';
/* Redux Import End */

function guardStepProcess(Component) {
    class guardStepProcessComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                validTab: false
            }
        }

        /**
         * componentWillMount()
         * This function Executes just before rendering takes place.
         */
        componentWillMount = async() => {
            this.checkAuth();
        }
        
        /**
         * checkAuth()
         * This function will act as a guard to redirect based on the conditions.
         */
        checkAuth = async() => {
            const { activeTab, accessToken } = this.props;
            let accessTokenfromStore = await storageService.getSessionStorageItem("accessToken");
            const location = this.props.location;
            const redirect = location.pathname + location.search;

            let valid = true;
            
            if ( accessTokenfromStore === null) {
                this.props.history.push(`/login`);
                valid = false;
                return;
            }

            if ( accessToken === '') {
                this.props.history.push(`/login`);
                valid = false;
                return;
            }
            
            if(activeTab === 'getEstimate' && redirect !== '/request-bungii') {
                this.props.history.push("/request-bungii");
                valid = false;
                return;
            }

            if(activeTab === 'deliveryDetails' && redirect !== '/delivery-details') {
                this.props.history.push("/delivery-details");
                valid = false;
                return;
            }

            if(activeTab === 'done' && redirect !== '/success') {
                this.props.history.push("/success");
                valid = false;
                return;
            }

            if(valid) {
                this.setState({
                    validTab: true
                })
            }
        }

        render() {
            return this.state.validTab ? <Component { ...this.props } /> : 'null';
        }
    }
    
    return connect(
        (
            { 
                user: {
                    accessToken
                },
                common: {
                    activeTab
                }
            }
        ) => 
        (
            {
                accessToken,
                activeTab
            }
        ),
        ''
    )(withRouter(guardStepProcessComponent));
}

export default guardStepProcess;