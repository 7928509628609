import ReactGA from "react-ga4";
/**
 * sendEvent()
 * This function is used to send trigger event to GA
 */
 export const sendEvent = (eventName, action, data) => {
	let eventParams = {
		action,
		...data
	};
	return ReactGA.event(eventName, eventParams);
};