/* Redux Import Start */
import {store} from '../redux/store';
import { dateUtils } from '../../shared/utilities/dateUtils';
import moment from 'moment-timezone';
import sec2timeValues from '../../shared/helpers/sec2timeValues'
import { getWorkingHours } from './getWorkingHours';
import { getStartTimeWithLeadTime } from './getStartTimeWithLeadTime';

/* Redux Import End */

/**
 * setMinMaxTimeValues()
 * This function is used to get pickup timings for a particular date
 */
 const setMinMaxTimeValues = (date) => {
    const { pickupDate } = store.getState().getEstimate;
    const { appSetting } = store.getState().common;
    const items = [];
    const pickupDateVal = (date) ? dateUtils.getDate(new Date(date)) : dateUtils.getDate(new Date(pickupDate));
    const {WorkingHoursStart, WorkingHoursEnd} = getWorkingHours();
    const min = WorkingHoursStart;
    const max = WorkingHoursEnd;
    const interval = appSetting.BungiiSettings.SchedulingBlockPeriod;

    const currentTimeWithBuffer = getStartTimeWithLeadTime();

    for (let i = min; i <= max; i = i + interval) {        
        // Get slot Time ( EPOCH time value )
        const time = moment(i).utc().format('HH:mm:ss');
        const slotTime = dateUtils.getTimeStamp(pickupDateVal + " " + time);

        // Display all time slots greater than ( current Time + Buffer Time ) where date is pickup date
        if (slotTime > currentTimeWithBuffer.toFixed(0)) {
            items.push({ value: sec2timeValues(i) });
        }
    }
    return items;
}

export default setMinMaxTimeValues;