import React, { Component } from "react";
import timeIcon from "../../assets/images/icons/time.png";
import locationIcon from "../../assets/images/icons/location-regular.svg";

/* Redux Import Start */
import { connect } from "react-redux";

/* Redux Import End */

class EstimateInfo extends Component {
	render() {
		const { deliveryTime, distance, quoteOnlyUser } = this.props;
		return (
			<div className={quoteOnlyUser ? "estimate-info mt-0" : "estimate-info"}>
				{!quoteOnlyUser && (
					<div className="d-inline-block pr-3">
						<img src={timeIcon} alt="Est. Delivery Time" />
						<label>
							Est. Delivery Time - <strong>{deliveryTime}</strong>
						</label>
					</div>
				)}
				<div className={quoteOnlyUser ? "d-inline-block" : "d-inline-block"}>
					<img src={locationIcon} alt="Distance in Miles" />
					<label>
						Distance in Miles - <strong>{distance + " miles"}</strong>
					</label>
				</div>
			</div>
		);
	}
}

export default connect(
	({ getEstimate: { deliveryTime, distance }, user: { quoteOnlyUser } }) => ({
		deliveryTime,
		distance,
		quoteOnlyUser,
	}),
	""
)(EstimateInfo);
