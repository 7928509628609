import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { handleGetReq } from "../services/AppendHeadersService";
import appConfig from "../appConfig/appConfig";
import { useSelector } from "react-redux";

const DashboardMetrics = () => {
	const { accessToken } = useSelector((state) => state.user);
	const [dashboardMetrics, setDashboardMetrics] = useState();
	const getDashboardMetricsData = async () => {
		try{
			let dashboardMetrics = await handleGetReq(
				appConfig.urls.dashboardMetrics,
				"",
				accessToken,
			);
			if (dashboardMetrics !== undefined && dashboardMetrics.data.Error === null) {
				setDashboardMetrics(dashboardMetrics.data.DashboardMetrics);
			}  
		}catch(e){
			console.log(e)
		}
	};
	useEffect(() => {
        getDashboardMetricsData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

	return (
		<Row className="metrics">
			<Col className="total-deliveries p-r-9">
				<div className="card">
					<label>Total Deliveries</label>
					<p>
						{dashboardMetrics?.TotalDeliveriesCompleted}
					</p>
				</div>
			</Col>
			<Col className="avg-rating p-l-7 p-r-9">
				<div className="card">
					<label>Avg. Driver Rating</label>
					<p>
						{dashboardMetrics?.AverageDriverRating}
						<span>stars</span>
					</p>
				</div>
			</Col>
			<Col className="avg-cost p-l-7 p-r-9">
				<div className="card">
					<label>Average Cost</label>
					<p>
						<sup>$</sup>
						{dashboardMetrics?.AverageCost}
					</p>
				</div>
			</Col>
			<Col className="avg-distance p-l-7 p-r-9">
				<div className="card">
					<label>Average Distance</label>
					<p>
						{dashboardMetrics?.AverageDistance}
						<span>miles</span>
					</p>
				</div>
			</Col>
			<Col className="current-deliveries p-l-7">
				<div className="card">
					<label className="text-grey">Current Deliveries</label>
					<p>{dashboardMetrics?.CurrentDeliveries}</p>
				</div>
			</Col>
		</Row>
	);
};

export default DashboardMetrics;
