import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'

import userReducer from './user.reducer';
import getEstimateReducer from './getEstimate.reducer'
import deliveryDetailsReducer from './deliveryDetails.reducer'
import dashboardReducer from './dashboard.reducer'
import commonReducer from './common.reducer'

const encryptor = encryptTransform({
    secretKey: '2wd3KicKiF',
    onError: function(error){
        localStorage.clear();
        sessionStorage.clear();
        window.location.href('/');
    }
})

const persistConfig = {
    key: 'ZUWam',
    storage,
    // whitelist: ['getEstimate','user','deliveryDetails','dashboard', 'common'],
    transforms: [encryptor]
}

export const rootReducer = combineReducers({
    user: userReducer,
    getEstimate: getEstimateReducer,
    deliveryDetails: deliveryDetailsReducer,
    dashboard: dashboardReducer,
    common: commonReducer
})

export default persistReducer(persistConfig,rootReducer);