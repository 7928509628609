import { dashboardActionTypes } from '../actions/dashboard.actions';

const INITIAL_STATE = {
    tripDetails: '',
}

const dashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case dashboardActionTypes.SET_TRIP_DETAILS:
            return {
                ...state,
                tripDetails: action.payload
            }
        default:
            return state;
    }
}

export default dashboardReducer;