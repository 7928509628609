import { commonActionTypes } from '../actions/common.actions';

const INITIAL_STATE = {
    activeTab: 'getEstimate',
    appSetting: '',
    showLoader: false,
    formFilled: false,
    kioskMode: null,
    displayKioskMode: false,
    lockedAccess: false,
    showPrice: true,
    isVisibleSoloDuo: ''
}

const commonReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case commonActionTypes.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload
            }
        case commonActionTypes.SET_APP_SETTING:
            return {
                ...state,
                appSetting: action.payload
            }
        case commonActionTypes.SET_SHOW_LOADER:
            return {
                ...state,
                showLoader: action.payload
            }
        case commonActionTypes.SET_FORM_IS_FILLED:
            return {
                ...state,
                formFilled: action.payload
            }
        case commonActionTypes.SET_COMMON_INITIAL_STATE:
            return {
                ...state,
                activeTab: 'getEstimate',
                showLoader: false,
                formFilled: false,
                showPrice: true,
                isVisibleSoloDuo: ''
            }
        case commonActionTypes.SET_KIOSK_MODE:
            return {
                ...state,
                kioskMode: action.payload
            }
        case commonActionTypes.SET_DISPLAY_KIOSK_MODE:
            return {
                ...state,
                displayKioskMode: action.payload
            }
        case commonActionTypes.SET_LOCKED_ACCESS:
            return {
                ...state,
                lockedAccess: action.payload
            }
        case commonActionTypes.SET_SHOW_PRICE:
            return {
                ...state,
                showPrice: action.payload
            }
        case commonActionTypes.SET_SHOW_SOLO_DUO:
            return {
                ...state,
                isVisibleSoloDuo: action.payload
            }
        default:
            return state;
    }
}

export default commonReducer;