import languageConstants from '../../shared/appConfig/languageConstants';

export const ValidateLogin = (value) => {
    let errors = {};

    if (!value.password) {
        errors.password = "Password is required.";
    }
    return errors;
}

export const validateReqBungii = (value) => {
    let errors = {};
    if (!value.pickupAdd) {
        errors.pickupAdd = "Pickup Address is required.";
    }

    if (!value.dropoffAdd) {
        errors.dropoffAdd = "Drop Off Address is required.";
    }

    if (value.loadTime !== 0 && !value.loadTime) {
        errors.loadTime = "Load Time is required.";
    }

    if (value.pickupTime !== 0 && !value.pickupTime) {
        errors.pickupTime = "Pickup Time is required.";
    }

    if (value.serviceLevel !== false && !value.serviceLevel) {
        errors.serviceLevel = "Service Level is required.";
    }

    if (!value.captchaValue) {
        errors.captchaValue = "Captcha is required.";
    }

    return errors;
}


export const validateReqDetails = (value) => {
    let errors = {};
    Object.keys(value).forEach(e => {
        if (value[e] === null || value[e] === '')
            (errors[e] = "This field is required.")
    })

    return errors;
}

export const validateSkuNumber = (value) => {
    let errors = '';
    if (value !== null && !value) {
        errors = languageConstants.errors.skuItemsToDeliver.emptySku;
    } else if (value.length < 7) {
        errors = languageConstants.errors.skuItemsToDeliver.skuLength;
    }

    return errors;
}
