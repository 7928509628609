import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../../assets/sass/modules/_loader.scss';
import loader from '../../assets/images/bungii-preloader.gif'

/* Redux Import Start */
import { connect } from 'react-redux';

/* Redux Import End */

//This component is for loading the loader throughout the application.
class Loader extends Component {
    constructor() {
        super();
        this.state = {
            hide: true
        }
    }

    render() {
        const { hide } = this.state;
        const { showLoader } = this.props
        return (
            (hide === false || showLoader === true) && <div className="loader">
                <img src={loader} alt="Loader"/>
            </div>
        )
    }
}

export default connect(
    (
        { 
            common: {
                showLoader
            }
        }
    ) => 
    (
        {
            showLoader
        }
    ),
    ''
)(withRouter(Loader));
