import { GET, POST, PATCH } from './HttpService';
import urlConstants from "../appConfig/urlConstants";

const checkIfNotAuthorized = (resp) => {
  if(resp?.data?.Error?.Code === 20009) window.location.href = '/';
}

export const handleGetReq = async (apiURL, params, authToken = '') => {
  let headers = {
    'Authorization': urlConstants.Authorization,
    'AppVersion': 4.0,
    'Content-Type': 'application/json',
    'AuthorizationToken':authToken,
    'Pragma': 'no-cache',
    'cache-control': 'no-store, no-cache, must-revalidate' 
  }
  const response = await GET(apiURL, params, headers );
  checkIfNotAuthorized(response);
  return response;
}

export const handlePostReq = async (apiURL, data, authToken = '') => {
  let headers = {
    'Authorization': urlConstants.Authorization,
    'AppVersion': 4.0,
    'Content-Type': 'application/json',
    'AuthorizationToken': authToken
  }
  let postResponse = await POST(apiURL, data, headers )
    .then(response => {
      checkIfNotAuthorized(response);
      return response
    })
    .catch(error => {
      return error.response
    });
  return postResponse
}

export const handleLoginGetReq = async (apiURL, params) => {
  let headers = {
    'Authorization': urlConstants.Authorization,
    'Content-Type': 'application/json',
  }
  const response = await GET(apiURL, params, headers );
  checkIfNotAuthorized(response);
  return response;
}

export const handleLoginPostReq = async (apiURL, data) => {
  let headers = {
    'Authorization': urlConstants.Authorization,
    'Content-Type': 'application/json',
  }
  let postResponse = await POST(apiURL, data, headers )
    .then(response => {
      checkIfNotAuthorized(response);
      return response
    })
    .catch(error => {
      return error.response
    });
  return postResponse
}

export const handlePatchReq = async (apiURL, data, authToken = '') => {
  let headers = {
    'Authorization': urlConstants.Authorization,
    'AppVersion': 4.0,
    'Content-Type': 'application/json',
    'AuthorizationToken': authToken
  }
  let postResponse = await PATCH(apiURL, data, headers )
    .then(response => {
      checkIfNotAuthorized(response);
      return response
    })
    .catch(error => {
      return error.response
    });
  return postResponse
}

export const handleTripDetailsReq = async (apiURL, data, authToken = '') => {
  let headers = {
    'Authorization': urlConstants.Authorization,
    'AppVersion': 4.0,
    'Content-Type': 'application/json',
    'AuthorizationToken': authToken,
    'cache-control': 'no-cache',
  }
  let postResponse = await PATCH(apiURL, data, headers )
    .then(response => {
      checkIfNotAuthorized(response);
      return response
    })
    .catch(error => {
      return error.response
    });
  return postResponse
}

export const handleDeliveryStatusReq = async (apiURL, params = '') => {
  let headers = {}
  const response = await GET(apiURL, params, headers );
  checkIfNotAuthorized(response);
  return response;
}
