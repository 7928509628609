import React, { Component } from 'react';
import { Form, ButtonGroup } from 'react-bootstrap';
import Alert from '../common/Alert';
import TextField from '@material-ui/core/TextField';
import languageConstants from '../../../shared/appConfig/languageConstants';

/* Redux Import Start */
import { connect } from 'react-redux';

/* Redux Import End */

class Password extends Component {

    render() {
        const { kioskMode } = this.props;
        return (
            <React.Fragment>
                <Alert showAlertModal={this.props.open} handleCloseAlert={this.props.cancelSubscription} showCloseIcon={true} alertType={'alert-md'}>
                    <h2>Admin Password Required</h2>
                    {this.props.clickedOnSetting && 
                        <p>{kioskMode ? languageConstants.modals.exitAccess : languageConstants.modals.gainAccess}</p>
                    }
                    {this.props.clickedPay &&
                        <p>{languageConstants.modals.clickedPay}</p>
                    }
                    <Form className="form-content" onSubmit={this.props.subscribe} noValidate>
                        <Form.Group controlId="formBasicPassword">
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Password"
                                type="password"
                                fullWidth
                                className={this.props.errors.password ? 'invalid' : ''}
                                onChange={this.props.onPasswordChange}
                            />
                            <div className="invalid-feedback d-block text-left">{this.props.errors}</div>
                        </Form.Group>
                        <ButtonGroup>
                            {kioskMode ?
                                <div>
                                    <button className="btn" type="submit">Exit access</button>
                                </div>
                            : <button className="btn" type="submit">Continue</button>
                            }
                        </ButtonGroup>
                    </Form>
                </Alert>
            </React.Fragment >
        );
    }
}

export default connect(
    (
        { 
            common: {
                kioskMode
            } 
        }
    ) => (
        {
            kioskMode
        }
    ),
    ''
)(Password);
