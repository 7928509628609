import { useField } from "formik";
import React from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import TextField from "./Field";

const TextFieldWithLabel = ({
	label,
	IsRequired = false,
	PlaceHolder,
	groupClass,
	showRemainingCharCount,
	staticField = false,
	...props
}) => {
	const [field, meta] = useField(props);
	const { value } = field;

	return (
		<FormGroup className={groupClass}>
			<FormLabel>
				{label}
				{IsRequired && <span className="text-mandatory">*</span>}
				{PlaceHolder && <i className="custom-placeholder">({PlaceHolder})</i>}
			</FormLabel>
			<TextField {...props} />
			{showRemainingCharCount && (
				<div className={"float-right form-text small"}>
					Characters remaining:{" "}
					{value ? props.maxLength - value.length : props.maxLength}/
					{props.maxLength}
				</div>
			)}
			<FormControl.Feedback type="invalid">
				{staticField ? label + " " : ""}
				{meta.error}
			</FormControl.Feedback>
		</FormGroup>
	);
};

export default TextFieldWithLabel;
