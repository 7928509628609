import React  from 'react';
import { withRouter } from 'react-router';
import storageService from '../../shared/services/StorageService';

/* Redux Import Start */
import { connect } from 'react-redux';
/* Redux Import End */

function guardIsAllowed(Component) {
    class isAllowedComponent extends React.Component {  

        /**
         * componentWillMount()
         * This function Executes just before rendering takes place.
         */
        componentWillMount = async() => {
            this.checkAuth();
        }

        /**
         * checkAuth()
         * This function will act as a guard to redirect based on the conditions.
         */
        checkAuth = async() => {
            
            const { accessToken, lockedAccess, quickAccessUser } = this.props;
            let accessTokenfromStore = await storageService.getSessionStorageItem("accessToken");   
            
            if ( accessTokenfromStore === null) {
                window.location.href = '/login';
            } else if(lockedAccess === true || quickAccessUser === true) {
                window.location.href = '/request-bungii';
            } else if(accessToken === '' || accessToken === null) {
                window.location.href = '/login';
            }
        }

        render() {
            const { accessToken } = this.props;
            return accessToken
            ? <Component { ...this.props } />
            : null;
        }
    }
    
    return connect(
        (
            { 
                user: {
                    accessToken, quickAccessUser
                },
                common: {
                    lockedAccess
                }
            }
        ) => 
        (
            {
                accessToken,
                lockedAccess,
                quickAccessUser
            }
        ),
        ''
    )(withRouter(isAllowedComponent));
}

export default guardIsAllowed;