/* Redux Import Start */
import {store} from '../redux/store';

/* Redux Import End */

const clearAppsettingData = () => {
    const { appSetting } = store.getState().common;
    if(appSetting && appSetting.PartnerLocationSettings && appSetting.PartnerLocationSettings.StaticFields){
        appSetting.PartnerLocationSettings.StaticFields.forEach(function(value,i){
            delete appSetting.PartnerLocationSettings.StaticFields[i].FieldValue;
        })
    }
    if(appSetting && appSetting.PartnerLocationSettings && appSetting.PartnerLocationSettings.CustomFields){
        appSetting.PartnerLocationSettings.CustomFields.forEach(function(value,i){
            delete appSetting.PartnerLocationSettings.CustomFields[i].FieldValue;
        });
    }
}

export default clearAppsettingData;