import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { dateUtils } from "../../shared/utilities/dateUtils";
import moment from "moment-timezone";
import languageConstants from "../appConfig/languageConstants";

class SelectBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropValue: "",
			pickValue: "",
		};
	}

	/**
	 * disableKeys()
	 * Disable all the keys if state if not empty
	 */
	disableKeys = (event, type) => {
		const { pickupAdd, dropoffAdd } = this.props;
		if (type === "dropoffAdd") {
			if (dropoffAdd !== "") {
				event.preventDefault();
			}
		}
		if (type === "pickupAdd") {
			if (pickupAdd !== "") {
				event.preventDefault();
			}
		}
	};

	render() {
		return (
			<div
				className={
					this.props.type === "pickupTime" ? "d-inline-flex w-100" : ""
				}
			>
				{this.props.type === "loadTime" ? (
					<Select
						required
						className={
							"load-time-select " + (this.props.class ? "invalid" : "")
						}
						displayEmpty
						value={this.props.value || ""}
						onChange={this.props.onChange}
						size="small"
					>
						<MenuItem value="" disabled>
							Select
						</MenuItem>
						{this.props.items &&
							this.props.items.map((e, key) => (
								<MenuItem key={key} value={e.value}>
									{e.name}
								</MenuItem>
							))}
					</Select>
				) : this.props.type === "pickupDate" ? (
					<Select
						required
						MenuProps={this.props.class}
						value={dateUtils.getDatePickerDateValues(
							new Date(this.props.value)
						)}
						onChange={this.props.onChange}
						size="small"
					>
						{this.props.items.length > 0 &&
							this.props.items.map((e, key) => (
								<MenuItem
									key={key}
									value={dateUtils.getDatePickerDateValues(new Date(e))}
								>
									{dateUtils.getDatePickerDate(
										new Date(e),
										key,
										this.props.workingHours
									)}
								</MenuItem>
							))}
					</Select>
				) : this.props.type === "pickupTime" ? (
					this.props.slotEnabled ? (
						<Select
							MenuProps={this.props.class}
							required
							value={this.props.value || ""}
							onChange={this.props.onChange}
							displayEmpty
							size="small"
							className={this.props.isError ? "invalid" : ""}
						>
							<MenuItem value="" disabled>
								Select
							</MenuItem>
							{this.props?.items?.map((e, key) => (
								<MenuItem
									key={key}
									value={moment(e.time_slot_from, "HH:mm:ss").format("HH:mm")}
									disabled={
										(this.props.tripType <= 2 && e.total_slot_count - e.slot_used_count >=
												this.props.tripType)
												? false
												: true
									}
								>
									{moment(e.time_slot_from, "HH:mm").format("hh:mm A")}
								</MenuItem>
							))}
							{this.props.items.length === 0 && (
								<MenuItem value="" disabled>
									{languageConstants.timeSlots.noSlotsAvailable}
								</MenuItem>
							)}
						</Select>
					) : (
						<Select
							MenuProps={this.props.class}
							required
							value={
								this.props.items.length === 0 ? "-1" : this.props.value || ""
							}
							onChange={this.props.onChange}
							size="small"
						>
							{this.props.items &&
								this.props.items.map((e, key) => (
									<MenuItem key={key} value={e.value}>
										{moment(e.value, "HH:mm").format("hh:mm A")}
									</MenuItem>
								))}
							{this.props.items.length === 0 && (
								<MenuItem value="-1" disabled>
									{languageConstants.timeSlots.noSlotsAvailable}
								</MenuItem>
							)}
						</Select>
					)
				) : this.props.type === "pickupAddress" ? (
					<Select
						required
						value={this.props.value || ""}
						onChange={this.props.onChange}
						className={this.props.errors.pickupAdd ? "invalid" : ""}
					>
						{this.props.items &&
							this.props.items.map((e, key) => (
								<MenuItem key={key} value={e} data-key={key}>
									{`${this.props.addressLabelArray[key]}: ${e}`}
								</MenuItem>
							))}
					</Select>
				) : this.props.type === "customDropdown" ? (
					<Select
						required
						MenuProps={this.props.class}
						name={`${this.props.items.FieldRef}`}
						ref={`${this.props.items.FieldRef}`}
						value={this.props.value}
						displayEmpty
						onChange={this.props.onChange}
						size="small"
						className={this.props.error}
					>
						<MenuItem value="" disabled>
							Select
						</MenuItem>
						{this.props.items.ControlValues &&
							this.props.items.ControlValues.map((cntrlVal, key) => {
								return (
									<MenuItem key={key} value={cntrlVal}>
										{cntrlVal}
									</MenuItem>
								);
							})}
					</Select>
				) : (
					""
				)}
			</div>
		);
	}
}

export default SelectBox;
