export const PickupStatus = {
    ProcessingConfirmation: 4,
    PickupAccepted: 5,
    PickupReqErrored: 8,
    ScheduledPickupComplete: 13,
    AwaitingCustomerConfirmation : 17,
    TripStarted: 23,
    DriverArrived: 24,
    LoadingItems: 25,
    DrivingToDropoff: 26,
    UnloadingItems: 27,
    DrivingToReturn: 46,
    UnloadingAtReturn: 47,
    TripCompleted: 28,
    DriverCancelled: 66,
    AdminCancelled: 69,
    AdminCancelledWithFee: 70,
    PartnerCancelled: 72,
    PickupCompleted: 62,
}

export const pickupCancelledStatus = [
    PickupStatus.PickupReqErrored,
    PickupStatus.ScheduledPickupComplete,
    PickupStatus.DriverCancelled,
    PickupStatus.AdminCancelled,
    PickupStatus.AdminCancelledWithFee,
    PickupStatus.PartnerCancelled
];

export const feedRefreshStatuses = [
    PickupStatus.PickupCompleted
];
