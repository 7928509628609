import React, { Component } from "react";
import { NavLink } from "react-router-dom";

/* Redux Import Start */
import { connect } from "react-redux";

/* Redux Import End */

class SubNavigation extends Component {
	componentDidUpdate = async () => {
		try {
			const { activeTab, quoteOnlyUser } = this.props;
			if (!quoteOnlyUser) {
				if (activeTab === "getEstimate") {
					document
						.getElementById("bungii-tab-getEstimate")
						.classList.remove("completed");
					document
						.getElementById("bungii-tab-deliveryDetails")
						.classList.remove("completed");
				} else if (activeTab === "deliveryDetails") {
					document
						.getElementById("bungii-tab-getEstimate")
						.classList.add("completed");
				} else if (activeTab === "done") {
					document
						.getElementById("bungii-tab-getEstimate")
						.classList.add("completed");
					document
						.getElementById("bungii-tab-deliveryDetails")
						.classList.add("completed");
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	render() {
		const { appSetting, quoteOnlyUser } = this.props;
		return (
			<React.Fragment>
				{!quoteOnlyUser ? (
					<nav
						className={
							appSetting.PartnerLocationSettings.EstimatePageFields[0].IsVisible
								? "nav nav-tabs"
								: "nav nav-tabs m-b-30"
						}
						role="tablist"
					>
						<NavLink
							to="/request-bungii"
							className={
								appSetting.PartnerLocationSettings.EstimatePageLabel ===
								"Get Quote"
									? "nav-item nav-link get-quote d-none d-lg-block"
									: "nav-item nav-link d-none d-lg-block"
							}
							id="bungii-tab-getEstimate"
							activeClassName="active"
						>
							{appSetting.PartnerLocationSettings.EstimatePageLabel}
						</NavLink>
						<NavLink
							to="/delivery-details"
							className="nav-item nav-link d-none d-lg-block"
							id="bungii-tab-deliveryDetails"
							activeClassName="active"
						>
							Delivery Details
						</NavLink>
						<NavLink
							to="/success"
							className="nav-item nav-link d-none d-lg-block"
							activeClassName="active"
						>
							Done!
						</NavLink>
					</nav>
				) : (
					<div className="quote-only"></div>
				)}
			</React.Fragment>
		);
	}
}

export default connect(
	({
		user: { partnerLocationReference, accessToken, quoteOnlyUser },
		common: { showLoader, activeTab, appSetting },
	}) => ({
		activeTab,
		partnerLocationReference,
		accessToken,
		showLoader,
		appSetting,
		quoteOnlyUser,
	}),
	""
)(SubNavigation);
