import React, { Component } from 'react';
import appConfig from '../shared/appConfig/appConfig';
import { Row, Col, Modal, Media, Button, ButtonGroup, Image } from 'react-bootstrap';
import Map from '../shared/modules/Map';
import TripProgress from './TripProgress';
import Rating from 'react-rating';
import star from '../assets/images/icons/star.png';
import starFilled from '../assets/images/icons/star-filled.png'
import { handlePostReq, handleGetReq } from '../shared/services/AppendHeadersService';
import toasterService from '../shared/services/ToasterService';
import Alert from '../shared/layouts/common/Alert';
import { phoneFormat } from '../shared/modules/Phone';
import { dateUtils } from '../shared/utilities/dateUtils';
import { currencyUtils } from '../shared/utilities/currencyUtils';
import languageConstants from '../shared/appConfig/languageConstants';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactGA from 'react-ga4';
import driverIcon from '../assets/images/profile.png';
import { getFullAddress } from '../shared/modules/Address';
import * as Enums from '../shared/appConfig/enums';
import { nearestMinutes } from '../shared/helpers/DeliveryTimeRange';
import moment from 'moment-timezone';
import globalConstants from '../shared/appConfig/globalConstants';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setShowLoader } from '../shared/redux/actions/common.actions';
import { setTripDetails } from '../shared/redux/actions/dashboard.actions'
import TripDetailsTable from '../Components/TripDetailsTable';
import { sendEvent } from '../shared/utilities/sendEvent';

/* Redux Import End */

const feedRefreshStatuses = Enums.feedRefreshStatuses;
let refreshInterval = null;

const imageType = {
    DeliveryPickupVerificationItems : 0,
    DeliveryDropOffVerificationItems : 1,
    DeliveryReturnVerificationItems : 2
}
class TripDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlertModal: false,
            showSuccessModal: false,
            category: '',
            action: '',
            label: '',
            openDetailsData: [],
            toOpen: '',
            whatsNeededData: this.props.appSetting.PartnerLocationSettings.EstimatePageFields[0],
            estimatedTime: '',
        }
    }

    /**
     * sendEvent()
     * This function is used to send trigger event to GA
     */
    sendEvent = async (val) => {
        await this.setState({
            category: `CTA ${val} Button`,
            action: 'Click',
            label: val
        });
        ReactGA.event(this.state);
    };

    /**
     * componentWillMount()
     * This function Executes just before rendering takes place.
     */
    componentWillMount = async () => {
        try {
            await this.getDetails();
        }
        catch (err) {
            console.log(err)
        }

    }

    /**
     * getDetails()
     * This function is used to fetch the trip details.
     */
    getDetails = async () => {
        try {
            const { tripDetails, appSetting } = this.props;
            
            const {
                ScheduledDateTime: scheduledDateTime,
                TimezoneIdentifier: timezoneIdentifier,
                TimezoneDisplayName: timezoneDisplayName,
                PickupCost: pickupCostValue,
                PickupAddress: pickupAddress,
                DropOffAddress: dropOffAddress,
                EstDistance: estDistance,
                NoOfDrivers: noOfDrivers,
                PickupNote: pickupNote,
                CanBeCancelled: canBeCancelled
            } = tripDetails.PickupDetails;
            
            const {
                ServiceName: serviceName,
                PartnerServiceDescription: partnerServiceDescription
            } = (tripDetails.ServiceLevelDetails !== null) ? tripDetails.ServiceLevelDetails : '';
            
            await this.getDriverDetails();

            /*Add all the data in an array*/
            let allItemIndex = [];
            if(Array.isArray(pickupNote)) {
                pickupNote.map(function(x, i){
                    allItemIndex.push({
                        toOpen: i,
                        openDetails: false
                    })
                    return true
                });
            }

            let timeRange = nearestMinutes(globalConstants.RoundToNearestMinuteValue, moment(dateUtils.convertTimezone(tripDetails.PickupAdditionalInfo.EstimatedDeliveryWindowTime ? tripDetails.PickupAdditionalInfo.EstimatedDeliveryWindowTime : tripDetails.PickupAdditionalInfo.TeletTime, timezoneIdentifier, globalConstants.HourFormat), globalConstants.HourFormat));
            let scheduledTimestamp = dateUtils.convertTimezone(scheduledDateTime, timezoneIdentifier, `ddd, MMM DD [at] h:mm A [${timezoneDisplayName}]`);
            let pickupCost = (pickupCostValue) ? currencyUtils.formatCurrency(pickupCostValue) : '';
            await this.setState({
                customerDetails: tripDetails.CustomerDetails,
                pickupFullAddress: getFullAddress(pickupAddress),
                dropOffFullAddress: getFullAddress(dropOffAddress),
                pickupCost,
                scheduledTimestamp,
                estDistance: estDistance + ' miles',
                numberOfDrivers: (noOfDrivers === 1) && appSetting.PartnerLocationSettings.EstimatePageFields?.length > 0? 
                    appSetting.PartnerLocationSettings.EstimatePageFields[0]?.SoloSummarysectionvalue 
                    : appSetting.PartnerLocationSettings.EstimatePageFields[0]?.DuoSummarysectionvalue,
                pickupPoint: {
                    lat: pickupAddress.Location.Latitude,
                    lng: pickupAddress.Location.Longitude
                },
                dropoffPoint: {
                    lat: dropOffAddress.Location.Latitude,
                    lng: dropOffAddress.Location.Longitude
                },
                latestTripProgress: tripDetails.TripProgress,
                timezoneIdentifier: timezoneIdentifier,
                timezoneDisplayName: timezoneDisplayName,
                pickupNote: pickupNote,
                pickupAdditionalInfo: tripDetails.PickupAdditionalInfo,
                primaryInfo: tripDetails.PrimaryInfo,
                secondaryInfo: tripDetails.SecondaryInfo,
                canBeCancelled: canBeCancelled,
                customerPhone: await phoneFormat(tripDetails.CustomerDetails.CustomerPhone),
                serviceName: serviceName,
                partnerServiceDescription: partnerServiceDescription,
                openDetailsData: allItemIndex,
                estimatedTime: timeRange
            });

            // Make a polling call for latest trip progress after every minute
            refreshInterval = setInterval(() => {
                const tripComplete = this.state.latestTripProgress.filter(item => feedRefreshStatuses.includes(item.Status)).length > 0;
                (tripComplete) ? clearInterval() :
                this.refreshTripProgress();
            }, 60000)
        }
        catch (err) {
            console.log(err)
        }

    }

    /**
     * refreshTripProgress()
     * This function is called every minute if trip is not cancelled to check for any trip progress
     */
    refreshTripProgress = async () => {
        try{
            const { setTripDetails, tripDetails, accessToken, appSetting } = this.props;
            const { OriginalPickupRef } = tripDetails.PickupDetails;
            const url = appConfig.urls.deliveryListDetails;
            const resp = await handleGetReq(url, { pickuprequestid: OriginalPickupRef }, accessToken);
            const newTripProgress = resp.data.DeliveryStatustList.Statuses;
            const newDriverDetails = resp.data.DeliveryStatustList.DriverDetails;
            const newCanBeCancelled = resp.data.DeliveryStatustList.CanBeCancelled;
            const newNoOfDrivers = resp.data.DeliveryStatustList.NoOfDrivers;
            setTripDetails({...tripDetails, TripProgress: newTripProgress, 
                DriverDetails: newDriverDetails, 
                PickupDetails: {...tripDetails.PickupDetails, 
                    CanBeCancelled: newCanBeCancelled, 
                    NoOfDrivers: newNoOfDrivers
                }});
            this.setState({
                latestTripProgress: newTripProgress,
                canBeCancelled: newCanBeCancelled,
                numberOfDrivers: (newNoOfDrivers === 1) 
                    ? appSetting.PartnerLocationSettings.EstimatePageFields[0]?.SoloSummarysectionvalue 
                    : appSetting.PartnerLocationSettings.EstimatePageFields[0]?.DuoSummarysectionvalue,
            },() => this.getDriverDetails());
        }catch(e){
            console.log("Error while refreshing trip progress", e);
        }
    }

    /**
     * componentWillUnmount()
     * This function is called when the component is about to unmount to clear the interval
     */
    componentWillUnmount(){
        clearInterval(refreshInterval);
    }

    /**
     * getDriverDetails()
     * This function is used to get the Driver details.
     */
    getDriverDetails = async () => {
        try {
            const { tripDetails } = this.props;
            if (tripDetails !== undefined || tripDetails !== null) {
                await this.setState({
                    driverDetails: tripDetails.DriverDetails,
                })
                for (let i = 0; i < this.state.driverDetails.length; i++) {
                    let name = "driver" + (i + 1);
                    const lat = this.state.driverDetails[i].CurrentLocation.Latitude;
                    const lng = this.state.driverDetails[i].CurrentLocation.Longitude
                    if (lat && lng) {
                        await this.setState({
                            [name]: {
                                lat,
                                lng
                            }
                        })
                    }

                }

            }
        }
        catch (err) {
            console.log(err);
        }

    }

    /**
     * toggleAlertModal()
     * This function is used to toggle the alert modal.
     */
    toggleAlertModal = (stateKey) => {
        try {
            this.setState({
                [stateKey]: !this.state[stateKey]
            })
        } catch (err) {
            console.log(err)
        }

    }

    /**
     * cancelPickup()
     * This function is used to cancel the pickup.
     */
    cancelPickup = async () => {
        try {
            const { setShowLoader, accessToken } = this.props;
            
            let data = {
                "PickupRequestID": this.props.pickuprequestid
            }
            this.setState({
                showConfirmCancelModal: false
            })
            setShowLoader(true);
            let response = await handlePostReq(appConfig.urls.cancelPickup, data, accessToken);
            
            if (response !== undefined && response.data.Error === null) {
                if (response.data.DisplayCancelAlert === false) {
                    this.setState({
                        canBeCancelled: false,
                        showSuccessModal: true,
                    })
                    this.getPartnerDetails(this.props.pickuprequestid);
                    sendEvent(globalConstants.GAEventNames.CancelDelivery,"Click",{PickupRequestID:this.props.pickuprequestid})
                }
                else {
                    this.setState({
                        showAlertModal: true
                    })
                }
            }
            else {
                toasterService.showCustomToasty(response.data.Error.Message, "error");
            }
            setShowLoader(false);
        }
        catch (err) {
            console.log(err);
        }
    }

    /**
     * getPartnerDetails()
     * This function is used to get the partner details.
     */
    getPartnerDetails = async (pickuprequestid) => {
        try {
            const { setShowLoader, accessToken } = this.props;
            setShowLoader(true);
            let upadetdtripDetails = await handleGetReq(appConfig.urls.tripDetails, { pickuprequestid: pickuprequestid }, accessToken);
            if (upadetdtripDetails !== undefined && upadetdtripDetails.data.Error === null) {
                await this.setState({
                    latestTripProgress: upadetdtripDetails.data.Details.TripProgress,
                });
            }
            else {
                toasterService.showCustomToasty(upadetdtripDetails.data.Error.Message, "error");
            }
            setShowLoader(false);
        } catch (e) { console.log(e) }
    }

    /**
     * openDetails()
     * This function is used to hide and show the extra information from the item details.
     */
    openDetails = async(index) => {
        let currentData = [...this.state.openDetailsData];
        
        /*Check if the item is already open.*/
        const itemOpen = currentData.findIndex((item) => item.openDetails === true)
        if(itemOpen > -1 && index !== itemOpen) {
            /*If item is open and it is not the clicked one then make it false.*/
            currentData[itemOpen].openDetails = false
        }
        currentData[index].openDetails = !currentData[index].openDetails
        
        this.setState({
            openDetailsData: currentData
        })
    }

    render() {
        const { showModal, handleCloseModal, tripDetails, showPrice, appSetting } = this.props;
        const { photoIndex, isOpen, itemValue, customerDetails } = this.state;
        const {
            PickupDetails: pickupDetails
        } = tripDetails;

        const {
            TripRequestFiles: tripRequestFiles
        } = pickupDetails;
        return (
            <React.Fragment>
                <Modal show={showModal} onHide={handleCloseModal} animation={false} dialogClassName="custom-modal">
                    <Modal.Body className="p-0 show-trip-details">

                        <Col sm={4} className="left-sidebar">
                            <div className="content">
                                <label className='customer-label'>CUSTOMER</label>
                                <p className="description custom capitalizeName trim-cust-name">{this.state.customerDetails && this.state.customerDetails.CustomerName}</p>
                                <p className="phone-number">{this.state.customerDetails && this.state.customerPhone}</p>
                                {customerDetails && customerDetails.AdditionalCustomerPhoneNumbers && <>
                                    <label className="mb-2 mt-2" >Texting status updates to:</label>
                                    <p className="phone-number">{this.state.customerPhone}</p>
                                    {
                                        customerDetails.AdditionalCustomerPhoneNumbers?.map((key) => {
                                            return <p className="phone-number" key={key}>{phoneFormat(key)}</p>
                                        })
                                    }
                                </>
                                }
                                {
                                    pickupDetails && this.state.canBeCancelled && !this.props.isTrackDeliveriesEnabled &&
                                    <button className="btn cancel-delivery" onClick={() => this.toggleAlertModal('showConfirmCancelModal')}>CANCEL DELIVERY</button>
                                }
                            </div>
                            {

                                this.state.latestTripProgress &&
                                <TripProgress
                                    latestTripProgress={this.state.latestTripProgress}
                                    timezoneIdentifier={this.state.timezoneIdentifier}
                                    timezoneDisplayName={this.state.timezoneDisplayName} />
                            }

                        </Col>
                        <Col sm={8} className="detailed-information">
                            <div className="sticky-header">
                                <h2>Delivery Details <span className="modal-close" onClick={() => { handleCloseModal(); this.props.getData() }}></span></h2>
                            </div>
                            <div className="scrollable-content">
                            <Row>
                                    <Col sm={6} className="p-r-40">
                                        <section className="scheduled-details">
                                            <div className="content">
                                                <label>PICKUP TIME</label>
                                                <p className="description custom">{this.state.scheduledTimestamp}</p>
                                                <label>ESTIMATED DELIVERY TIME</label>
                                                <p className="description custom">{this.state.estimatedTime}</p>
                                            </div>
                                            <div className="trip-details success-trip-details">
                                            <div className="pickup-icon">
                                                <div className="content">
                                                    <label>Pickup Address:</label>
                                                    <p className="description">{pickupDetails && this.state.pickupFullAddress}</p>
                                                </div>
                                            </div>
                                            <div className="delivery-icon">
                                                <div className="content">
                                                    <label>Delivery Address:</label>
                                                    <p className="description">{pickupDetails && this.state.dropOffFullAddress}</p>
                                                    <p className="distance">Distance: <span>{pickupDetails && this.state.estDistance}</span></p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="scheduled-drivers">
                                            <div className="content">
                                                <label>DRIVER(S) 
                                                        {
                                                            pickupDetails.IsBoxTruckDelivery ?
                                                                <span className="yellow-label">
                                                                    SOLO
                                                                </span>
                                                                : this.props.isTrackDeliveriesEnabled && this.props.isEapiUser ?
                                                                    <span className="yellow-label">
                                                                        {pickupDetails?.NoOfDrivers === 2 ? 'DUO' : 'SOLO'}
                                                                    </span>
                                                                    : (appSetting.PartnerLocationSettings.EstimatePageFields?.length > 0 && appSetting.PartnerLocationSettings.EstimatePageFields[0].IsVisible)
                                                                        ? <span className="yellow-label">
                                                                            {pickupDetails?.IsMuscleHelpRequired ? "Large item(s) - 2 drivers"
                                                                                : this.state.whatsNeededData.UIType !== 5
                                                                                    ? pickupDetails && this.state.numberOfDrivers
                                                                                    : this.state.whatsNeededData.UIType === 5 ?
                                                                                        this.state.whatsNeededData.Summarysectionvalue
                                                                                        : ''
                                                                            }
                                                                        </span>
                                                                        : ''
                                                        }
                                                </label>
                                            </div>
                                            {(this.state.driverDetails && this.state.driverDetails.length > 0) ? 
                                                <Row>
                                                    {this.state.driverDetails.map((e, key) => {
                                                        return <Col sm={(pickupDetails.NoOfDrivers === 1) ? 12 : 6} key={key}>
                                                            <Media>
                                                                <img
                                                                    width={40}
                                                                    height={40}
                                                                    className="align-self-center mr-2 rounded-circle"
                                                                    src={e.ProfilePicURL}
                                                                    alt="driver"
                                                                />
                                                                <Media.Body className="content">
                                                                    <p
                                                                        className={((pickupDetails.NoOfDrivers === 1) ? '' : 'trim-name ') + 'description'}
                                                                        title={(pickupDetails.NoOfDrivers === 1) ? '' : (e.FirstName + ' ' + e.LastName)}>{e.FirstName + ' ' + e.LastName}</p>
                                                                    {
                                                                        <Rating initialRating={e.Rating ? e.Rating : 5} readonly
                                                                            emptySymbol={<img src={star} className="icon" alt="Empty Symbol" />}
                                                                            fullSymbol={<img src={starFilled} className="icon" alt="Full Symbol" />} />
                                                                    }
                                                                </Media.Body>
                                                            </Media>
                                                        </Col>;
                                                    })}
                                                </Row>
                                            :
                                                <Row>
                                                    <Col sm={(pickupDetails.NoOfDrivers > 1) ? 6 : 12}>
                                                        <Media>
                                                            <img
                                                                width={40}
                                                                height={40}
                                                                className="align-self-center mr-2 rounded-circle"
                                                                src={driverIcon}
                                                                alt="driver"
                                                            />
                                                            <Media.Body className="content">
                                                                <p className={'description'}>Driver {(pickupDetails.NoOfDrivers > 1) ? 1 : ''}</p>
                                                                <p className={'sub-title'}>Not assigned</p>
                                                            </Media.Body>
                                                        </Media>
                                                    </Col>
                                                    {(pickupDetails.NoOfDrivers > 1) &&
                                                        <Col sm={6}>
                                                            <Media>
                                                                <img
                                                                    width={40}
                                                                    height={40}
                                                                    className="align-self-center mr-2 rounded-circle"
                                                                    src={driverIcon}
                                                                    alt="driver"
                                                                />
                                                                <Media.Body className="content">
                                                                    <p className={'description'}>Driver 2</p>
                                                                    <p className={'sub-title'}>Not assigned</p>
                                                                </Media.Body>
                                                            </Media>
                                                        </Col>
                                                    }
                                                </Row>
                                            }
                                        </div>
                                    </section>
                                    <div>
                                        <h2 className="estimate-label">
                                            {(pickupDetails.PickupStatus === 9 ||
                                                pickupDetails.PickupStatus === 10 ||
                                                pickupDetails.PickupStatus === 11) || (appSetting.PartnerLocationSettings.PartnerHasServiceLevels) ? 'Delivery Charge' : 'Estimated Charge'}
                                            <span><strong>{showPrice ? pickupDetails && this.state.pickupCost : languageConstants.defaultPrice.bestBuy}</strong></span></h2>
                                    </div>
                                </Col>
                                <Col sm={6} className="p-l-0">
                                    <Map google={this.props.google}
                                        center={{ lat: pickupDetails.PickupAddress.Location.Latitude, lng: pickupDetails.PickupAddress.Location.Longitude }}
                                        zoom={15}
                                        pickupPoint={this.state.pickupPoint}
                                        dropoffPoint={this.state.dropoffPoint}
                                        driver1={this.state.driver1 && this.state.driver1}
                                        driver2={this.state.driver2 && this.state.driver2}
                                        isReturnTrip={tripDetails?.TripProgress?.some((state)=>state.Status === Enums.PickupStatus.DrivingToReturn)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="additional-details">
                                        <h2>Additional Details</h2>
                                        {
                                            pickupDetails && this.state.pickupNote &&
                                            < Row >
                                                <Col className="content">
                                                    <label>ITEM(S) TO DELIVER</label>
                                                    {(Array.isArray(this.state.pickupNote)) ?
                                                                <TripDetailsTable
                                                                    uiType={appSetting.PartnerLocationSettings.DeliveryDetailsFields && appSetting.PartnerLocationSettings.DeliveryDetailsFields[0]?.UIType}
                                                                    pickupNote={this.state.pickupNote}
                                                                    expandedData={this.state.openDetailsData}
                                                                    isTrackDeliveriesEnabled={this.props.isTrackDeliveriesEnabled}
                                                                    isMuscleHelpRequired={pickupDetails?.IsMuscleHelpRequired}
                                                                    onExpand={this.openDetails}
                                                                />
                                                    
                                                    : <p className="description">{this.state.pickupNote}</p>
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                        {this.state.serviceName && 
                                            <Row>
                                                <Col className="content">
                                                    <label>Service Level</label>
                                                    <p className="description"><span>{this.state.serviceName}</span> - {this.state.partnerServiceDescription} </p>
                                                </Col>
                                            </Row>
                                        }
                                        {
                                            this.state.pickupAdditionalInfo && this.state.pickupAdditionalInfo.DropOffInstructions &&
                                            <Row>
                                                <Col className="content">
                                                    <label>SPECIAL INSTRUCTIONS</label>
                                                    <p className="description">{this.state.pickupAdditionalInfo.DropOffInstructions ?? 'NA'}</p>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            {this.state.primaryInfo && this.state.primaryInfo.map((e, key) => {
                                                return <Col sm={4} key={key}>
                                                    <div className="content">
                                                        <label>{e.Label}</label>
                                                        {e.Details.map((item) => {
                                                            return item.FieldValue && (
                                                                item.Type === 'string' ? <p className="description capitalizeName" key={item.FieldName}>{item.FieldValue}</p> :
                                                                    item.Type === 'phone' ? <p className="phone-number" key={item.FieldName}>{phoneFormat(item.FieldValue)}</p> : '')
                                                        }
                                                        )}
                                                    </div>
                                                </Col>;

                                            }
                                            )}
                                        </Row>

                                        {this.state.secondaryInfo && this.state.secondaryInfo.map((e) => {
                                            return <Row className="content secondary" key={e.Label}>
                                                <Col className="p-l-0">
                                                    <label className="col-4 p-0">{e.Label}</label>
                                                    <p className="description">{e.FieldValue ? e.FieldValue : 'NA'}</p>
                                                </Col>
                                            </Row>
                                        })}

                                        {this.state.pickupAdditionalInfo && this.state.pickupAdditionalInfo.CustomFields.map((e) => {
                                            return <Row className="content secondary" key={e.FieldName}>
                                                <Col className="p-l-0">
                                                    <label className="col-4 p-0">{e.FieldName}</label>
                                                    <p className="description">{e.Value}</p>
                                                </Col>
                                            </Row>
                                        })}
                                    </div>
                                    {/* Image section start */}
                                    {tripRequestFiles.length > 0 &&
                                    <>
                                        <h2 className="m-t-0">Verification</h2>
                                        <Row className="verification-img">
                                            {tripRequestFiles[0]['DeliveryPickupVerificationItems'].length > 0 &&
                                                <div className="outside-container content">
                                                    <label>PHOTOS AT PICKUP</label>
                                                    <Row className="m-0">
                                                        {tripRequestFiles[0]['DeliveryPickupVerificationItems'] && tripRequestFiles[0]['DeliveryPickupVerificationItems'].map((e, key) => {
                                                            return <div className="col-3 img-container" key={key}>
                                                                <Image src={e} alt="Pickup Image"  onClick={() => this.setState({ isOpen: true, photoIndex: key, itemValue: "DeliveryPickupVerificationItems" })} thumbnail/>
                                                            </div>;
                                                            })
                                                        }
                                                    </Row>
                                                </div>
                                            }
                                            {tripRequestFiles[1]['DeliveryDropOffVerificationItems'].length > 0 &&
                                                <div className="outside-container content">
                                                    <label>PHOTOS AT DROP-OFF</label>
                                                    <Row className="m-0">
                                                        {tripRequestFiles[1]['DeliveryDropOffVerificationItems'] && tripRequestFiles[1]['DeliveryDropOffVerificationItems'].map((e, key) => {
                                                            return <div className="col-3 img-container" key={key}>
                                                                <Image src={e} alt="Dropoff Image"  onClick={() => this.setState({ isOpen: true, photoIndex: key, itemValue: "DeliveryDropOffVerificationItems" })} thumbnail/>
                                                            </div>;
                                                            })
                                                        }
                                                    </Row>
                                                </div>
                                            }
                                            {tripRequestFiles[2]['DeliveryReturnVerificationItems'].length > 0 &&
                                                <div className="outside-container content">
                                                    <label>PHOTOS AT RETURN</label>
                                                    <Row className="m-0">
                                                        {tripRequestFiles[2]['DeliveryReturnVerificationItems'] && tripRequestFiles[2]['DeliveryReturnVerificationItems'].map((e, key) => {
                                                            return <div className="col-3 img-container" key={key}>
                                                                <Image src={e} alt="Return Image"  onClick={() => this.setState({ isOpen: true, photoIndex: key, itemValue: "DeliveryReturnVerificationItems" })} thumbnail/>
                                                            </div>;
                                                            })
                                                        }
                                                    </Row>
                                                </div>
                                            }
                                        </Row>
                                    </>
                                    }
                                    {isOpen && (
                                        <Lightbox
                                            imageTitle={(itemValue === 'DeliveryPickupVerificationItems') ? 'Driver verification image - before' : 'Driver verification image - after'}
                                            enableZoom={false}
                                            mainSrc={tripRequestFiles[imageType[itemValue]][itemValue][photoIndex]}
                                            nextSrc={(tripRequestFiles[imageType[itemValue]][itemValue].length > 1 ) ? tripRequestFiles[imageType[itemValue]][itemValue][(photoIndex + 1) % tripRequestFiles[imageType[itemValue]][itemValue].length] : undefined}
                                            prevSrc={(tripRequestFiles[imageType[itemValue]][itemValue].length > 1 ) ? tripRequestFiles[imageType[itemValue]][itemValue][(photoIndex + tripRequestFiles[imageType[itemValue]][itemValue].length - 1) % tripRequestFiles[imageType[itemValue]][itemValue].length] : undefined}
                                            clickOutsideToClose={true}
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                            onMovePrevRequest={() =>
                                            this.setState({
                                                photoIndex: (photoIndex + tripRequestFiles[imageType[itemValue]][itemValue].length - 1) % tripRequestFiles[imageType[itemValue]][itemValue].length
                                            })
                                            }
                                            onMoveNextRequest={() =>
                                            this.setState({
                                                photoIndex: (photoIndex + 1) % tripRequestFiles[imageType[itemValue]][itemValue].length
                                            })
                                            }
                                            animationOnKeyInput={true}
                                        />
                                    )}
                                    {/* Image section end */}
                                </Col>
                            </Row>
                            </div>
                        </Col>
                        <div></div>
                    </Modal.Body>

                </Modal>
                <Alert showAlertModal={this.state.showAlertModal} handleCloseAlert={() => this.toggleAlertModal('showAlertModal')} showCloseIcon={true} alertType='alert-lg'>
                    <h2>Delivery cancellation failed!</h2>
                    <p>This delivery cannot be canceled at the moment. You can text our support team at <strong>{this.props.supportPhoneNumber}</strong> to cancel this delivery.</p>
                    <Button className="btn" onClick={() => this.toggleAlertModal('showAlertModal')} >
                        OK
                    </Button>
                </Alert>
                <Alert showAlertModal={this.state.showConfirmCancelModal} handleCloseAlert={() => this.toggleAlertModal('showConfirmCancelModal')} showCloseIcon={true} alertType='alert-lg'>
                    <h2>Cancel Delivery</h2>
                    <p>Are you sure you want to cancel this delivery?</p>
                    <ButtonGroup>
                        <button className="btn" onClick={() => this.toggleAlertModal('showConfirmCancelModal')}>Back</button>
                        <button className="btn btn-clear btn-clear-red" onClick={this.cancelPickup}>Cancel Delivery</button>
                    </ButtonGroup>
                </Alert>
                <Alert showAlertModal={this.state.showSuccessModal} handleCloseAlert={() => this.toggleAlertModal('showSuccessModal')} showCloseIcon={true} alertType='alert-lg'>
                    <h2>Cancel Delivery</h2>
                    <p>Your delivery has been canceled.</p>
                    <ButtonGroup>
                        <button className="btn" onClick={() => this.toggleAlertModal('showSuccessModal')}>Ok</button>
                    </ButtonGroup>
                </Alert>
            </React.Fragment>
        );
    }
}

export default connect(
    (
        { 
            dashboard: {
                tripDetails
            },
            common: {
                showLoader, showPrice, appSetting
            },
            user: {
                accessToken,
                isTrackDeliveriesEnabled,
                isEapiUser
            },
        }
    ) => 
    (
        {
            isTrackDeliveriesEnabled,
            isEapiUser,
            tripDetails,
            showLoader,
            accessToken,
            showPrice,
            appSetting
        }
    ),
    dispatch => (
        {
            setShowLoader: showLoader => dispatch(setShowLoader(showLoader)),
            setTripDetails: tripDetails => dispatch(setTripDetails(tripDetails)),
        }
    )
)(TripDetails);
