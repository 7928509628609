import React from 'react';
import Footer from '../shared/layouts/common/Footer';
import { Container, Row, Col, Media, ButtonGroup, Button } from 'react-bootstrap';
import Rating from 'react-rating';
import star from '../assets/images/icons/star.png';
import starFilled from '../assets/images/icons/star-filled.png';
import phone from '../assets/images/icons/call.png';
import Done from '../assets/images/icons/trip-progress/status/Done.png';
import Driving from '../assets/images/icons/trip-progress/status/Driving.png';
import En from '../assets/images/icons/trip-progress/status/En.png';
import Loading from '../assets/images/icons/trip-progress/status/Loading.png';
import Unloading from '../assets/images/icons/trip-progress/status/Unloading.png';
import Driver from '../assets/images/icons/trip-progress/status/Driver.png';
import Completed from '../assets/images/icons/completed.png';
import Cancelled from '../assets/images/icons/cancelled.png';
import { handleDeliveryStatusReq } from '../shared/services/AppendHeadersService';
import appConfig from '../shared/appConfig/appConfig';
import moment from 'moment-timezone';
import loader from '../assets/images/bungii-preloader.gif'
import * as Enums from '../shared/appConfig/enums';
import { handlePostReq } from '../shared/services/AppendHeadersService';
import languageConstants from '../shared/appConfig/languageConstants';
import Alert from '../shared/layouts/common/Alert';
import toasterService from '../shared/services/ToasterService';
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import DeliveryStatusMap from '../shared/modules/DeliveryStatusMap';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setShowLoader } from '../shared/redux/actions/common.actions';
import { ToastContainer } from 'react-toastify';

class DeliveryStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pickupAdd: '',
            dropofAdd: '',
            pickupAddLat: '',
            pickupAddLng: '',
            dropofAddLat: '',
            dropofAddLng: '',
            estDeliveryTimeRange: '',
            distancePickupToDropOff: '',
            timeStamp: '',
            pickupTime: '',
            driverDetails: '',
            noOfDrivers: '',
            showLoader: true,
            statusLabel: '',
            isReturnTrip : false,
            message: '',
            partnerLogo: '',
            deliveryComplete: true,
            height: '',
            driver1Rating: 5,
            driver1Ref: '',
            driver2Rating: 5,
            driver2Ref: '',
            errMge: '',
            successMge: '',
            ratingsSubmitted: false,
            checkHeight: false,
            showConfirmCallModal: false,
            callingDriverRef: null,
            pickupRef: null,
            noDisplayMessage: null,
        }
        this.myDiv = React.createRef();
    }

    /**
     * setHeight()
     * This function is used to get the height of the element
     */
    setHeight = () =>{
        try{
            let currentHeight = this.myDiv.current.clientHeight
            this.setState({
                heightRenderer: true,
                height: currentHeight
            })
        } catch(err){
            console.log(err)
        }
    }

    /**
     * componentDidMount()
     * This function will be called only after the first render.
     */
    componentDidMount = async () => {
        let that = this;
        if(this.state.checkHeight){
            
            setTimeout(function(){ 
                that.setHeight();
            }, 1500)
        }
        
    }

    /**
     * componentWillMount()
     * This function Executes just before rendering takes place.
     */
    componentWillMount() {
        this.loadData()

        setInterval(() => {
            (this.state.message !== '') ? clearInterval() :
            this.loadData()
        }, 30000)
    }

    /**
     * loadData()
     * This function is used to load all the data to build the page.
     */
    loadData = async () => {
        try{
            this.setState({
                showLoader: true
            })
            const fullUrl = window.location.pathname;
            const trackingId = fullUrl.split('/')[2]
            let deliveryStatus = await handleDeliveryStatusReq(`${appConfig.urls.deliveryStatus}${trackingId}`, '');
            const PickupDetails = deliveryStatus.data.PickupDetails
            
            if(
                PickupDetails?.TripStatus === Enums.PickupStatus.DrivingToReturn 
                || PickupDetails?.TripStatus === Enums.PickupStatus.UnloadingAtReturn
            ){
                this.setState({isReturnTrip : true})
            }
            if (deliveryStatus && deliveryStatus.data.Error === null && deliveryStatus.data.PickupDetails !== null) {
                let pickupAdd = '',
                    dropofAdd = '',
                    pickupTimeFormat = '',
                    statusLabel = '';

                const DriverDetails = deliveryStatus.data.DriverDetails
                const PickupAddress = PickupDetails.PickupAddress
                const DropOffAddress = PickupDetails.DropOffAddress
                /*Date formatting*/    
                pickupTimeFormat = moment(PickupDetails.ScheduleTimestamp).format(`MMMM DD, YYYY [at] h:mm A`);
                
                /*Building Pickup addr*/
                if(PickupAddress !== null){
                    let address2 = PickupAddress.Address2 ? (PickupAddress.Address2 + ', ') : '';
                    let state = PickupAddress.State ? (PickupAddress.State + ', ') : '';
                    let country = PickupAddress.Country ? (PickupAddress.Country + ', ') : '';
                    pickupAdd = PickupAddress.Address1 + ', '
                        + address2 + PickupAddress.City + ', ' + state + country
                        + PickupAddress.ZipPostalCode;
                }

                /*Building Dropoff addr*/
                if(DropOffAddress !== null){
                    let address2 = DropOffAddress.Address2 ? (DropOffAddress.Address2 + ', ') : '';
                    let state = DropOffAddress.State ? (DropOffAddress.State + ', ') : '';
                    let country = DropOffAddress.Country ? (DropOffAddress.Country + ', ') : '';
                    dropofAdd = DropOffAddress.Address1 + ', '
                        + address2 + DropOffAddress.City + ', ' + state + country
                        + DropOffAddress.ZipPostalCode;
                }
                
                /*Building the status labels based on the status codes*/
                switch (PickupDetails.TripStatus) {
                    case Enums.PickupStatus.TripStarted: statusLabel = "En Route To Pickup"; break;
                    case Enums.PickupStatus.DriverArrived: statusLabel = "Driver Arrived At Pickup"; break;
                    case Enums.PickupStatus.LoadingItems: statusLabel = "Loading Items"; break;
                    case Enums.PickupStatus.DrivingToDropoff: statusLabel = "Driving To Drop Off"; break;
                    case Enums.PickupStatus.UnloadingItems: statusLabel = "Unloading Items"; break;
                    case Enums.PickupStatus.DrivingToReturn: statusLabel = "Driving To Return"; break;
                    case Enums.PickupStatus.UnloadingAtReturn: statusLabel = "Unloading Items At Return"; break;
                    case Enums.PickupStatus.TripCompleted: statusLabel = "Driver 1 Of 2 Complete"; break;
                    case Enums.PickupStatus.PickupCompleted: statusLabel = "Done"; break;
                    default:
                        statusLabel = "default"
                        break;
                }
                
                this.setState({
                    pickupAdd: pickupAdd,
                    dropofAdd: dropofAdd,
                    pickupAddLat: PickupDetails.PickupAddress.Location.Latitude,
                    pickupAddLng: PickupDetails.PickupAddress.Location.Longitude,
                    dropofAddLat: PickupDetails.DropOffAddress.Location.Latitude,
                    dropofAddLng: PickupDetails.DropOffAddress.Location.Longitude,
                    estDeliveryTimeRange: PickupDetails.EstDeliveryEndTimeRange,
                    distancePickupToDropOff: PickupDetails.DistancePickupToDropOff,
                    pickupTime: pickupTimeFormat,
                    driverDetails: DriverDetails,
                    noOfDrivers: PickupDetails.NoOfDrivers,
                    showLoader: false,
                    statusLabel: statusLabel,
                    partnerLogo: deliveryStatus.data.PartnerLogoUrl,
                    checkHeight: true,
                    pickupRef: deliveryStatus.data.PickupDetails.PickupRequestID
                })
               if(!this.state.height) await this.setHeight();
            } else if(deliveryStatus.data.DisplayMessage && deliveryStatus.data.DisplayMessage !== null){
                let tripComplete = '';
                if (deliveryStatus.data.DisplayMessage.indexOf('completed') > -1) {
                    tripComplete = true
                } else {
                    tripComplete = false
                }
                this.setState({
                    showLoader: false,
                    message: deliveryStatus.data.DisplayMessage,
                    deliveryComplete: tripComplete,
                    partnerLogo: deliveryStatus.data.PartnerLogoUrl,
                    driverDetails: deliveryStatus.data.DriverDetails,
                    driver1Ref: deliveryStatus.data.DriverDetails.length > 0 && deliveryStatus.data.DriverDetails[0]['DriverRef'],
                    driver2Ref: deliveryStatus.data.DriverDetails.length > 1 && deliveryStatus.data.DriverDetails[1]['DriverRef'],
                    driverRatingToBeGiven: deliveryStatus.data.DriverRatingToBeGiven,
                })
            } else if(!deliveryStatus.data.DisplayMessage && !deliveryStatus.data.PickupDetails){
                this.setState({
                    showLoader: false,
                    message: deliveryStatus.data.DisplayMessage,
                    deliveryComplete: '',
                    partnerLogo: deliveryStatus.data.PartnerLogoUrl,
                    driverDetails: deliveryStatus.data.DriverDetails,
                    driverRatingToBeGiven: deliveryStatus.data.DriverRatingToBeGiven,
                    noDisplayMessage : true,
                })
            } else {
                this.setState({
                    showLoader: false,
                    message: deliveryStatus.data.Error.Message,
                    deliveryComplete: '',
                    partnerLogo: deliveryStatus.data.PartnerLogoUrl,
                    driverDetails: deliveryStatus.data.DriverDetails,
                    driverRatingToBeGiven: deliveryStatus.data.DriverRatingToBeGiven,
                })
            }
        } catch(err){
            console.log(err)
        }
    }

    /**
     * rateDriver1()
     * This function is used to fetch the rating value of Driver1.
     */
    rateDriver1 = (e, value) => {
        this.setState({
            driver1Rating: value,
            driver1Ref: e.DriverRef,
            errMge: ''
        })
    }

    /**
     * rateDriver2()
     * This function is used to fetch the rating value of Driver2.
     */
    rateDriver2 = (e, value) => {
        this.setState({
            driver2Rating: value,
            driver2Ref: e.DriverRef,
            errMge: ''
        })
    }

    /**
     * submitRating()
     * This function is used to submit the ratings.
     */
    submitRating = async() => {
        try{
            const { setShowLoader, accessToken } = this.props;
            const fullUrl = window.location.pathname;
            const trackingId = fullUrl.split('/')[2]
            if(this.state.driver1Rating || this.state.driver2Rating) {
                let driverArray = [];
                let driver1Obj = {};
                let driver2Obj = {};
                if(this.state.driver1Ref){
                    driver1Obj['DriverRef'] = this.state.driver1Ref
                    driver1Obj['Rating'] = this.state.driver1Rating
                    driverArray.push(driver1Obj)
                }
                if(this.state.driver2Ref){
                    driver2Obj['DriverRef'] = this.state.driver2Ref
                    driver2Obj['Rating'] = this.state.driver2Rating
                    driverArray.push(driver2Obj)
                }
                let body = {
                    "Driver": driverArray,
                    "PickupToken": trackingId
                }
                setShowLoader(true);
                let rateDriverInfo = await handlePostReq(appConfig.urls.ratedriver, body, accessToken);
                if(rateDriverInfo && rateDriverInfo.data.Error === null){
                    setShowLoader(false);
                    this.setState({
                        ratingsSubmitted: true,
                        successMge: languageConstants.deliveryStatus.ratingSuccess
                    })
                } else {
                    setShowLoader(false);
                    this.setState({
                        ratingsSubmitted: false,
                        successMge: rateDriverInfo.data.Error.Message
                    })
                }
            } else {
                this.setState({
                    errMge: languageConstants.deliveryStatus.ratingSelect
                })
            }
        } catch(err){
            console.log(err)
        }
    }

        /**
     * toggleCallingConfirmModal()
     * This function is used to hide and show CallingConfirmModal.
     */
    toggleCallingConfirmModal = (value, driverRef = null) => {
        this.setState({
            showConfirmCallModal: value,
            callingDriverRef: driverRef
        })
    }
    
    /**
     * callDriver()
     * This function is used to initiate call between driver and customer
     */
    callDriver = async () => {
        try{
            this.setState({
                showConfirmCallModal: false
            })
            const { setShowLoader } = this.props;
            setShowLoader(true);
            const body = {
                DriverRef: this.state.callingDriverRef,
                PickupRef: this.state.pickupRef
            }
            const resp = await handlePostReq(appConfig.urls.callDriver, body);
            if(resp?.data?.error){
                setShowLoader(false);
                throw new Error(appConfig.language.callRequest.error);
            }
            console.log('response', resp)
            this.setState({
                callingDriverRef: null
            })
            setShowLoader(false);
            toasterService.showCustomToasty(appConfig.language.callRequest.success, "success");
        }catch(e){
            setShowLoader(false);
            toasterService.showCustomToasty(e.message, "error");
        }
    }

    render() {
        return (
            <React.Fragment>
                <main className="main-wrapper show-delivery-status" id="main-wrapper">
                    {/* Header */}
                    <header className='delivery-status-header'>
                        <Container>
                            <Row>
                                {this.state.partnerLogo && (
                                    <Link
                                        onClick={this.handleImgClick}
                                        to="/request-bungii"
                                        onContextMenu={(e) => e.preventDefault()}
                                    >
                                        <Col
                                            className="pr-0 partner-logo d-flex align-items-center"
                                            md="auto"
                                        >
                                            <img src={this.state.partnerLogo} alt="Partner Logo" />
                                        </Col>
                                    </Link>
                                )}
                                <Col className="pl-0 powered-by align-self-center d-block">
                                    <img src={logo} width="93" className="logo" alt="Bungii Logo" />
                                </Col>
                            </Row>
                        </Container>
                    </header>

                    <Container className="main-container detailed-information">
                        {(this.state.showLoader) ? <div className="loader"><img src={loader} alt="Loader" /></div> : '' }
                        <div className="sticky-header">
                            <label>Delivery Status</label>              
                        </div>
                        <Row className="content-section">
                            {(this.state.message) ? 
                                <React.Fragment>
                                    {this.state.deliveryComplete !== '' &&
                                        /*To appear only on Tablet and Desktop */
                                        <React.Fragment>
                                            {this.state.deliveryComplete ?
                                                <Col md="3" className={(this.state.driverRatingToBeGiven) ? (this.state.driverDetails.length === 1) ? `d-none d-md-block d-lg-block text-center desktop-1-driver` : `d-none d-md-block d-lg-block text-center desktop` : `d-none d-md-block d-lg-block text-center desktop-no-rating`}>
                                                    <img src={Completed} alt="Completed"></img>
                                                </Col>
                                                :
                                                <Col md="3" className={(this.state.driverRatingToBeGiven) ? (this.state.driverDetails.length === 1) ? `d-none d-md-block d-lg-block text-center desktop-1-driver` : `d-none d-md-block d-lg-block text-center desktop` : `d-none d-md-block d-lg-block text-center desktop-no-rating`}>
                                                    <img src={Cancelled} alt="Cancelled"></img>
                                                </Col>
                                            }
                                        </React.Fragment>
                                    }
                                    <Col md="8" className="r-p-mobile">
                                        {this.state.deliveryComplete !== '' &&
                                            /*To appear only on Mobile */
                                            <React.Fragment>
                                                {this.state.deliveryComplete ?
                                                    <Col sm={6} md={6} className="text-center trip-completed d-block d-sm-none">
                                                        <img src={Completed} alt="Completed"></img>
                                                        <span>Successfully <br />Completed!</span>
                                                    </Col>
                                                :
                                                    <React.Fragment>
                                                        <Col sm={6} md={6} className="text-center trip-completed d-block d-sm-none">
                                                            <img src={Cancelled} alt="Cancelled"></img>
                                                            <span>Delivery <br />Canceled</span>
                                                        </Col>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                        <Col md={12} className="m-t-20">
                                            {this.state.deliveryComplete !== '' &&
                                                /*To appear only on Tablet and Desktop */
                                                <React.Fragment>
                                                    {this.state.deliveryComplete ?
                                                        <strong><span className="d-none d-md-block d-lg-block">Successfully Completed!</span></strong>
                                                        :
                                                        <strong><span className="d-none d-md-block d-lg-block">Delivery Canceled</span></strong>
                                                    }
                                                </React.Fragment>
                                            }
                                            <p>{this.state.message}</p>
                                        </Col>
                                        {this.state.driverRatingToBeGiven &&
                                            <React.Fragment>
                                                <Col md={12} className="driver-rating">
                                                    <h6 className="m-t-20">Rate Your Drivers</h6>
                                                        {(this.state.driverDetails.length > 0) ? 
                                                            <Row>
                                                                {this.state.driverDetails.map((e, key) => {
                                                                    if(key === 0) {
                                                                        return <Col sm={12} key={key} className="m-b-20">
                                                                            <Media>
                                                                                <img
                                                                                    width={40}
                                                                                    height={40}
                                                                                    className="align-self-center mr-2 rounded-circle"
                                                                                    src={e.ProfilePicURL}
                                                                                    alt="driver"
                                                                                />
                                                                                <Media.Body className="content">
                                                                                    <label className="mb-0 d-block" title={(this.state.noOfDrivers === 1) ? '' : (e.FirstName + ' ' + e.LastName)}>{e.FirstName + ' ' + e.LastName}</label>
                                                                                    <Rating id={`driver_${key}`} emptySymbol={<img src={star} className="icon"  alt="starEmpty"/>} fullSymbol={<img src={starFilled} className="icon" alt="starFilled" />} onChange={(rate) => this.rateDriver1(e, rate)} initialRating={this.state.driver1Rating}/>
                                                                                </Media.Body>
                                                                            </Media>
                                                                        </Col>;
                                                                    }
                                                                    if(key === 1){
                                                                        return <Col sm={12} key={key} className="m-b-20">
                                                                            <Media>
                                                                                <img
                                                                                    width={40}
                                                                                    height={40}
                                                                                    className="align-self-center mr-2 rounded-circle"
                                                                                    src={e.ProfilePicURL}
                                                                                    alt="driver"
                                                                                />
                                                                                <Media.Body className="content">
                                                                                    <label className="mb-0 d-block" title={(this.state.noOfDrivers === 1) ? '' : (e.FirstName + ' ' + e.LastName)}>{e.FirstName + ' ' + e.LastName}</label>
                                                                                    <Rating id={`driver_${key}`} emptySymbol={<img src={star} className="icon"  alt="starEmpty"/>} fullSymbol={<img src={starFilled} className="icon"  alt="starFilled"/>} onChange={(rate) => this.rateDriver2(e, rate)} initialRating={this.state.driver2Rating}/>
                                                                                </Media.Body>
                                                                            </Media>
                                                                        </Col>;
                                                                    }
                                                                    return true
                                                                })}
                                                            </Row>
                                                        :
                                                            ''
                                                        }
                                                    {this.state.errMge?<span className="text-danger">{this.state.errMge}</span>:''}
                                                    {this.state.successMge?<span className="text-success">{this.state.successMge}</span>:''}
                                                </Col>
                                                <Col md={12} className="text-center rate-btn m-t-20">
                                                    <input type="button" value="Submit" className={this.state.ratingsSubmitted ? 'd-none' : "btn btn-primary"} onClick={this.submitRating} />
                                                </Col>
                                            </React.Fragment>
                                        }
                                    </Col>
                                </React.Fragment>
                            :  this.state.noDisplayMessage ?
                                    <React.Fragment>
                                        <Col md="3" className={(this.state.driverRatingToBeGiven) ? (this.state.driverDetails.length === 1) ? `d-none d-md-block d-lg-block text-center desktop-1-driver` : `d-none d-md-block d-lg-block text-center desktop` : `d-none d-md-block d-lg-block text-center desktop-no-rating`}>
                                            <img src={Cancelled} alt="Cancelled"></img>
                                        </Col>
                                        <Col md="8" className="r-p-mobile">
                                            <Col sm={6} md={6} className="text-center trip-completed d-block d-sm-none">
                                                <img src={Cancelled} alt="Cancelled"></img>
                                                <span>Data is <br /> currently unavailable</span>
                                            </Col>
                                            <Col md={12} className="m-t-20">
                                                <strong><span className="d-none d-md-block d-lg-block">Data is currently unavailable</span></strong>
                                                <p>You will get the delivery details once driver starts the delivery.</p>
                                            </Col>
                                        </Col>
                                    </React.Fragment>
                                :
                                <React.Fragment>
                                    {this.state.pickupTime && 
                                        <Col md={6} ref={this.myDiv}>
                                            <section className="scheduled-details">
                                                {/*Delivery Status */}
                                                <div className="delivery-status">
                                                    <div className="bbottom">
                                                        <img className={`m-b-5`} src={(this.state.statusLabel.split(' ')[0]) === 'En' ? En : (this.state.statusLabel.split(' ')[0]) === 'Driver' ? Driver : (this.state.statusLabel.split(' ')[0]) === 'Loading' ? Loading : (this.state.statusLabel.split(' ')[0]) === 'Driving' ? Driving : (this.state.statusLabel.split(' ')[0]) === 'Unloading' ? Unloading : (this.state.statusLabel.split(' ')[0]) === 'Done' ? Done : ''} alt="Status" />
                                                        {this.state.statusLabel && <span className="m-l-10">{this.state.statusLabel}</span>}
                                                    </div>
                                                </div>
                                                {/*Delivery time Status */}
                                                <div className="delivery-info d-none d-md-block">
                                                    <Row>
                                                        <Col md="5" className="pickup-time">Pick Up Time</Col>
                                                        <Col md="7" className="text-right">{this.state.pickupTime}</Col>
                                                        <Col md="5" className="pickup-distance">DISTANCE</Col>
                                                        <Col md="7" className="text-right">{`${this.state.distancePickupToDropOff} miles`}</Col>
                                                        {(this.state.statusLabel!=='Driving To Return' && this.state.statusLabel!=='Unloading Items At Return') && <>
                                                            <Col md="5" className="pickup-delivery">Est. Delivery Time</Col>
                                                            <Col md="7" className="text-right">{this.state.estDeliveryTimeRange}</Col>
                                                        </>}
                                                    </Row>
                                                </div>
                                                {/*Pickup/Dropoff address */}
                                                <div className="success-trip-details m-t-20 d-none d-md-block">
                                                    <div className="pickup-icon">
                                                        <div className="content">
                                                            <label>Pickup Address:</label>
                                                            <p className="description">{this.state.pickupAdd}</p>
                                                        </div>
                                                    </div>
                                                    <div className="delivery-icon">
                                                        <div className="content">
                                                            <label>Delivery Address:</label>
                                                            <p className="description">{this.state.dropofAdd}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </section>
                                        </Col>
                                    }
                                    {/*Map */}
                                    {this.state.noOfDrivers && 
                                        <Col md={6} className="map">
                                            {(this.state.noOfDrivers === 2) ?
                                                <DeliveryStatusMap google={this.props.google}
                                                    center={{ lat: this.state.pickupAddLat, lng: this.state.pickupAddLng }}
                                                    zoom={10}
                                                    pickupPoint={{ lat: this.state.pickupAddLat, lng: this.state.pickupAddLng }}
                                                    dropoffPoint={{lat: this.state.dropofAddLat, lng: this.state.dropofAddLng}}
                                                    address={this.state.pickupAdd}
                                                    UIType="public-2-driver"
                                                    dsdriver1={{lat: this.state.driverDetails[0]?.Location.Latitude, lng: this.state.driverDetails[0]?.Location.Longitude}}
                                                    dsdriver2={{lat: this.state.driverDetails[1]?.Location.Latitude, lng: this.state.driverDetails[1]?.Location.Longitude}}
                                                    height={this.state.height+140}
                                                    driverDetails={this.state.driverDetails}
                                                    isReturnTrip={this.state.isReturnTrip}
                                                />
                                            : <DeliveryStatusMap google={this.props.google}
                                                center={{ lat: this.state.pickupAddLat, lng: this.state.pickupAddLng }}
                                                zoom={10}
                                                pickupPoint={{ lat: this.state.pickupAddLat, lng: this.state.pickupAddLng }}
                                                dropoffPoint={{lat: this.state.dropofAddLat, lng: this.state.dropofAddLng}}
                                                address={this.state.pickupAdd}
                                                UIType="public-1-driver"
                                                dsdriver1={{lat: (this.state.driverDetails) ? this.state.driverDetails[0]?.Location.Latitude : '', lng: (this.state.driverDetails) ? this.state.driverDetails[0]?.Location.Longitude : ''}}
                                                height={this.state.height+110}
                                                driverDetails={this.state.driverDetails}
                                                isReturnTrip={this.state.isReturnTrip}
                                            />}
                                        </Col>
                                    }
                                    {/*Drivers */}
                                    {this.state.driverDetails &&
                                        <Col md={6}>
                                            <div className={`scheduled-drivers ${(this.state.noOfDrivers === 1) ? 'single-driver' : 'duo-driver'}`}>
                                                <h6 className="label">DRIVER(S)</h6>
                                                {(this.state.driverDetails.length > 0) ? 
                                                    <Row>
                                                        {this.state.driverDetails.map((e, key) => {
                                                            return <Col sm={12} key={key} className={(key === 1) ? 'driver-2' : ''}>
                                                                <Media>
                                                                    <img
                                                                        width={40}
                                                                        height={40}
                                                                        className="align-self-center mr-2 rounded-circle"
                                                                        src={e.ProfilePicURL}
                                                                        alt="driver"
                                                                    />
                                                                    <Media.Body className="content">
                                                                        <p
                                                                            className={'description'}
                                                                            title={(this.state.noOfDrivers === 1) ? '' : (e.FirstName + ' ' + e.LastName)}>{e.FirstName + ' ' + e.LastName}</p>
                                                                        {
                                                                            <Rating initialRating={e.Rating ? e.Rating : 5} readonly
                                                                                emptySymbol={<img src={star} className="icon" alt="Empty" />}
                                                                                fullSymbol={<img src={starFilled} className="icon" alt="Full" />} />

                                                                        }

                                                                    </Media.Body>
                                                                    <span onClick={() => this.toggleCallingConfirmModal(true, e.DriverRef)} className="align-self-center cursor-pointer"><img
                                                                        width={26}
                                                                        height={26}
                                                                        src={phone}
                                                                        className="call"
                                                                        alt="phone"
                                                                    /></span>
                                                                </Media>
                                                            </Col>;
                                                        })}
                                                    </Row>
                                                :
                                                    ''
                                                }
                                            </div>
                                        </Col>
                                    }
                                </React.Fragment>
                            }
                        </Row>
                    </Container>
                    <ToastContainer position="bottom-right" closeOnClick={false} />
                    <Alert showAlertModal={this.state.showConfirmCallModal} handleCloseAlert={() => this.toggleCallingConfirmModal(false)} showCloseIcon={true} >
                        <p>Are you sure you want to initiate the call ?</p>
                        <ButtonGroup>
                            <Button className="btn" onClick={this.callDriver} >
                                Confirm
                            </Button>
                            <Button className="ml-2 btn-clear btn-clear-red noborder" onClick={() => this.toggleCallingConfirmModal(false)} >
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </Alert>
                    <Footer />
                </main>
            </React.Fragment>
        )
    }
}

export default connect(
    (
        { 
            common: {
                showLoader, accessToken
            }
        }
    ) => 
    (
        {
            showLoader,
            accessToken
        }
    ),
    dispatch => (
        {
            setShowLoader: showLoader => dispatch(setShowLoader(showLoader))

        }
    )
)(DeliveryStatus);