export const deliveryDetailsActionTypes = {
    SET_PICKUP_DETAILS: 'SET_PICKUP_DETAILS',
    SET_LOADING_UNLOADING_TIME: 'SET_LOADING_UNLOADING_TIME',
    SET_NUMBER_OF_DRIVERS: 'SET_NUMBER_OF_DRIVERS',
    SET_SCHEDULED_TIMESTAMP: 'SET_SCHEDULED_TIMESTAMP',
    SET_PAYMENT_METHOD_SELECTED: 'SET_PAYMENT_METHOD_SELECTED',
    SET_LAST_FOUR: 'SET_LAST_FOUR',
    SET_DELIVERY_INITIAL_STATE: 'SET_DELIVERY_INITIAL_STATE',
    SET_PICKUP_CONTACT_PHONE: 'SET_PICKUP_CONTACT_PHONE',
    SET_PICKUP_CONTACT_NAME: 'SET_PICKUP_CONTACT_NAME',
    SET_ORDER_NUMBER: 'SET_ORDER_NUMBER',
    SET_PICKUP_TOKEN: 'SET_PICKUP_TOKEN',
    SET_EXTERNALID_LABEL: 'SET_EXTERNALID_LABEL',
    SET_BACK_TO_QUOTE: 'SET_BACK_TO_QUOTE',
    SET_INITIAL_STATES: 'SET_INITIAL_STATES',
}

export const setPickupDetails = pickupDetails => ({
    type: deliveryDetailsActionTypes.SET_PICKUP_DETAILS,
    payload: pickupDetails
})

export const setLoadingUnloadingTime = loadingUnloadingTime => ({
    type: deliveryDetailsActionTypes.SET_LOADING_UNLOADING_TIME,
    payload: loadingUnloadingTime
})

export const setNoOfDrivers = noOfDrivers => ({
    type: deliveryDetailsActionTypes.SET_NUMBER_OF_DRIVERS,
    payload: noOfDrivers
})

export const setScheduledTimestamp = scheduledTimestamp => ({
    type: deliveryDetailsActionTypes.SET_SCHEDULED_TIMESTAMP,
    payload: scheduledTimestamp
})

export const setPaymentMethodSelected = paymentMethodSelected => ({
    type: deliveryDetailsActionTypes.SET_PAYMENT_METHOD_SELECTED,
    payload: paymentMethodSelected
})

export const setLastFour = lastFour => ({
    type: deliveryDetailsActionTypes.SET_LAST_FOUR,
    payload: lastFour
})

export const setDeliveryDetailsInitialState = newValues => ({
    type: deliveryDetailsActionTypes.SET_DELIVERY_INITIAL_STATE,
    payload: newValues
});

export const setOrderNumber = orderNumber => ({
    type: deliveryDetailsActionTypes.SET_ORDER_NUMBER,
    payload: orderNumber
});

export const setExternalIdLabel = externalIdLabel => ({
    type: deliveryDetailsActionTypes.SET_EXTERNALID_LABEL,
    payload: externalIdLabel
});

export const setPickupContactPhone = pickupContactPhone => ({
    type: deliveryDetailsActionTypes.SET_PICKUP_CONTACT_PHONE,
    payload: pickupContactPhone
});

export const setPickupContactName = pickupContactName => ({
    type: deliveryDetailsActionTypes.SET_PICKUP_CONTACT_NAME,
    payload: pickupContactName
});

export const setPickupToken = pickupToken => ({
    type: deliveryDetailsActionTypes.SET_PICKUP_TOKEN,
    payload: pickupToken
})

export const setBackToQuote = backToQuote => ({
    type: deliveryDetailsActionTypes.SET_BACK_TO_QUOTE,
    payload: backToQuote
});
export const setInitialStates = initialStates => ({
    type: deliveryDetailsActionTypes.SET_INITIAL_STATES,
    payload: initialStates
});

