import React  from 'react';
import { withRouter } from 'react-router';
import AuthService from '../services/AuthService';
import globalConstants from '../../shared/appConfig/globalConstants';
import storageService from '../../shared/services/StorageService';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setIsAuthenticated, setPartnerLocationReference, setQuickAccessUser, setQuoteOnlyUser } from '../redux/actions/user.actions'
import { setShowLoader } from '../redux/actions/common.actions';
/* Redux Import End */

function guardCanActivate(Component, store) {
    class canActivateComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                subDomainName: '',
                isValidDomain: false,
                isLogin: false,
                isLoading: true
            }
        }

        /**
         * componentWillMount()
         * This function Executes just before rendering takes place.
         */
        componentWillMount = async () => {
            //To be used on Deployed Serves
            const fullUrl = window.location.host
    
            //Common code
            const subdomain = (globalConstants.FullUrl) ? globalConstants.FullUrl.split('.')[0] : fullUrl.split('.')[0]
            this.setState({ subDomainName: subdomain });
        }

        /**
         * componentDidMount()
         * This function Executes only after the first render..
         */
        componentDidMount = async () => {
            /* Set Redux Props */
            const { setIsAuthenticated, setPartnerLocationReference, setQuickAccessUser, setShowLoader, setQuoteOnlyUser } = this.props
            let params = { SubDomainName: this.state.subDomainName }
            try {
                let accessToken = await storageService.getSessionStorageItem("accessToken");
                let response = await AuthService.verifySubDomain(params);
                
                if (response && response.data.PartnerLocation && (accessToken === null || accessToken != null)) {
                    setPartnerLocationReference(response.data.PartnerLocation.PartnerLocationRef)
                    setIsAuthenticated(response.data.PartnerLocation ? true : false)
                    setQuickAccessUser(false)
                    setQuoteOnlyUser(false)
                    /**
                     * Set redux states
                     */
                    this.setState({
                        isValidDomain: true,
                        isLoading: false
                    })
                    setShowLoader(false)
                } else {
                    this.props.history.push("/page-not-found"); 
                }
            }
            catch (err) {
                console.log(err);
            }
        }

        render() {
            const { isValidDomain} = this.state;
            return isValidDomain ? <Component { ...this.props } /> : null;
        }
    }
    
    return connect(
        (
            { 
                user: {
                    isAuthenticated, partnerLocationReference, accessToken
                },
                common: {
                    showLoader
                }, 
            }
        ) => (
            {
                isAuthenticated,
                partnerLocationReference,
                accessToken,
                showLoader
            }
        ), 
        dispatch => (
            {
            setIsAuthenticated: isAuthenticated => dispatch(setIsAuthenticated(isAuthenticated)),
            setPartnerLocationReference: partnerLocationReference => dispatch(setPartnerLocationReference(partnerLocationReference)),
            setQuickAccessUser: quickAccessUser => dispatch(setQuickAccessUser(quickAccessUser)),
            setShowLoader: showLoader => dispatch(setShowLoader(showLoader)),
            setQuoteOnlyUser: quoteOnlyUser => dispatch(setQuoteOnlyUser(quoteOnlyUser)),
            }
        )
    )(withRouter(canActivateComponent));
}

export default guardCanActivate;