import globalConstants from './globalConstants';
import urlConstants from './urlConstants';

import languageConstants from './languageConstants';

const appConfig = {
    urls: urlConstants,
    language: languageConstants,
    global: globalConstants
}

export default appConfig;