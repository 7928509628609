export const downloadCSV = async (filename, data) => {
    try{
        const replacer = (key, value) => (value === null || value === "") ? '-' : value // specify how you want to handle null values here
        const header = Object.keys(data[0])
        let csv = data.map(row => header.map(fieldName => 
        JSON.stringify(row[fieldName], replacer)).join(','))
        csv.unshift(header.join(','))
        csv = csv.join('\r\n')
      
        // Create link and download
        var link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }catch(e){
        throw new Error("Error occurred while converting json to csv");
    } 
}