
const googleSDKUtils = {
    getBoundsFromPolylinePoints: (mapObject, points) => {
        const latLngBounds = new mapObject.LatLngBounds();
        for (const point of points) {
            const latLng = new mapObject.LatLng(point.lat, point.lng);
            latLngBounds.extend(latLng);
        }
        return latLngBounds;
    },
}

export default googleSDKUtils;
