const globalConstants = {
    FullUrl: process.env.REACT_APP_FULL_URL,
    GoogleMap_Key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    AppKey: process.env.REACT_APP_APP_KEY,
    AppSecret: process.env.REACT_APP_APP_SECRET,
    SiteKey: process.env.REACT_APP_CAPTCHA_KEY,
    AnalyticsKey: process.env.REACT_APP_ANALYTICS_KEY,
    GTMKey: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY,

    FieldTypes: {
        String: 'string',
        Phone: 'phone',
        Integer: 'int'
    },
    MaxAllowedDigitsInPhoneNumber: 10,
    maxPhoneNumbers: 6,
    PhoneNumberFormat: '(999) 999-9999',
    MaxLength: {
        ItemsToDeliver: 100,
        SpecialInstructions: 300
    },
    MaxItemAllowed: 5,
    SkuItemMaxWeight: 250,
    RoundToNearestMinuteValue: 5,
    LowerTimeMins: 60,
    UpperTimeMins: 60,
    HourFormat: 'hh:mm A',
    TeletBuffer: 1.5,
    DateTimeFormat: 'YYYY/MM/DD HH:mm:ss',
    DateFormat: 'YYYY-MM-DD',
    DateFormat2: 'YYYY/MM/DD',
    HourFormat2: 'HH:mm:ss',
    GAEventNames: {
        Pagination: 'Pagination',
        DeliveryFilterApply: 'Delivery Filter Apply',
        DeliveryFilterReset: 'Delivery Filter Reset',
        SearchDeliveries:"Search Deliveries",
        ClearSearch:"Clear Search",
        ViewDeliveryDetails:"Delivery Details View",
        ScheduleBungii:"Schedule Bungii",
        BackToQuote:"Back To Quote/Edit",
        NewBungii:"New Bungii",
        StartOver:"Start Over",
        EditPickupAddress:"Edit Pickup Address",
        GetEstimate:"Get Estimate",
        WhatsNeededInfo:"What's Needed Info",
        NewBungii:"New Bungii",
        CancelDelivery:"Cancel Delivery",
        ViewDashboard:"View Dashboard",
        Logout:"Logout",
        NewDelivery:"New Delivery",
        HeadsUpCancel:"Heads Up Cancel",
        HeadsUpContinue:"Heads Up Continue",
        PartnerLogo:"Partner Logo",
        GenerateReports:"Generate Reports",
        EmailSupport:"Email Support",
        FillOutThisFormLink:"Fill Out This Form Link",
    },
}


export default globalConstants;
