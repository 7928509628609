import React, { Component } from "react";
import { Form, Row, Col } from "react-bootstrap";
import TooltipOverlay from "../../pages/TooltipOverlay";
import oneDriverOneTruck from "../../assets/images/solo-trip.png";
import twoDriversTwoTrucks from "../../assets/images/duo-trip.png";
import oneDriver from "../../assets/images/1person.png";
import twoDriver from "../../assets/images/2people.png";
import oneDeliveryPro from "../../assets/images/1deliverypro.svg";
import twoDeliveryPros from "../../assets/images/2deliverypros.svg";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { connect } from "react-redux";
import { setIsMuscleHelpRequired } from '../redux/actions/getEstimate.actions';
import languageConstants from '../appConfig/languageConstants';
import { sendEvent } from "../utilities/sendEvent";
import globalConstants from "../appConfig/globalConstants";

class TripType extends Component {
	/**
	 * getIconName()
	 * This function is used to get the solo/duo icon names
	 */
	getIconName = (icon) => {
		try {
			let iconName = "";
			switch (icon) {
				case "oneDriverOneTruck":
					iconName = oneDriverOneTruck;
					break;
				case "oneDriver":
					iconName = oneDriver;
					break;
				case "oneDeliveryPro":
					iconName = oneDeliveryPro;
					break;
				case "twoDriversTwoTrucks":
					iconName = twoDriversTwoTrucks;
					break;
				case "twoDriver":
					iconName = twoDriver;
					break;
				case "twoDeliveryPros":
					iconName = twoDeliveryPros;
					break;
				default:
					iconName = "";
					break;
			}
			return iconName;
		} catch (err) {
			console.log(err);
		}
	};

	checkIfSoloOrDuo = (soloVal, duoVal) => {
		const { whatsNeededData } = this.props;
		if (whatsNeededData.NoOfDriver === 1) return soloVal;
		return duoVal;
	};
	
	palletSelection = () => {
		const {appSetting} = this.props;
		let { MaxNumberOfPallets } = appSetting?.PartnerLocationSettings;
		let pallets = [];
		for (let i = 1; i <= MaxNumberOfPallets; i++) {
			pallets.push(<MenuItem key={i} value={i} className="text">{i} Pallet{i>1 && "s"}</MenuItem>)
		}

		return pallets;
	};

	render() {
		let color = this.props.color ? "color-red" : "color-dark-grey";
		let pointer = this.props.pointer ? "pointer-auto" : "pointer-none";
		const { whatsNeededData, appSetting, quoteOnlyUser } = this.props;
		let {
			CustomOrderURL,
			CustomOrderMessage,
			CustomOrderURLLabel,
			DisclaimerMessages,
		} = appSetting?.PartnerLocationSettings?.EstimatePageFields[0];
		let {IsMuscleHelpEnabled} = appSetting?.PartnerLocationSettings;
		//Building Disclaimer field to replace the email address with the dynamic data.
		let partnerDisclaimerField = "";
		if (appSetting && DisclaimerMessages?.length > 0) {
			partnerDisclaimerField = DisclaimerMessages.map((field, index) => {
				return <li key={field}>{field}</li>;
			});
		}
		return (
			<React.Fragment>
				<Form.Group
					className={
						this.props.showCheckbox ? (quoteOnlyUser ? "m-b-20" : "m-b-10") : ""
					}
				>
					<Form.Label>
						{whatsNeededData?.WhatsNeedLabel
							? whatsNeededData.WhatsNeedLabel
							: "WHAT’S NEEDED?"}
						{whatsNeededData.UIType !== 2 &&
							whatsNeededData.UIType !== 3 &&
							whatsNeededData.UIType !== 5 && (
								<i
									onClick={this.props.setTooltip.bind(
										this,
										"showTripTypeTooltip"
									)}
									ref={this.props.tripTypeRef}
									className="infoIcon"
								/>
							)}
						<TooltipOverlay
							target={this.props.tripTypeRef.current}
							showTooltip={this.props.showTripTypeTooltip}
							hideTooltip={this.props.hideTooltip.bind(
								this,
								"showTripTypeTooltip"
							)}
						>
							<p>
								If there will be assistance at the pickup and drop-off locations
								to help lift and carry items, select {whatsNeededData.SoloLabel}{" "}
								as a more cost effective option.
							</p>
							<p>
								If you need an extra hand for loading + unloading, or have
								multiple items (3+), select the {whatsNeededData.DuoLabel}{" "}
								option.
							</p>
						</TooltipOverlay>
					</Form.Label>
					<Row
						className={
							whatsNeededData.UIType === 3 || whatsNeededData.UIType === 2
								? `col-padding UIType UIType-${whatsNeededData.UIType}`
								: `col-padding UIType-${whatsNeededData.UIType}`
						}
					>
						{whatsNeededData.UIType === 6 && (
							<Col
								xs="12"
								sm="6"
								lg="12"
								xl="6"
								className={whatsNeededData.UIType === 2 ? "one mb-2 " : "mb-2 "}
							>
								<FormControl
									variant="outlined"
									data-size="10"
									className="select-pallets w-100"
								>
									<Select
										required
										value={this.props.tripType}
										onChange={(event) =>
											this.props.setTripType(event.target.value)
										}
									>
										{this.palletSelection()}
									</Select>
								</FormControl>
							</Col>
						)}
						{(whatsNeededData.UIType !== 5 && whatsNeededData.UIType !== 6) && (
							<Col
								xs="12"
								sm="6"
								lg="12"
								xl="6"
								className={whatsNeededData.UIType === 2 ? "one mb-2 " : "mb-2 "}
							>
								<Form.Check
									className={whatsNeededData.UIType === 2 ? "" : "solo"}
									type="radio"
									label={whatsNeededData.SoloLabel}
									value={1}
									name="bungii-type"
									id="solo"
									checked={this.props.tripType === 1}
									onChange={(event) =>
										this.props.setTripType(Number(event.target.value))
									}
								/>
								<img
									src={
										whatsNeededData.SoloIcon === "oneDriverOneTruck"
											? oneDriverOneTruck
											: whatsNeededData.SoloIcon === "oneDeliveryPro"
											? oneDeliveryPro
											: oneDriver
									}
									alt="Solo"
									className={
										whatsNeededData.UIType === 2 ? "solo-img one" : "solo-img"
									}
								/>
								<span className="form-check-desc">
									{whatsNeededData && whatsNeededData.SoloDescriptionTitle}
									<ul className="sku-list">
										{whatsNeededData?.SoloDescriptionValue?.map((val) => {
											return <li key={val}>{val}</li>;
										})}
										{Object.keys(whatsNeededData.SoloToolTip).length > 0 && (
											<li
												className="toolTipOverlay"
												onClick={this.props.setTooltip.bind(
													this,
													"showSoloTooltip"
												)}
												ref={this.props.soloRef}
											>
												{whatsNeededData.SoloToolTip.Title}
												<i
													onClick={this.props.setTooltip.bind(
														this,
														"showSoloTooltip"
													)}
													ref={this.props.soloRef}
													className="infoIcon infoTooltip"
												/>
											</li>
										)}
										<TooltipOverlay
											target={this.props.soloRef.current}
											showTooltip={this.props.showSoloTooltip}
											hideTooltip={this.props.hideTooltip.bind(
												this,
												"showSoloTooltip"
											)}
										>
											{whatsNeededData.SoloToolTip.ToolTipValue &&
												whatsNeededData.SoloToolTip.ToolTipValue.map(
													(data, i) => <p key={`Solo-${i}`}>{data}</p>
												)}
										</TooltipOverlay>
									</ul>
								</span>
							</Col>
						)}
						{(whatsNeededData.UIType !== 5 && whatsNeededData.UIType !== 6) && this.props.isDuo ? (
							<Col
								xs="12"
								sm="6"
								lg="12"
								xl="6"
								className={whatsNeededData.UIType === 2 ? "two" : ""}
							>
								<Form.Check
									className={whatsNeededData.UIType === 2 ? "" : "inactive duo"}
									type="radio"
									label={whatsNeededData.DuoLabel}
									value={2}
									name="bungii-type"
									id="duo"
									checked={this.props.tripType === 2}
									onChange={(event) =>
										this.props.setTripType(Number(event.target.value))
									}
								/>
								<img
									src={
										whatsNeededData.DuoIcon === "twoDriversTwoTrucks"
											? twoDriversTwoTrucks
											: whatsNeededData.DuoIcon === "twoDeliveryPros"
											? twoDeliveryPros
											: twoDriver
									}
									alt="Duo"
									className={
										whatsNeededData.UIType === 2 ? "duo-img two" : "duo-img"
									}
								/>
								<span
									className={
										whatsNeededData.DuoIcon === "twoDeliveryPros"
											? "form-check-desc pros"
											: "form-check-desc"
									}
								>
									{whatsNeededData.DuoDescriptionTitle}
									<ul className="sku-list">
										{whatsNeededData?.DuoDescriptionValue?.map((val) => {
											return <li key={val}>{val}</li>;
										})}
										{Object.keys(whatsNeededData.DuoToolTip).length > 0 && (
											<li
												className="toolTipOverlay"
												onClick={this.props.setTooltip.bind(
													this,
													"showDuoTooltip"
												)}
												ref={this.props.duoRef}
											>
												{whatsNeededData.DuoToolTip.Title}
												<i
													onClick={this.props.setTooltip.bind(
														this,
														"showDuoTooltip"
													)}
													ref={this.props.duoRef}
													className="infoIcon infoTooltip"
												/>
											</li>
										)}
										<TooltipOverlay
											target={this.props.duoRef.current}
											showTooltip={this.props.showDuoTooltip}
											hideTooltip={this.props.hideTooltip.bind(
												this,
												"showDuoTooltip"
											)}
										>
											{whatsNeededData.DuoToolTip.ToolTipValue &&
												whatsNeededData.DuoToolTip.ToolTipValue.map(
													(data, i) => <p key={`Duo-${i}`}>{data}</p>
												)}
										</TooltipOverlay>
									</ul>
								</span>
							</Col>
						) : (
							""
						)}
						{whatsNeededData.UIType === 5 && (
							<Col xs="12">
								<Form.Check
									type="radio"
									label={whatsNeededData.Label}
									value={this.checkIfSoloOrDuo("solo", "duo")}
									name="bungii-type"
									id={this.checkIfSoloOrDuo("solo", "duo")}
									checked={
										whatsNeededData.NoOfDriver === this.checkIfSoloOrDuo(1, 2)
									}
									onChange={(event) =>
										this.props.setTripType(event.target.value)
									}
								/>
								<img
									src={this.getIconName(whatsNeededData.Icon)}
									alt={this.checkIfSoloOrDuo("Solo", "Duo")}
									className={this.checkIfSoloOrDuo("solo-img", "duo-img")}
								/>
								<span className={"form-check-desc"}>
									{whatsNeededData.DescriptionTitle}
									<ul>
										{whatsNeededData?.DescriptionValue?.map((val) => {
											return <li key={val}>{val}</li>;
										})}
										{Object.keys(whatsNeededData.SoloToolTip).length > 0 && (
											<li
												className="toolTipOverlay"
												onClick={this.props.setTooltip.bind(
													this,
													"showSoloTooltip"
												)}
												ref={this.props.soloRef}
											>
												{whatsNeededData.SoloToolTip.Title}
												<i
													onClick={this.props.setTooltip.bind(
														this,
														"showSoloTooltip"
													)}
													ref={this.props.soloRef}
													className="infoIcon infoTooltip"
												/>
											</li>
										)}
										<TooltipOverlay
											target={this.props.soloRef.current}
											showTooltip={this.props.showSoloTooltip}
											hideTooltip={this.props.hideTooltip.bind(
												this,
												"showSoloTooltip"
											)}
										>
											{whatsNeededData.SoloToolTip.ToolTipValue &&
												whatsNeededData.SoloToolTip.ToolTipValue.map(
													(data, i) => <p key={`Solo-${i}`}>{data}</p>
												)}
										</TooltipOverlay>
									</ul>
								</span>
							</Col>
						)}
					</Row>
					{whatsNeededData.UIType === 6 && this.props.tripType === 1 && !this.props.isMuscleHelpRequired && (
						<ul className="description-value">{whatsNeededData?.SoloDescriptionValue?.map((val) => {
							return <li key={val}>{val}</li>;
						})}</ul>
					)}
					{(this.props.isMuscleHelpRequired || (whatsNeededData.UIType === 6  && this.props.tripType === 2)) && (
						<ul className="description-value">{whatsNeededData?.DuoDescriptionValue?.map((val) => {
							return <li key={val}>{val}</li>;
						})}</ul>
					)}
					{partnerDisclaimerField && !quoteOnlyUser && (
						<ul className="partner-disclaimer-mge">{partnerDisclaimerField}</ul>
					)}

					<div className={IsMuscleHelpEnabled && this.props.tripType===1 ? 'vanity_item_box' : 'd-none'}>
						<Row>
							<Col>
								<span className='form-description'>
									{languageConstants.getEstimate.isMuscleHelpRequiredText}
								</span>
								<span className='text-mandatory'>*</span>
							</Col>
						</Row>
						<Row>
							<Col className='p-0' xs={12}>
								<Form.Check
									inline
									label="Yes"
									name="group1"
									type={'radio'}
									id={`inline-radio-1`}
									checked={this.props.isMuscleHelpRequired}
									onChange={(e)=>{
										this.props.getServiceLevelsForVanityItems(true);
									}}
								/>
								<Form.Check
									inline
									label="No"
									name="group1"
									type={'radio'}
									id={`inline-radio-2`}
									checked={this.props.isMuscleHelpRequired === false}
									onChange={(e)=>{
										this.props.getServiceLevelsForVanityItems(false);
									}}
								/>
							</Col>
							<Col xs={12}>
								<div className="invalid-feedback d-block">
									{this.props.muscleHelpRequiredError}
								</div>
							</Col>
						</Row>
					</div>

					{CustomOrderURL && !quoteOnlyUser && (
						<div className="custom_quote_disc">
							<Row>
								<Col xs={8}>
									<h5>Custom Quotes</h5>
									{CustomOrderMessage}
								</Col>
								<Col>
									<a
										href={CustomOrderURL}
										target="_blank"
										rel="noopener noreferrer"
										onClick={() => sendEvent(globalConstants.GAEventNames.FillOutThisFormLink, "Click")}
									>
										{CustomOrderURLLabel}
									</a>
								</Col>
							</Row>
						</div>
					)}
				</Form.Group>
				{this.props.showCheckbox && !quoteOnlyUser && (
					<Form.Group
						id="helperCheckbox"
						className={"mandateCheckbox full-width " + color + " " + pointer}
					>
						<Form.Check
							type="checkbox"
							checked={this.props.checked}
							id="DriverHelpCarryFields"
							label={this.props.driverHelpCarryFieldMessage}
							onChange={this.props.handleChange}
						/>
					</Form.Group>
				)}
			</React.Fragment>
		);
	}
}

export default connect(
	({ common: { appSetting },
		user: { quoteOnlyUser },
		getEstimate : {isMuscleHelpRequired}
	}) => ({
		appSetting,
		quoteOnlyUser,
		isMuscleHelpRequired
	}),
	dispatch => ({
		setIsMuscleHelpRequired : (isRequired) => dispatch(setIsMuscleHelpRequired(isRequired)),
	})
)(TripType);
