import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class ToasterService extends React.Component {
   ensurePreviousToastIsRemoved = (toastId) => {
      if (!toast.isActive(toastId)) {
         toast.dismiss(toastId)
      }
   }

   showCustomToasty = async (message, toastType) => {
      let toastId = message
      await this.ensurePreviousToastIsRemoved(toastId)
      // This function generates a custom Toastr bases on the type passed.
      switch (toastType) {
         case 'info':
            toast.info(message, {
               toastId: toastId,
               containerId: toastType,
               autoClose: false
            });
            break;
         case 'success':
            toast.success(message, {
               toastId: toastId,
               containerId: toastType,
               autoClose: false
            });
            break;
         case 'warn':
            toast.warn(message, {
               toastId: toastId,
               containerId: toastType,
               autoClose: false
            });
            break;
         case 'error':
            toast.error(message, {
               toastId: toastId,
               containerId: toastType,
               autoClose: false
            });
            break;
         default:
            toast(message, {
               toastId: toastId,
               containerId: toastType,
               autoClose: false
            });
      }
   }

   hideCustomToasty = () => {
      toast.dismiss();
   }
}

const toasterService = new ToasterService()

export default toasterService


