import {Buffer} from 'buffer';

class base64Encode {
    base64Encode = (AppKey, AppSecret) => {
        let encodedString = Buffer(AppKey + ':' + AppSecret).toString('base64');
        return encodedString;
    }
}

const base64EncodedSrting = new base64Encode();

export default base64EncodedSrting;