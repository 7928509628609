import React from "react";
import { useSelector } from "react-redux";
import languageConstants from "../shared/appConfig/languageConstants";

const Summary = ({editBungii,whatsNeededData,state}) => {
    const { pickupAdd,dropoffAdd,deliveryTime,distance,partnerUpchargeValue, isMuscleHelpRequired,tripType,estimate} = useSelector((state) => state.getEstimate);
    const { scheduledTimestamp,paymentMethodSelected,noOfDrivers } = useSelector((state) => state.deliveryDetails);
    const { appSetting,showPrice } = useSelector((state) => state.common);

    return (
        <React.Fragment>
            <div className="summary-container grey">
                <h2 className="summary-label">
                    Summary{" "}
                    <span className="edit-label" onClick={editBungii}>
                        EDIT
                    </span>
                </h2>
                {state.isVisibleSoloDuo && (
                    <div>
                        <label>What’s needed:</label>
                        <p>
                            {
                                whatsNeededData.UIType === 6 ?
                                    (isMuscleHelpRequired ? "Large item(s) - 2 drivers" : tripType + " Pallet" + (tripType > 1 ? "s" : ""))
                                    : (noOfDrivers === 1
                                        ? whatsNeededData.SoloSummarysectionvalue
                                        : whatsNeededData.DuoSummarysectionvalue)
                            }
                        </p>
                    </div>
                )}
                <div>
                    <label>Pickup Address:</label>
                    <p>{pickupAdd}</p>
                </div>
                <div>
                    <label>Delivery Address:</label>
                    <p>{dropoffAdd}</p>
                </div>
                {state.serviceName && (
                    <div>
                        <label>Service Level:</label>
                        <p>
                            {state.serviceName +
                                " - " +
                                state.serviceDescription}
                        </p>
                    </div>
                )}
                <div>
                    <label>Pickup Date &amp; Time:</label>
                    <p>{scheduledTimestamp}</p>
                </div>
                
                <div>
                    <label>Estimated Delivery Time:</label>
                    <p>{deliveryTime}</p>
                </div>
                <div>
                    <label>Distance:</label>
                    <p>{distance} miles</p>
                </div>
                <div>
                    <label>Payment:</label>
                    {paymentMethodSelected === "MI" && (
                        <p>This will be included on your invoice.</p>
                    )}
                    {paymentMethodSelected === "CC" && <p>Customer Card</p>}
                    {paymentMethodSelected === "PC" && <p>Partner Pay</p>}
                </div>
                <div>
                    <h2 className="estimate-label">
                        {appSetting.PartnerLocationSettings.EstimatePageLabel ===
                            "Get Quote"
                            ? "Delivery Cost"
                            : "Estimated Cost"}
                        <span>
                            <strong>
                                {showPrice
                                    ? appSetting.PartnerLocationSettings
                                        ?.EstimatePageFields[0]?.IsPartnerUpcharge
                                        ? partnerUpchargeValue.split("-")[0]
                                        : estimate
                                    : languageConstants.defaultPrice.bestBuy}
                            </strong>
                        </span>
                    </h2>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Summary;