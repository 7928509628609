import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../../assets/images/footer-logo.png";

class Footer extends Component {
	render() {
		return (
			<footer>
				<Container>
					<Row>
						<Col className="text-center d-block d-md-none m-b-20" md="4">
							<img src={logo} width="106" className="logo" alt="Bungii logo" />
						</Col>
						<Col md="8" className="terms-of-use">
							<p className="text-center text-md-left">
								&copy; {new Date().getFullYear()} Bungii.com. All rights
								reserved.{" "}
								<span className="d-none d-lg-inline">
									By using this site, you agree to our{" "}
									<a
										href="https://bungii.com/legal/"
										target="_blank"
										rel="noopener noreferrer"
									>
										Terms of Use
									</a>
									.
								</span>
							</p>
						</Col>
						<Col className="text-right d-none d-md-block" md="4">
							<img src={logo} width="106" className="logo" alt="Bungii logo" />
						</Col>
					</Row>
				</Container>
			</footer>
		);
	}
}

export default Footer;
