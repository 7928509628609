import React from 'react';

//This is a service class containing functions for session and local storage get, set and clear.
class StorageService extends React.Component {

    //Session Storage
    setSessionStorageItem = async(key, value) => {
        try {
            await sessionStorage.setItem(key, JSON.stringify(value));
        }
        catch(err) {
            throw err;
        }
    }

    getSessionStorageItem = async(key) => {
        try {
            return  await JSON.parse(sessionStorage.getItem(key));
        }
        catch(err) {
            throw err;
        }
    }

    getItemFromSessionStorage = (key) => {
        return JSON.parse(sessionStorage.getItem(key));
    }

    clearSessionStorageItems = async() => {
        try {
            return await sessionStorage.clear();
        }
        catch(err) {
            throw err;
        }
    }

    //Local Storage
    setLocalStorageItem = async(key, value) => {
        try {
            await localStorage.setItem(key, JSON.stringify(value));
        }
        catch(err) {
            throw err;
        }
    }
    
    getLocalStorageItem = async(key) => {
        try {
            return  await JSON.parse(localStorage.getItem(key));
        }
        catch(err) {
            throw err;
        }
    }

    clearLocalStorageItems = async() => {
        try {
            return await localStorage.clear();
        }
        catch(err) {
            throw err;
        }
    }

}

const storageService = new StorageService();

export default storageService;
// export default connect('','')(storageService);
