import { handleLoginGetReq, handleLoginPostReq } from '../../shared/services/AppendHeadersService';
import appConfig from '../../shared/appConfig/appConfig';

class AuthService {
    async verifySubDomain(params) {
        try {
            const response = await handleLoginGetReq(appConfig.urls.getpartnerlocationforsubdomain, params);
           return response;
        } catch (err) {
            return false;
        }
    }

    async isAuthenticated(data) {
        try {
            const response = await handleLoginPostReq(appConfig.urls.login, data);
            return response;
        } catch (err) {
            return false;
        }
    }

    async isUserAuthorized(data) {
        try {
            const response = await handleLoginPostReq(appConfig.urls.authorizeuser, data);
            return response;
        } catch (err) {
            return false;
        }
    }

    async quickAccess(data) {
        try {
            const response = await handleLoginPostReq(appConfig.urls.quickaccesslogin, data);
            return response;
        } catch (err) {
            return false;
        }
    }
}

const AuthClient = new AuthService();

export default AuthClient;