import React, { useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Form, Dropdown, ButtonGroup } from "react-bootstrap";
import { useSelector } from "react-redux";

const DashboardFilter = ({
	onSearch,
	clearSearch,
	filterStatusString,
	resetFilters,
	applyFilters,
}) => {
	const [dropdownVisibility, setDropdownVisibility] = useState(false);

	const { appSetting } = useSelector((state) => state.common);
	const optionValues = appSetting.PartnerLocationSettings
		?.CustomerDelScheduleSmsResponse
		? [
			"Awaiting Customer Confirmation",
			"Scheduled",
			"In-Progress",
			"Completed",
			"Canceled",
		]
		: ["Scheduled", "In-Progress", "Completed", "Canceled"];

	/**
	 * checkUncheckAll()
	 * This function is triggered on click of uncheck checkbox.
	 */
	const checkUncheckAll = () => {
		try {
			let selectAllCheckboxes = document.getElementById("checkUncheckAll");
			var checkboxes = document.getElementsByName("deliveryStatus");
			if (selectAllCheckboxes.checked === true) {
				checkboxes.forEach((item) => {
					item.checked = true;
				})
			} else {
				checkboxes.forEach((item) => {
					item.checked = false;
				})
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * handleChange()
	 * This function is triggered on click of each filter from the filter list
	 */
	const handleChange = async (event) => {
		try {
			document.getElementById(event.target.id).setAttribute("checked", "false");
			let checkboxes = document.getElementsByName("deliveryStatus");
			let selectAllCheckboxes = document.getElementById("checkUncheckAll");
			let checkboxesChecked = [];
			for (let index = 0; index < checkboxes.length; index++) {
				if (checkboxes[index].checked) {
					checkboxesChecked.push(checkboxes[index].id);
				}
			}
			if (checkboxesChecked.length === checkboxes.length) {
				selectAllCheckboxes.checked = true;
			} else {
				selectAllCheckboxes.checked = false;
			}
		} catch (err) {
			console.log(err);
		}
	};

	/**
	* onApplyFilter()
	* This function is used to fetch filtered data.
	*/
	const onApplyFilter = (event) => {
		setDropdownVisibility(false)
		applyFilters(event);
	}

	/**
	* onResetFilter()
	* This function is used to reset added filters.
	*/
	const onResetFilter = (event) => {
		setDropdownVisibility(false)
		resetFilters(event);
	}

	/**
	* CustomToggle()
	* This is to show/hide filter dropdown.
	*/
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<div
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				setDropdownVisibility(!dropdownVisibility);
			}}
			className="caret filter"
		>
			{children}
		</div>
	));

	return (
		<div className="row">
			<div className="search">
				<input
					label="Search"
					maxLength="99"
					name="searchText"
					autoComplete="off"
					id="searchText"
					className="searchText"
					placeholder="Search"
					onKeyPress={onSearch}
				/>
				<link onClick={clearSearch} id="closeIcon"></link>
			</div>
			<div className="col">
				<Dropdown className="filterDropdown" show={dropdownVisibility}>
					<ClickAwayListener onClickAway={() => setDropdownVisibility(false)}>
						<div>
							<Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
								<span>Filter:</span>{" "}
								{filterStatusString.length === optionValues.length
									? "All"
									: filterStatusString.join(", ")}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<div className="inner-content">
									<Form.Group controlId="checkbox">
										<Form.Check
											type="checkbox"
											name="checkUncheckAll"
											label="Check / uncheck all"
											id="checkUncheckAll"
											defaultChecked={false}
											onChange={(event) => checkUncheckAll(event)}
										/>
									</Form.Group>
									{optionValues.map((item, index) => (
										<Form.Group
											controlId={
												appSetting.PartnerLocationSettings
													.CustomerDelScheduleSmsResponse
													? index
													: index + 1
											}
											key={item}
										>
											<Form.Check
												type="checkbox"
												name="deliveryStatus"
												label={item}
												value={item}
												defaultChecked={
													item === filterStatusString[index] ? true : false
												}
												onChange={handleChange}
											/>
										</Form.Group>
									))}
									<ButtonGroup>
										<button className="btn" onClick={onApplyFilter}>
											Apply
										</button>
										<button className="btn btn-reset" onClick={onResetFilter}>
											RESET
										</button>
									</ButtonGroup>
								</div>
							</Dropdown.Menu>
						</div>
					</ClickAwayListener>
				</Dropdown>
			</div>
		</div>
	);
};

export default DashboardFilter;
