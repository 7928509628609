import React, {useEffect} from 'react';
import { withRouter } from "react-router-dom";
import '../../assets/sass/layout/_error.scss'
import error_image from '../../assets/images/error-image.svg';
const Error404 = () => {

  useEffect(() => {
    document.title = "Page Not Found 404 - Bungii";
  }, []);

  return (
      <div className="error container">
      <div className="row">
        <div className="col-12 col-sm-12 text-center vertically-center">
          <h1>404: Page Not Found</h1>
          <p>The page you are looking for does not exist. Try using the navigation.</p>
          <div className="img-wrapper">
            <img alt="404" className="img-dimension" src={error_image} />
          </div>
          <a href="/" className="btn btn-secondary">
            BACK TO HOME</a>
        </div>
      </div>
    </div>
  );

}

export default withRouter(Error404);
