import React, { Component } from "react";
import { Overlay, Tooltip } from "react-bootstrap";

class TooltipOverlay extends Component {
	render() {
		const { target, showTooltip, hideTooltip } = this.props;
		return (
			<React.Fragment>
				<Overlay
					target={target}
					show={showTooltip}
					placement={
						window.matchMedia("(min-width: 992px)").matches ? "right" : "bottom"
					}
					onHide={hideTooltip}
					rootClose={true}
				>
					{(props) => (
						<Tooltip {...props} show={showTooltip.toString()}>
							{this.props.children}
						</Tooltip>
					)}
				</Overlay>
			</React.Fragment>
		);
	}
}

export default TooltipOverlay;
