import React  from 'react';
import { withRouter } from 'react-router';
import AuthService from '../../shared/services/AuthService';
import globalConstants from '../../shared/appConfig/globalConstants';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setShowLoader } from '../../shared/redux/actions/common.actions';
import { setPartnerLocationReference } from '../redux/actions/user.actions';
/* Redux Import End */

function quickGuard(Component) {
    class quickAccessGuardComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isValidDomain: false,
            }
        }

        /**
         * componentWillMount()
         * This function Executes just before rendering takes place.
         */
        componentWillMount = async() => {
            this.checkAuth();
        }
        
        /**
         * checkAuth()
         * This function will act as a guard to redirect based on the conditions.
         */
        checkAuth = async() => {
            try{
                const { setShowLoader, setPartnerLocationReference } = this.props;
                setShowLoader(true);
                //To be used on Deployed Serves
                const fullUrl = window.location.host
        
                //Common code
                const subdomain = (globalConstants.FullUrl) ? globalConstants.FullUrl.split('.')[0] : fullUrl.split('.')[0]
                
                let data = {
                    "SubDomainName": subdomain
                }
                let response = await AuthService.verifySubDomain(data);
                
                if (response && response.data.Error === null && response.data.PartnerLocation !== null) {
                    setPartnerLocationReference(response.data.PartnerLocation.PartnerLocationRef)
                    const fullUrl = window.location.pathname;
                    const trackingId = fullUrl.split('/')[2]
                    if(!trackingId){
                        await setShowLoader(false);
                        window.location.href = '/page-not-found';
                    } else {
                        await setShowLoader(false);
                        this.setState({
                            isValidDomain: true
                        })
                    }
                }
                else {
                    await setShowLoader(false);
                    window.location.href = '/page-not-found';
                    
                }
            } catch (err) {
                console.log(err)
            }
        }

        render() {
            const { isValidDomain} = this.state;
            return isValidDomain
            ? <Component { ...this.props } />
            : null;
        }
    }
    
    return connect(
        (''),
        dispatch => ( 
            {
                setShowLoader: showLoader => dispatch(setShowLoader(showLoader)),
                setPartnerLocationReference: partnerLocationReference => dispatch(setPartnerLocationReference(partnerLocationReference)),
            }
        )
    )(withRouter(quickAccessGuardComponent));
}

export default quickGuard;