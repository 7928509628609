const getFullAddressFromObject = (data) => {
    let address = '';
    let address2 = data.Address2 ? (data.Address2 + ', ') : '';
    let state = data.State ? (data.State + ', ') : '';
    address = data.Address1 + ', '
        + address2 + data.City + ', ' + state
        + data.ZipPostalCode;

    return address;
}

export default getFullAddressFromObject;