import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
	isAuthenticated: false,
	partnerLocationReference: "",
	accessToken: "",
	quickAccessUser: false,
	quoteOnlyUser: false,
	isTrackDeliveriesEnabled: false,
	isEapiUser: false,
};

export const userSlice = createSlice({
	name: "user",
	initialState: INITIAL_STATE,
	reducers: {
		setIsAuthenticated: (state, action) => {
			state.isAuthenticated = action.payload;
		},
		setPartnerLocationReference: (state, action) => {
			state.partnerLocationReference = action.payload;
		},
		setAccessToken: (state, action) => {
			state.accessToken = action.payload;
		},
		setIsTrackDeliveriesEnabled: (state, action) => {
			state.isTrackDeliveriesEnabled = action.payload;
		},
		setIsEapiUser: (state, action) => {
			state.isEapiUser = action.payload;
		},
		setQuickAccessUser: (state, action) => {
			state.quickAccessUser = action.payload;
		},
		setQuoteOnlyUser: (state, action) => {
			state.quoteOnlyUser = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setIsAuthenticated,
	setPartnerLocationReference,
	setAccessToken,
	setIsTrackDeliveriesEnabled,
	setIsEapiUser,
	setQuickAccessUser,
	setQuoteOnlyUser,
} = userSlice.actions;

export default userSlice.reducer;

// export default userReducer;
