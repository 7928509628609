import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class Alert extends Component {
    handleClose = (event) => {
        event.preventDefault();
    }

    render() {
        const { showAlertModal, handleCloseAlert, showCloseIcon, alertType} = this.props;
        return (
            <React.Fragment>
                <Modal show={showAlertModal} onHide={(event) => this.handleClose.bind(this, event)} className={(alertType==='alert-sm' ? "alert-sm" : (alertType==='alert-md') ? "alert-md" : "") + ' alert-modal'} centered>
                
                {showCloseIcon &&<span className="modal-close" onClick={handleCloseAlert}></span>}
                    <Modal.Body>
                        {this.props.children}
                    </Modal.Body>
                </Modal>
            </React.Fragment >
        );
    }
}

export default Alert;
