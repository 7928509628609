
import axios from 'axios';

export const GET = async (apiURL, params, headers) => {
  const getResponse = await axios.get(apiURL, { crossDomain: true, params, headers })
  .then(response => {
    return response
  })
  .catch(error => {
    return error.response
  });
  return getResponse;
}

export const POST = async (apiURL, data, headers) => {
  let postResponse = await axios.post(apiURL, data, { crossDomain: true, headers })
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    });
  return postResponse
}

//
export const PATCH = async (apiURL, data, headers) => {
  let patchResponse = await axios.patch(apiURL, data, { crossDomain: true, headers })
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    });
  return patchResponse
}