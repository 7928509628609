import { useField } from "formik";
import React from "react";
import { FormControl } from "react-bootstrap";
import ReactInputMask from 'react-input-mask';
import globalConstants from '../../shared/appConfig/globalConstants';

const PhoneInput = ({ onBlur, ...props }) => {
	const [field, meta, helpers] = useField(props);
	const { setValue } = helpers;

	const onChangeHandler = (event) => {
		let value = event.target.value;
		value = value.replace(/\D/g, "")
		setValue(value);
	};
	field.onBlur = onBlur;
	field.onChange = onChangeHandler;


	return (
		<ReactInputMask
			mask={globalConstants.PhoneNumberFormat}
			{...field}
		>
			{(inputProps) => (
				<FormControl
					{...inputProps}
					type="text"
					autoComplete="off"
					isInvalid={meta.touched && meta.error}
				/>
			)}
		</ReactInputMask>
	);
};

export default PhoneInput;
