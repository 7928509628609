import React from "react";
import Header from "./common/Header";
import SubNavigation from "./common/SubNavigation";
import { Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "../../pages/Dashboard";
import Footer from "./common/Footer";
import appConfig from "../appConfig/appConfig";
import GetEstimates from "../../pages/getEstimates";
import DeliveryDetails from "../../pages/deliveryDetails";
import Success from "../../pages/Success";
import { Container } from "react-bootstrap";
import { handleGetReq } from "../services/AppendHeadersService";
import { phoneFormat } from "../modules/Phone";
import QuoteOnly from "../../pages/quoteOnly";
import storageService from "../services/StorageService";
import EAPIDashboard from '../../pages/EAPIDashboard';
import ScheduleSuccess from '../../pages/ScheduleSuccess';
import LinkExpired from '../../pages/LinkExpired';

/* Redux Import Start */
import { connect } from "react-redux";
import {
	setIsAuthenticated,
	setAccessToken,
	setQuickAccessUser,
} from "../redux/actions/user.actions";
import {
	setAppSetting,
	setActiveTab,
	setShowLoader,
	setCommonInitialState,
	setKioskMode,
	setDisplayKioskMode,
	setLockedAccess,
} from "../redux/actions/common.actions";
import {
	setDefaultGeofenceIndex,
	setDefaultGeofence,
	setTimeZone,
	setShowCheckbox,
	setDriverHelpCarryFieldMessage,
	resetDataOnPageChange,
} from "../redux/actions/getEstimate.actions";
import { setDeliveryDetailsInitialState } from "../redux/actions/deliveryDetails.actions";


/* Redux Import End */

class HomeLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			appSettingLoaded: false,
			reqPath: window.location.pathname,
			callSupportPhoneNumber: "",
			supportEmailId: "",
		};
	}

	initApp = async () => {
		try {
			const { setShowLoader } = this.props;
			let accessToken = await storageService.getSessionStorageItem(
				"accessToken"
			);
			this.setState({
				accessToken: accessToken,
			});
			setShowLoader(true);
			const appSetting = await handleGetReq(
				appConfig.urls.appSettings,
				"",
				accessToken
			);
			setShowLoader(false);
			return appSetting ? appSetting.data : null;
		} catch (e) {
			console.log("Unauthorized Access");
		}
	};

	setData = async () => {
		try {
			const {
				setAppSetting,
				appSetting,
				kioskMode,
				displayKioskMode,
				setKioskMode,
				setDisplayKioskMode,
				setLockedAccess,
			} = this.props;
			let appSettingData = await this.initApp();

			if (appSettingData && appSettingData.Error === null) {
				await setAppSetting(appSetting ? appSetting : appSettingData);
				await this.setState({
					appSettingLoaded: true,
					logoURL: appSettingData.PartnerLocationSettings.LogoURL,
					supportPhoneNumber: await phoneFormat(
						appSetting
							? appSetting.BungiiSettings.SupportPhoneNumber
							: appSettingData.BungiiSettings.SupportPhoneNumber
					),
					callSupportPhoneNumber:
						appSetting &&
						appSetting.PartnerLocationSettings.CallSupportPhoneNumber
							? await phoneFormat(
									appSetting.PartnerLocationSettings.CallSupportPhoneNumber
							  )
							: appSettingData.PartnerLocationSettings.CallSupportPhoneNumber
							? await phoneFormat(
									appSettingData.PartnerLocationSettings.CallSupportPhoneNumber
							  )
							: null,
					supportEmailId: appSetting
						? appSetting.PartnerLocationSettings.SupportEmailId
						: appSettingData.PartnerLocationSettings.SupportEmailId,
				});

				/**
				 * Set the kiosk mode based on the data
				 */
				if (kioskMode !== null) {
					if (
						this.state.reqPath === "/request-bungii" ||
						this.state.reqPath === "/delivery-details"
					) {
						if (kioskMode === false) {
							setLockedAccess(true);
						}
					}
					setKioskMode(kioskMode);
					setDisplayKioskMode(displayKioskMode);
				} else if (kioskMode === null) {
					setLockedAccess(
						appSetting
							? appSetting.PartnerLocationSettings.KioskMode
							: appSettingData.PartnerLocationSettings.KioskMode
					);
					setKioskMode(
						appSetting
							? !appSetting.PartnerLocationSettings.KioskMode
							: !appSettingData.PartnerLocationSettings.KioskMode
					);
					setDisplayKioskMode(
						appSetting
							? appSetting.PartnerLocationSettings.KioskMode
							: appSettingData.PartnerLocationSettings.KioskMode
					);
				}
			} else {
				window.location.replace(`/`);
			}
		} catch (err) {
			console.log(err);
		}
	};

	componentDidMount = async () => {
		await this.setData();
	};

	render() {
		const { accessToken, appSetting } = this.props;
		return (
			this.state.appSettingLoaded && (
				<React.Fragment>
					<main className="main-wrapper" id="main-wrapper">
						{/* Header */}
						<Header
							partnerLogo={this.state.logoURL}
							supportPhoneNumber={this.state.supportPhoneNumber}
							openPopper={this.state.openPopper}
							reqPath={this.state.reqPath}
							partnerName={
								appSetting.PartnerLocationSettings.BusinessPartnerName
							}
							callSupportPhoneNumber={this.state.callSupportPhoneNumber}
							supportEmailId={this.state.supportEmailId}
						/>

						{/* All main content goes inside the below section */}
						{/*iPad Portrait view*/}
						{/* <Container className="main-container ipad-portrait-view">
							<div className="portrait">
								<p>{languageConstants.portraitMode}</p>
							</div>
						</Container> */}

						{/* Navigation */}
						{/*iPad Landscape view*/}
						<Container className="main-container">
							{this.props.location.pathname !== "/dashboard" ? (
								<SubNavigation />
							) : (
								""
							)}
							{/* <Switch> */}
							{/* <Route exact path="/home" component={GetEstimates} accessToken={accessToken} /> */}
							<Route
								exact
								path="/request-bungii"
								component={GetEstimates}
								accessToken={accessToken}
							/>
							<Route
								exact
								path="/delivery/success"
								component={ScheduleSuccess}
								accessToken={accessToken}
							/>
							<Route
								exact
								path="/delivery/link-expired"
								component={LinkExpired}
								accessToken={accessToken}
							/>
							<Route
								exact
								path="/delivery/:trackingID"
								component={GetEstimates}
								accessToken={accessToken}
							/>
							<Route
								exact
								path="/dashboard"
								component={this.props.isTrackDeliveriesEnabled ? EAPIDashboard : Dashboard}
								accessToken={accessToken}
							/>
							<Route
								exact
								path="/delivery-details"
								component={DeliveryDetails}
								accessToken={accessToken}
							/>
							<Route
								exact
								path="/success"
								component={Success}
								accessToken={accessToken}
							/>
							<Route
								exact
								path="/quote-only"
								component={QuoteOnly}
								accessToken={accessToken}
							/>
							{/* </Switch> */}
						</Container>
						<ToastContainer position="bottom-right" closeOnClick={false} />

						<Footer />
					</main>
				</React.Fragment>
			)
		);
	}
}

export default connect(
	({
		getEstimate: {
			editPickup,
			pickupPoint,
			minDate,
			geofence,
			defaultGeofenceIndex,
			rerenderDropoff,
			pickupDate,
			isDuo,
			defaultPoint,
			pickupAdd,
			pickupAddObj,
			defaultAdd,
			loadUnloadTimeShow,
			defaultGeofence,
			pickupTime,
			tripType,
			dropoffAdd,
			dropoffPoint,
			timeZone,
			dropoffAddObj,
			nextCalculatedDate,
			pickupTimeValues,
			loadUnload,
			estimate,
			loadTime,
			pickupRequestID,
		},
		user: { isTrackDeliveriesEnabled, accessToken, partnerLocationReference, isAuthenticated },
		deliveryDetails: {
			pickupDetails,
			loadingUnloadingTime,
			noOfDrivers,
			scheduledTimestamp,
			paymentMethodSelected,
			lastFour,
		},
		common: {
			appSetting,
			activeTab,
			showLoader,
			kioskMode,
			displayKioskMode,
			lockedAccess,
			formFilled,
		},
	}) => ({
		isTrackDeliveriesEnabled,
		accessToken,
		activeTab,
		pickupRequestID,
		appSetting,
		pickupDetails,
		loadingUnloadingTime,
		noOfDrivers,
		scheduledTimestamp,
		editPickup,
		pickupPoint,
		minDate,
		geofence,
		defaultGeofenceIndex,
		rerenderDropoff,
		pickupDate,
		isDuo,
		defaultPoint,
		pickupAdd,
		pickupAddObj,
		defaultAdd,
		loadUnloadTimeShow,
		defaultGeofence,
		pickupTime,
		tripType,
		dropoffAdd,
		dropoffPoint,
		timeZone,
		dropoffAddObj,
		nextCalculatedDate,
		pickupTimeValues,
		loadUnload,
		estimate,
		loadTime,
		paymentMethodSelected,
		lastFour,
		showLoader,
		partnerLocationReference,
		kioskMode,
		displayKioskMode,
		lockedAccess,
		formFilled,
		isAuthenticated,
	}),
	(dispatch) => ({
		setActiveTab: (activeTab) => dispatch(setActiveTab(activeTab)),
		setAppSetting: (appSetting) => dispatch(setAppSetting(appSetting)),
		setShowLoader: (showLoader) => dispatch(setShowLoader(showLoader)),
		setDeliveryDetailsInitialState: (newValues1) =>
			dispatch(setDeliveryDetailsInitialState(newValues1)),
		setCommonInitialState: (commonInitialState) =>
			dispatch(setCommonInitialState(commonInitialState)),
		setDefaultGeofenceIndex: (defaultGeofenceIndex) =>
			dispatch(setDefaultGeofenceIndex(defaultGeofenceIndex)),
		setDefaultGeofence: (defaultGeofence) =>
			dispatch(setDefaultGeofence(defaultGeofence)),
		setTimeZone: (timeZone) => dispatch(setTimeZone(timeZone)),
		setShowCheckbox: (showCheckbox) => dispatch(setShowCheckbox(showCheckbox)),
		setDriverHelpCarryFieldMessage: (driverHelpCarryFieldMessage) =>
			dispatch(setDriverHelpCarryFieldMessage(driverHelpCarryFieldMessage)),
		setKioskMode: (kioskMode) => dispatch(setKioskMode(kioskMode)),
		setDisplayKioskMode: (displayKioskMode) =>
			dispatch(setDisplayKioskMode(displayKioskMode)),
		setLockedAccess: (lockedAccess) => dispatch(setLockedAccess(lockedAccess)),
		resetDataOnPageChange: (data) => dispatch(resetDataOnPageChange(data)),
		setIsAuthenticated: (isAuthenticated) =>
			dispatch(setIsAuthenticated(isAuthenticated)),
		setAccessToken: (accessToken) => dispatch(setAccessToken(accessToken)),
		setQuickAccessUser: (quickAccessUser) =>
			dispatch(setQuickAccessUser(quickAccessUser)),
	})
)(HomeLayout);
