import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loader from './shared/modules/Loader';
import HomeLayout from './shared/layouts/HomeLayout';
import BlankRouter from './shared/layouts/BlankRouter';
import LoginPage from './pages/auth/LoginPage';
import guardIsAllowed from './shared/guards/isAllowedComponent';
import guardCanActivate from './shared/guards/canActivateComponent';
import guardStepProcess from './shared/guards/guardStepProcessComponent';
import quickAccessGuard from './shared/guards/quickAccessGuardComponent';
import quickGuard from './shared/guards/quickGuardComponent';
import DeliveryStatus from './pages/DeliveryStatus';
import DeliveryWindow from './pages/DeliveryWindow';
import ScheduleSuccess from './pages/ScheduleSuccess';
import LinkExpired from './pages/LinkExpired';
import urlConstants from './shared/appConfig/urlConstants';
import base64EncodedString from "./shared/utilities/base64Encode";
import { handleLoginGetReq } from './shared/services/AppendHeadersService';

const Routes = () => {
    const isTrackDeliveriesEnabled = useSelector((state) => state.user.isTrackDeliveriesEnabled)

    const [authToken, setAuthToken] = useState(urlConstants.Authorization);
    const EapiRoutes = (
        <Switch>
            <Route exact path="/" render={() => (<Redirect to="/login" />)} />
            <Route path='/page-not-found' component={BlankRouter} />
            <Route path="/dashboard" component={guardIsAllowed(HomeLayout)} />
            <Route path="/delivery-details" component={guardStepProcess(HomeLayout)} />
            <Route path="/login" component={guardCanActivate(LoginPage)} />
            <Route path="/delivery/success" component={quickGuard(ScheduleSuccess)} />
            <Route path="/delivery/link-expired" component={quickGuard(LinkExpired)} />
            <Route path="/delivery/:trackingID" component={quickGuard(DeliveryWindow)} />
            <Route path='' render={() => (<Redirect to="/page-not-found" />)}/>
        </Switch>
    )

    const PartnerRoutes = (
        <Switch>
              <Route exact path="/" render={() => (<Redirect to="/login" />)} />
              <Route path="/login" component={guardCanActivate(LoginPage)} />
              <Route path='/page-not-found' component={BlankRouter} />
              <Route path="/dashboard" component={guardIsAllowed(HomeLayout)} />

              <Route path="/request-bungii" component={guardStepProcess(HomeLayout)} />
              <Route path="/delivery-details" component={guardStepProcess(HomeLayout)} />
              <Route path="/success" component={guardStepProcess(HomeLayout)} />

              <Route path="/quick-access" component={quickAccessGuard(HomeLayout)} />
              <Route path="/delivery/success" component={quickGuard(ScheduleSuccess)} />
              <Route path="/delivery/link-expired" component={quickGuard(LinkExpired)} />
              <Route path="/delivery/:trackingID" component={quickGuard(DeliveryWindow)} />

              <Route path="/Pickup" sensitive component={quickGuard(DeliveryStatus)} />

              {/* This path is only to get the quotation */}
              <Route path="/quote-only" component={quickAccessGuard(HomeLayout)} />
              
              <Route path='' render={() => (<Redirect to="/page-not-found" />)}/>
        </Switch>
    )

    /**
     * This function is to fetch app key and secret from BE which will be used as authorization token
     */
    const getAppSettings = async () => {
		try {
			const response = await handleLoginGetReq(
                urlConstants.getAppSettings
            );
            if(response.data.Error){
                throw(response.data.Error)
            }else{
                const {keys} = response.data
                urlConstants.Authorization = `Basic ${base64EncodedString.base64Encode(keys.appKey,keys.secret)}`
                setAuthToken(urlConstants.Authorization)
            }
		} catch (error) {
			console.log(error);
		}
	};

    useEffect(()=>{
        if(authToken === '')
            getAppSettings();
    },[authToken])

    if(authToken === '') return <></>

    return (
          <Suspense fallback={<Loader show={true}></Loader>}>
            {
                isTrackDeliveriesEnabled ? EapiRoutes : PartnerRoutes
            }
            <Loader></Loader>
          </Suspense>
    );
};

export default Routes;