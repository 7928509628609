export const changePositions = async (arr, old_index, new_index) => {
	if (new_index >= arr.length) {
		var k = new_index - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	return arr;
};

export const objectsEqual = (o1, o2) =>
	typeof o1 === "object" && Object.keys(o1).length > 0
		? Object.keys(o1).length === Object.keys(o2).length &&
		  Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
		: o1 === o2;

export const arraysEqual = (a1, a2) =>
	a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export const removeIntersection = (a1, a2) => a1.filter((value) => !a2.some(item=> item.Name === value.Name))
