// @flow
import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DateRangePicker } from "materialui-daterange-picker";
import appConfig from '../../appConfig/appConfig';

export class CalenderModal extends React.Component{

  render() {
    let today = new Date();
    let maxDate = new Date(today.getFullYear(), today.getMonth()+2, 0);
    const {calenderStatus,reportDates,handleDateChange,close,generateReport, reportsError} = this.props;
    return (
        <div>
            <Modal show={calenderStatus} className="date-picker-modal" onHide={() => close()}>
                <Modal.Header closeButton>
                    Delivery History Report
                </Modal.Header>
                <div className='modal-body' >
                    <div className='calender-container' >
                        <DateRangePicker
                            open={calenderStatus}
                            range={reportDates}
                            toggle={() => null}
                            onChange={(range) => handleDateChange(range)}
                            maxDate={maxDate}
                            />
                    </div>
                    {reportsError && <div className='err' >{appConfig.language.deliveryReport.noDataAvailable}</div>}
                    <div className='generate-btn'>
                        <Button disabled={reportDates.startDate === false || reportsError === true} onClick={generateReport} >GENERATE REPORT</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
  };
};
