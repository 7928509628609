import React, { useEffect, useState } from "react";
import Alert from "./Alert";
import { useIdleTimer } from "react-idle-timer";
import { ButtonGroup } from "react-bootstrap";

const timeout = process.env.REACT_APP_SESSION_TIMEOUT * 3600_000;

// total time prompt will be visible
const promptBeforeIdle = 300_000;

const Timeout = ({ logoutHandler }) => {
	const [remaining, setRemaining] = useState(timeout);
	const [open, setOpen] = useState(false);

	const onIdle = () => {
		setOpen(false);
		logoutHandler();
	};

	const onActive = () => {
		setOpen(false);
	};

	const onPrompt = () => {
		setOpen(true);
	};

	const { getRemainingTime, activate } = useIdleTimer({
		onIdle,
		onActive,
		onPrompt,
		timeout,
		promptBeforeIdle,
		throttle: 500,
		events: 
		[
		  'mousemove',
		  'keydown',
		  'wheel',
		  'DOMMouseScroll',
		  'mousewheel',
		  'mousedown',
		  'touchstart',
		  'touchmove',
		  'MSPointerDown',
		  'MSPointerMove',
		]
	});

	const handleStillHere = () => {
		activate();
	};

	let minutes = parseInt(remaining / 60, 10);
	let seconds = parseInt(remaining % 60, 10);

	minutes = minutes < 10 ? "0" + minutes : minutes;
	seconds = seconds < 10 ? "0" + seconds : seconds;

	useEffect(() => {
		const interval = setInterval(() => {
			setRemaining(Math.ceil(getRemainingTime() / 1000));
		}, 1000);

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Alert showAlertModal={open} alertType="alert-sm">
			<h2>Session Expiring!</h2>
			<p>
				Your session will expire in{" "}
				<span id="timer">{minutes + ":" + seconds}</span> minutes. Please click
				on continue to keep working.
			</p>
			<ButtonGroup>
				<button className="btn" onClick={handleStillHere}>
					Continue
				</button>
			</ButtonGroup>
		</Alert>
	);
};

export default Timeout;
