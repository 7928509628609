import { useField } from "formik";
import React from "react";
import { FormControl } from "react-bootstrap";
import InputMask from "react-input-mask";
import globalConstants from "../../shared/appConfig/globalConstants";
import { MenuItem, Select } from "@material-ui/core";

const TextField = ({
	showRemainingCharCount = false,
	onBlur,
	PhoneInput = false,
	ControlType,
	ControlValues,
	showInstantFeedback = false,
	NumberOnly = false,
	...props
}) => {
	const [field, meta, helpers] = useField(props);

	const onChangeHandler = (event) => {
		let value = event.target.value;
		if (PhoneInput || NumberOnly) {
			value = value.replace(/\D/g, "")
			helpers.setValue(value);
		} else {
			helpers.setValue(value);
		}
	};
	field.onBlur = onBlur;
	field.onChange = onChangeHandler;

	return (
		<>
			{PhoneInput ? (
				<InputMask
					mask={globalConstants.PhoneNumberFormat}
					{...field}
				>
					{(inputProps) => (
						<FormControl
							{...inputProps}
							type="text"
							autoComplete="off"
							isInvalid={meta.touched && meta.error}
						/>
					)}
				</InputMask>
			) : ControlType === "DropDown" ? (
				<Select
					className={
						meta.touched && meta.error
							? "form-control is-invalid"
							: "form-control"
					}
					{...props}
					{...field}
					displayEmpty
					size="small"
				>
					<MenuItem value="" disabled>
						Select
					</MenuItem>
					{ControlValues?.map((item, key) => {
						return (
							<MenuItem key={key} value={item}>
								{item}
							</MenuItem>
						);
					})}
				</Select>
			) : (
				<FormControl
					{...props}
					{...field}
					isInvalid={(showInstantFeedback || meta.touched) && meta.error}
				/>
			)}
		</>
	);
};

export default TextField;
