import React from "react";
import {Table } from "react-bootstrap";

const DeliveryDetailsTable = ({tableHeader,tableData,tableClass,tableFooter=null}) => {
    return (
        <React.Fragment>
            <Table className={tableClass}>
                <thead>
                    <tr>
                        {tableHeader.map((data) => {
                            return <th>{data.title}</th>
                        })}
                    </tr>
                </thead>
                <tbody>{tableData.map((x, i) =>
                    <tr key={i}>
                        {tableHeader.map((data, index) => {
                            return <td key={index}>{data.formatter ? data.formatter(x, i) : x[data.value]}</td>
                        })}
                    </tr>
                )}</tbody>
                {tableFooter}
            </Table>
        </React.Fragment>
    );
};

export default DeliveryDetailsTable;