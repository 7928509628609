import React, { Component } from 'react';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import Alert from '../../shared/layouts/common/Alert';
import languageConstants from '../../shared/appConfig/languageConstants';
import Radio from '@material-ui/core/Radio';
import { currencyUtils } from '../../shared/utilities/currencyUtils';

/* Redux Import Start */
import { connect } from 'react-redux';
import { setServiceLevel } from '../../shared/redux/actions/getEstimate.actions';
import { setShowLoader } from '../../shared/redux/actions/common.actions';

/* Redux Import End */

class ServiceLevelModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedService: (this.props.serviceLevel) ? this.props.serviceLevel.ServiceRef : ''
        }
    }

    /**
     * handleServiceChange()
     * This function is called on click of service Level radio buttons
     */
    handleServiceChange = (e) => {
        e.persist();
        
        this.setState({
            selectedService: e.target.value
        })
    }

    /**
     * applyService()
     * This function will be called on click of Apply button
     */
    applyService = () => {
        try{
            const { serviceLevelValues, setServiceLevel, setShowLoader } = this.props;
            setShowLoader(true);
            let serviceData = [];
            serviceData = serviceLevelValues.filter((data) => data.ServiceRef === this.state.selectedService);
            setServiceLevel(serviceData[0])
            setShowLoader(false);
            this.props.handleServiceModalClose();
        } catch (err) {
            console.log(err)
        }
        
    }

    /**
     * handleResetServiceState()
     * To close the service modal on click of close icon and reset the state
     * to the original one.
     */
    handleResetServiceState = () => {
        this.setState({
            selectedService: (this.props.serviceLevel) ? this.props.serviceLevel.ServiceRef : ''
        })
        this.props.handleServiceModalClose();
    }

    render() {
        const { serviceLevelValues } = this.props;
        return (
            <React.Fragment>
                <Alert showAlertModal={this.props.showServiceModal} handleCloseAlert={this.handleResetServiceState} showCloseIcon={true} alertType='alert-lg'>
                    <h2>{languageConstants.serviceLevel.modal.title}</h2>
                    <p className="service-info">{languageConstants.serviceLevel.modal.description}</p>
                    <div className="service-group">
                        {serviceLevelValues && serviceLevelValues.length > 0 && serviceLevelValues.map((e, key) => (
                            <Row className="service-list" key={key}>
                                <Col md="1">
                                    <Radio
                                        checked={(this.state.selectedService && this.state.selectedService === e.ServiceRef) ? true : false}
                                        onChange={event => this.handleServiceChange(event)}
                                        value={e.ServiceRef}
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                </Col>
                                <Col>
                                    <div>
                                        <p>{e.ServiceName}</p>
                                        <span className="p-r-10">{currencyUtils.formatCurrency(e.ServiceCost)}</span>
                                    </div>
                                    <p>{e.PartnerServiceDescription}</p>
                                </Col>
                            </Row>
                        ))}
                    </div>
                    <ButtonGroup>
                        <button className="btn" onClick={this.applyService}>APPLY</button>
                    </ButtonGroup>
                </Alert>
            </React.Fragment >
        );
    }
}

export default connect(
    (
        { 
            getEstimate: {
                serviceLevel, serviceLevelValues
            } 
        }
    ) => (
        {
            serviceLevel,
            serviceLevelValues
        }
    ),
    dispatch => (
        {
            setServiceLevel: serviceLevel => dispatch(setServiceLevel(serviceLevel)),
            setShowLoader: showLoader => dispatch(setShowLoader(showLoader)),
        }
    )
)(ServiceLevelModal);
