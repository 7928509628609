import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Error404 from '../../pages/error/Error404';

class BlankRouter extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/page-not-found" component={Error404} />
                <Route render={() => (<Redirect to="/page-not-found" />)} />
            </Switch>
        );
    }
}           

export default BlankRouter