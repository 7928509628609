import { Formik, FieldArray } from "formik";
import React, { useState, useRef } from "react";
import {
	Button,
	Col,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	InputGroup,
	Row,
} from "react-bootstrap";
import globalConstants from "../../shared/appConfig/globalConstants";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoader } from "../../shared/redux/actions/common.actions";
import { handleGetReq } from "../../shared/services/AppendHeadersService";
import appConfig from "../../shared/appConfig/appConfig";
import * as Yup from "yup";
import { removeIntersection } from "../../shared/utilities/arrayUtils";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Checkbox } from "@material-ui/core";
import languageConstants from "../../shared/appConfig/languageConstants";
import { UITypeDesc } from "./constants";
import DeliveryDetailsTable from "../../Components/DeliveryDetailsTable";
import xicon from "../../assets/images/icons/icon-x.png";
import { setInitialStates } from "../../shared/redux/actions/deliveryDetails.actions";
import TooltipOverlay from "../TooltipOverlay";
import TextFieldWithLabel from "./TextFieldWithLabel";
import PhoneInput from "./PhoneInput";
import TextField from "./Field";
import { default as AutoCompleteField } from "@material-ui/core/TextField";
import { setIsServiceLevelUpdated } from "../../shared/redux/reducers/getEstimate.reducer";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color={"primary"} />;

const itemsToDeliverMaxLength = globalConstants.MaxLength.ItemsToDeliver;
const specialInstructionsMaxLength =
	globalConstants.MaxLength.SpecialInstructions;

const staticFields = [
	{
		DisplayName: "CUSTOMER NAME (FIRST & LAST)",
		IsRequired: true,
		PlaceHolder: null,
		FieldName: "Customer full name",
		MappingDBFieldName: "CustomerName",
		ControlValues: [],
		FieldDataType: "string",
	},
	{
		DisplayName: "CUSTOMER MOBILE",
		IsRequired: true,
		PlaceHolder: null,
		FieldName: "Customer mobile",
		MappingDBFieldName: "CustomerMobile",
		ControlValues: [],
		FieldDataType: "phone",
	},
];

const DeliveryDetailsForm = (props) => {
	const { appSetting } = useSelector((state) => state.common);
	const { tripType, serviceLevel, isServiceLevelUpdated } = useSelector(
		(state) => state.getEstimate,
	);
	const { initialStates } = useSelector((state) => state.deliveryDetails);

	const { PartnerLocationSettings } = appSetting;
	const [checkedItems, setCheckedItems] = useState([]);
	const [showPalletTypeTooltip, setShowPalletTypeTooltip] = useState(false);

	const { accessToken } = useSelector((state) => state.user);
	const palletTypeRef = useRef();

	let dispatch = useDispatch();
	const { DropDownItems, AddMultipleCustomerPhoneNumber } =
		PartnerLocationSettings;
	const itemsToDeliverFieldData =
		PartnerLocationSettings.DeliveryDetailsFields[0];
	const { UIType } = itemsToDeliverFieldData;
	const specialInstructionsFieldData =
		PartnerLocationSettings.DeliveryDetailsFields[1];

	let itemsToDeliver = [];
	if (
		UITypeDesc[UIType].name === "ItemsToDeliver" &&
		!UITypeDesc[UIType].showTable
	) {
		itemsToDeliver = [...initialStates?.ItemsToDeliver.slice(0, tripType)];
		let meta = {};
		itemsToDeliverFieldData.Fields.forEach((field, index) => {
			meta[field.FieldName] = field.DefaultValue;
		});
		for (let i = itemsToDeliver.length; i < tripType; i++) {
			itemsToDeliver.push({ ...meta, ID: i + 1 });
		}
		// If service level is changed on getEstimate page, save service level name in product description
		// only for XLTile portals
		if (UIType === 5) {
			if (isServiceLevelUpdated) {
				itemsToDeliver[0].Name = serviceLevel.ServiceName;
				dispatch(setIsServiceLevelUpdated(false));
			}
		}
	} else {
		itemsToDeliver = initialStates.ItemsToDeliver;
	}

	/**
	 *
	 * @param {*} skuNumber
	 * This functions will be called when user enter 7 digit sku number and click on add button
	 * @returns skuDetails
	 */
	const getSkuDetails = async (skuNumber) => {
		dispatch(setShowLoader(true));
		const response = await handleGetReq(
			appConfig.urls.getSkuData + skuNumber,
			"",
			accessToken,
		);
		dispatch(setShowLoader(false));
		if (response.data.Error) {
			throw new Error(response.data.Error.Message);
		} else return response.data;
	};

	const Schema = Yup.object().shape({
		PickupNote: Yup.string().when("UIType", {
			is: 1,
			then: (schema) =>
				schema
					.trim()
					.required(languageConstants.errors.skuItemsToDeliver.emptyField),
		}),
		ItemsToDeliver: Yup.array()
			.when("UIType", {
				is: 4,
				then: (schema) =>
					schema
						.min(1, languageConstants.errors.skuItemsToDeliver.emptyField)
						.test(
							"Weight or volume exceeding",
							() => {
								let { MaxItemWeight, MaxItemVolume } = itemsToDeliverFieldData;
								return (
									<>
										{MaxItemVolume && (
											<span>
												{
													languageConstants.errors.skuItemsToDeliver
														.maxItemVolume
												}{" "}
												{MaxItemVolume} ft<sup>3</sup>
											</span>
										)}
										{MaxItemVolume && MaxItemWeight && <> and </>}
										<span>
											{
												languageConstants.errors.skuItemsToDeliver
													.maxItemWeight2
											}{" "}
											{MaxItemWeight} lbs
										</span>
										.
									</>
								);
							},
							(items) => {
								let totalItemsWeight = items.reduce(
									(totalWeight, item) =>
										totalWeight +
										Number(item.Weight?.split(" ")[0]) * Number(item.Quantity),
									0,
								);

								let totalItemsVolume = 0.0;
								items.forEach((item) => {
									totalItemsVolume += item.Volume * Number(item.Quantity);
								});
								totalItemsVolume = parseFloat(totalItemsVolume).toFixed(2);

								// flag to check if total weight exceeding max weight limit
								let { MaxItemWeight, MaxItemVolume } = itemsToDeliverFieldData;
								let isWeightExceedingMaxLimit =
									!!MaxItemWeight && MaxItemWeight < totalItemsWeight;
								let isVolumeExceedingMaxLimit =
									!!MaxItemVolume && MaxItemVolume < totalItemsVolume;

								if (isWeightExceedingMaxLimit || isVolumeExceedingMaxLimit)
									return false;
								return true;
							},
						),
			})
			.when(["UIType"], {
				is: 2,
				then: (schema) =>
					schema.min(1, languageConstants.errors.skuItemsToDeliver.emptyField),
			})
			.when(["UIType"], {
				is: 3,
				then: (schema) =>
					schema.of(
						Yup.object({
							Name: Yup.string()
								.trim()
								.required(languageConstants.errors.pallets.emptyPallet),
							Depth: Yup.number()
								.required(languageConstants.errors.pallets.emptyPallet)
								.min(
									itemsToDeliverFieldData.Fields[1].MinAllowedValue,
									itemsToDeliverFieldData.Fields[1].MinValidationText,
								)
								.max(
									itemsToDeliverFieldData.Fields[1].MaxAllowedValue,
									itemsToDeliverFieldData.Fields[1].MaxValidationText,
								),
							Width: Yup.number()
								.required(languageConstants.errors.pallets.emptyPallet)
								.min(
									itemsToDeliverFieldData.Fields[2].MinAllowedValue,
									itemsToDeliverFieldData.Fields[2].MinValidationText,
								)
								.max(
									itemsToDeliverFieldData.Fields[2].MaxAllowedValue,
									itemsToDeliverFieldData.Fields[2].MaxValidationText,
								),
							Height: Yup.number().required(
								languageConstants.errors.pallets.emptyPallet,
							),
							Weight: Yup.number()
								.required(languageConstants.errors.pallets.emptyPallet)
								.min(
									itemsToDeliverFieldData.Fields[4].MinAllowedValue,
									itemsToDeliverFieldData.Fields[4].MinValidationText,
								)
								.max(
									itemsToDeliverFieldData.Fields[4].MaxAllowedValue,
									itemsToDeliverFieldData.Fields[4].MaxValidationText,
								),
						}),
					),
			})
			.when("UIType", {
				is: 5,
				then: (schema) =>
					schema.of(
						Yup.object({
							Name: Yup.string()
								.trim()
								.required(languageConstants.errors.pallets.emptyXlTilePallet),
							noOfSlabs: Yup.number()
								.required(languageConstants.errors.pallets.emptyXlTilePallet)
								.min(
									itemsToDeliverFieldData.Fields[1].MinAllowedValue,
									itemsToDeliverFieldData.Fields[1].MinValidationText,
								)
								.max(
									itemsToDeliverFieldData.Fields[1].MaxAllowedValue,
									itemsToDeliverFieldData.Fields[1].MaxValidationText,
								),
						}),
					),
			}),
		CustomerName: Yup.string()
			.trim()
			.required("Customer full name is required."),
		CustomerMobile: Yup.array().of(
			Yup.string()
				.required("is required")
				.test(
					"is-valid-number",
					"is invalid",
					(value) => value?.replace(/\D/g, "").length === 10,
				),
		),
		StaticFields: Yup.array().of(
			Yup.object({
				FieldRef: Yup.string(),
				FieldValue: Yup.string()
					.when(["IsRequired"], {
						is: true,
						then: (schema) =>
							schema
								.trim()
								.required(languageConstants.errors.requiredField.message),
					})
					.when(["FieldDataType"], {
						is: "phone",
						then: (schema) =>
							schema.test("is-valid-number", "is invalid", (value) => {
								let phoneNo = value?.replace(/\D/g, "");
								return !phoneNo || phoneNo?.length === 10;
							}),
					}),
			}),
		),
		CustomFields: Yup.array().of(
			Yup.object({
				FieldRef: Yup.string(),
				FieldValue: Yup.string().when(["IsRequired"], {
					is: true,
					then: (schema) =>
						schema
							.trim()
							.required(languageConstants.errors.requiredField.message),
				}),
			}),
		),
	});

	return (
		<div>
			<Formik
				innerRef={props.formikRef}
				initialValues={{
					...initialStates,
					CustomerName:
						initialStates.CustomerName !== ""
							? initialStates.CustomerName
							: PartnerLocationSettings.DeliveryBusinessModel === 2
							? PartnerLocationSettings.BusinessPartnerName
							: "",

					CustomerMobile:
						initialStates.CustomerMobile[0] !== ""
							? initialStates.CustomerMobile
							: PartnerLocationSettings.BusinessPartnerLocationPhone === 2
							? [PartnerLocationSettings.BusinessPartnerLocationPhone]
							: [""],
					UIType,
					StaticFields:
						initialStates.StaticFields.length > 0
							? initialStates.StaticFields
							: PartnerLocationSettings.StaticFields.map((field) => {
									return {
										FieldRef: field.FieldRef,
										FieldValue: field.DefaultValue,
										IsRequired: field.IsRequired,
										FieldDataType: field.FieldDataType,
									};
							  }),
					CustomFields:
						initialStates.CustomFields?.length > 0
							? initialStates.CustomFields
							: PartnerLocationSettings.CustomFields.map((field) => {
									return {
										FieldRef: field.FieldRef,
										FieldValue: field.DefaultValue,
										IsRequired: field.IsRequired,
									};
							  }),
					ItemsToDeliver: itemsToDeliver,
				}}
				onSubmit={(values) => {
					props.handleSubmit(values);
				}}
				validationSchema={Schema}
			>
				{({
					handleSubmit,
					handleChange,
					values,
					touched,
					errors,
					setFieldValue,
					setFieldError,
					setFieldTouched,
					isValid,
				}) => {
					if (isValid !== props.isValid) props.setIsValid(isValid);
					// This code is to calculate total weight of items added in items to deliver
					let totalItemsWeight = values.ItemsToDeliver?.reduce(
						(totalWeight, item) =>
							totalWeight +
							Number(item.Weight?.split(" ")[0]) * Number(item.Quantity),
						0,
					);

					const handleBlur = async () => {
						dispatch(setInitialStates(values));
					};

					let totalItemsVolume = 0.0;
					values.ItemsToDeliver?.forEach((item) => {
						totalItemsVolume += item.Volume * Number(item.Quantity);
					});
					totalItemsVolume = parseFloat(totalItemsVolume).toFixed(2);

					// flag to check if total weight exceeding max weight limit
					let { MaxItemWeight, MaxItemVolume } = itemsToDeliverFieldData;
					let isWeightExceedingMaxLimit =
						!!MaxItemWeight && MaxItemWeight < totalItemsWeight;
					let isVolumeExceedingMaxLimit =
						!!MaxItemVolume && MaxItemVolume < totalItemsVolume;

					// this function is called when new items is added from dropdown
					const addItems = () => {
						// remove already added items from checkedItems
						let newItems = removeIntersection(
							checkedItems,
							values.ItemsToDeliver,
						);
						newItems.forEach((item) => {
							if (typeof item.Height === "number") item.Height += " inches";
							if (typeof item.Width === "number") item.Width += " inches";
							if (typeof item.Depth === "number") item.Depth += " inches";
							if (typeof item.Weight === "number") item.Weight += " lbs";
							item.Quantity = "1";
						});

						dispatch(
							setInitialStates({
								...values,
								ItemsToDeliver: [...values.ItemsToDeliver, ...newItems],
							}),
						);
						setFieldValue("ItemsToDeliver", [
							...values.ItemsToDeliver,
							...newItems,
						]);
					};

					// Validate and add sku item
					const addSKUtoCart = async () => {
						await setFieldTouched("skuNumber", true);

						let skuNumberSchema = Yup.string()
							.required(languageConstants.errors.skuItemsToDeliver.emptySku)
							.min(7, languageConstants.errors.skuItemsToDeliver.skuLength)
							.test(
								"Already exists",
								languageConstants.errors.skuItemsToDeliver.itemExists,
								(skuNumber) =>
									!values.ItemsToDeliver.some(
										(item) => Number(item.SKU) === Number(skuNumber),
									),
							)
							.test(
								"Cart is full",
								languageConstants.errors.skuItemsToDeliver.maxItemCount,
								() =>
									values.ItemsToDeliver.length < globalConstants.MaxItemAllowed,
							);
						let skuError = await skuNumberSchema
							.validate(values.skuNumber)
							.catch((error) => error);

						if (skuError.message) {
							console.log("test");
							setFieldError("skuNumber", skuError.message);
							return;
						}
						const maxWeightField =
							itemsToDeliverFieldData.MaxItemWeight ||
							globalConstants.SkuItemMaxWeight;

						try {
							const { ItemDetails } = await getSkuDetails(values.skuNumber);
							let { Weight } = ItemDetails;
							if (Weight && Weight.match(/(\d+)/)[0] >= maxWeightField) {
								await setFieldError(
									"skuNumber",
									`${languageConstants.errors.skuItemsToDeliver.maxItemWeight}${maxWeightField}lbs.`,
								);
								return;
							}
							await setFieldValue("ItemsToDeliver", [
								...values.ItemsToDeliver,
								ItemDetails,
							]);
							await setFieldValue("skuNumber", "");
							await setFieldTouched("skuNumber", false);
						} catch (error) {
							setFieldError("skuNumber", error.message);
						}
					};

					// increment/decrement/remove items from cart
					const updateQuantity = (index, type, offSet) => {
						let { maxQtyAllowed } = UITypeDesc[UIType];
						if (type === "REMOVE") {
							setFieldValue(
								`ItemsToDeliver`,
								values.ItemsToDeliver.filter((item, i) => {
									return index !== i;
								}),
							);
							setCheckedItems(
								checkedItems.filter(
									(item) => item.Name !== values.ItemsToDeliver[index].Name,
								),
							);
							return;
						}
						let currQty = Number(values.ItemsToDeliver[index]?.Quantity);
						if (currQty === 1 && type === "DEC") {
							return;
						}
						if (currQty === maxQtyAllowed && type === "INC") {
							return;
						}
						let newQty = currQty + (type === "DEC" ? -1 : 1) * 1;
						setFieldValue(
							`ItemsToDeliver[${index}].Quantity`,
							newQty.toString(),
						);
					};

					return (
						<Form
							onSubmit={handleSubmit}
							noValidate
							className="form-content delivery-details"
							id="formik-form"
						>
							<FormGroup>
								<FormLabel>
									{itemsToDeliverFieldData.DisplayLabel}
									{UIType !== 3 && <span className="text-mandatory">*</span>}

									{UITypeDesc[UIType].showTootip && (
										<>
											<i
												onClick={() =>
													setShowPalletTypeTooltip(!showPalletTypeTooltip)
												}
												ref={palletTypeRef}
												className="infoIcon"
											/>
											<TooltipOverlay
												target={palletTypeRef.current}
												showTooltip={showPalletTypeTooltip}
												hideTooltip={() => setShowPalletTypeTooltip(false)}
											>
												<p>
													{PartnerLocationSettings?.DeliveryDetailsFields?.map(
														(item) => {
															if (item?.FieldName === "ItemsToDeliver") {
																return item?.Fields?.map((data) => {
																	if (data?.FieldName === "Weight") {
																		return `${
																			languageConstants.pallets.palletInfo +
																			" " +
																			data?.MaxAllowedValue
																		} lbs.`;
																	} else return null;
																});
															} else return null;
														},
													)}
												</p>
											</TooltipOverlay>
										</>
									)}
								</FormLabel>
								{/* Pickup Note */}
								{!itemsToDeliverFieldData.AllowMultiple && UIType === 1 && (
									<TextField
										maxLength={itemsToDeliverMaxLength}
										name="PickupNote"
										type="text"
										autoComplete="off"
										placeholder={itemsToDeliverFieldData.WatermarkLabel}
										onBlur={handleBlur}
									/>
								)}
								{/* SKU items */}
								{itemsToDeliverFieldData.AllowMultiple && UIType === 2 && (
									<>
										<InputGroup>
											<FormControl
												maxLength="7"
												className={"col-9 col-sm-6 sku-text"}
												onChange={(e) =>
													setFieldValue(
														"skuNumber",
														e.target.value.replace(/\D/g, ""),
													)
												}
												name="skuNumber"
												type="text"
												autoComplete="off"
												placeholder={itemsToDeliverFieldData.WatermarkLabel}
												value={values.skuNumber}
												isInvalid={touched.skuNumber && !!errors.skuNumber}
											/>
											<Button
												id="skuForm"
												className="btn sku-btn"
												type="button"
												onClick={() => addSKUtoCart(values.skuNumber)}
											>
												ADD
											</Button>
										</InputGroup>
										<div className="invalid-feedback d-block">
											{touched.skuNumber && errors.skuNumber
												? errors.skuNumber
												: ""}
										</div>
									</>
								)}
								{/* Pallet Items */}
								{!itemsToDeliverFieldData.AllowMultiple &&
									UIType === 3 &&
									values.ItemsToDeliver.map((item, i) => (
										<div key={i}>
											<Form.Label className="d-block pallet">
												Pallet {i + 1}
											</Form.Label>
											<Row className="product-desc">
												{itemsToDeliverFieldData?.Fields.map((field, index) => {
													if (
														itemsToDeliverFieldData?.Fields.some(
															(data) => data?.FieldName === "Dimensions",
														)
													) {
														return (
															<Col key={index} md={index === 0 ? "6" : "3"}>
																<Form.Label className="d-block">
																	{field.FieldLabel}
																	<span className="text-mandatory">*</span>
																</Form.Label>
																<TextField
																	name={`ItemsToDeliver[${i}].${field.FieldName}`}
																	maxLength={field.FieldLengthMax}
																	data-index={i}
																	onBlur={handleBlur}
																	type="text"
																	autoComplete="off"
																	placeholder={field.WatermarkLabel}
																	NumberOnly={index !== 0}
																	showInstantFeedback
																/>
															</Col>
														);
													} else {
														return (
															<Col
																key={index}
																md={
																	index === 0 ? "12" : index === 4 ? "3" : "2"
																}
																className={
																	index === 1 || index === 2
																		? "pr0 w33"
																		: index === 3 && "w33"
																}
															>
																<Row
																	className={
																		(field.FieldName === "Height" ||
																			field.FieldName === "Weight") &&
																		"pr15"
																	}
																>
																	<Col
																		md={
																			(field.FieldName === "Depth" ||
																				field.FieldName === "Width") &&
																			10
																		}
																		className={
																			(field.FieldName === "Depth" ||
																				field.FieldName === "Width" ||
																				field.FieldName === "Height" ||
																				field.FieldName === "Weight") &&
																			"pr0 w80"
																		}
																	>
																		<TextField
																			className="mb10 dimensions-input"
																			name={`ItemsToDeliver[${i}].${field.FieldName}`}
																			maxLength={field.FieldLengthMax}
																			data-index={i}
																			onBlur={handleBlur}
																			type="text"
																			autoComplete="off"
																			placeholder={field.WatermarkLabel}
																			NumberOnly={index !== 0}
																			showInstantFeedback
																		/>
																		<span className="unit">
																			{field.FieldUnit ? field.FieldUnit : ""}
																		</span>
																	</Col>
																	{(field.FieldName === "Depth" ||
																		field.FieldName === "Width") && (
																		<Col md={2} className="multiply-sign w20">
																			{" "}
																			X
																		</Col>
																	)}
																</Row>
															</Col>
														);
													}
												})}
												<Row className="pl15">
													<Col
														id={"error" + i}
														className="invalid-feedback d-block"
													>
														{errors.ItemsToDeliver?.length > i &&
															props.appendUrl(
																errors.ItemsToDeliver[i]?.Depth ||
																	errors.ItemsToDeliver[i]?.Width ||
																	errors.ItemsToDeliver[i]?.Height ||
																	errors.ItemsToDeliver[i]?.Weight,
															)}
													</Col>
												</Row>
											</Row>
										</div>
									))}
								{/* Multi select dropdown */}
								{itemsToDeliverFieldData.AllowMultiple && UIType === 4 && (
									<Row>
										<Col xs={8} md={6}>
											<Autocomplete
												multiple
												options={DropDownItems ?? []}
												disableCloseOnSelect
												value={[]}
												onChange={(event, newValue) => {
													let index = checkedItems.findIndex(
														(item) => item.Name === newValue[0].Name,
													);
													if (index === -1)
														setCheckedItems([...checkedItems, ...newValue]);
													else
														setCheckedItems(
															checkedItems.filter(
																(item) => item.Name !== newValue[0].Name,
															),
														);
												}}
												id="items-to-deliver-select"
												getOptionLabel={(option) => option.Name}
												getOptionDisabled={(option) =>
													values.ItemsToDeliver.some(
														(item) => item.Name === option.Name,
													)
												}
												renderOption={(option, { selected }) => {
													let checked =
														checkedItems.some(
															(item) => item.Name === option.Name,
														) ||
														values.ItemsToDeliver.some(
															(item) => item.Name === option.Name,
														);
													return (
														<React.Fragment>
															<Checkbox
																icon={icon}
																checkedIcon={checkedIcon}
																style={{ marginRight: 8 }}
																checked={checked}
															/>
															{option.Name}
														</React.Fragment>
													);
												}}
												renderInput={(params) => {
													return (
														<AutoCompleteField
															{...params}
															variant="outlined"
															placeholder={
																values.ItemsToDeliver.length > 0
																	? "Items selected"
																	: "Select item"
															}
															size="small"
															classes="select-input"
															error={
																!(
																	isWeightExceedingMaxLimit ||
																	isVolumeExceedingMaxLimit
																) &&
																touched.ItemsToDeliver &&
																errors.ItemsToDeliver
															}
														/>
													);
												}}
											/>
										</Col>
										<Col xs={2}>
											<Button
												id="add-items-btn"
												type="button"
												onClick={addItems}
												disabled={
													removeIntersection(
														checkedItems,
														values.ItemsToDeliver,
													).length === 0
												}
											>
												ADD
											</Button>
										</Col>
									</Row>
								)}
								{/* For XL tile Partners*/}
								{!itemsToDeliverFieldData.AllowMultiple &&
									UIType === 5 &&
									(() => {
										const onChangeSlabs = async (event, i) => {
											let value = event.target.value.replace(/\D/g, "");
											let { StandardFieldValue } =
												itemsToDeliverFieldData.Fields?.find(
													(item) => item.FieldName === "Weight",
												);
											let { StandardTileWeight, StandardAFrameWeight } =
												StandardFieldValue;
											let newWeight = value
												? String(
														value * StandardTileWeight + StandardAFrameWeight,
												  )
												: "";
											await setFieldValue(
												`ItemsToDeliver[${i}].noOfSlabs`,
												value,
											);
											await setFieldValue(
												`ItemsToDeliver[${i}].Weight`,
												newWeight,
											);
										};
										return values.ItemsToDeliver.map((item, i) => (
											<div key={i}>
												<Form.Label className="d-block pallet">
													Pallet {i + 1}
												</Form.Label>
												<Row className="product-desc">
													{itemsToDeliverFieldData.Fields.map(
														(field, index) => {
															return (
																<Col key={index} md={index === 0 ? "6" : "3"}>
																	<Form.Label className="d-block">
																		{field.FieldLabel}
																		{field.FieldName !== "Weight" && (
																			<span className="text-mandatory">*</span>
																		)}
																	</Form.Label>

																	<Form.Control
																		maxLength={field.FieldLengthMax}
																		data-index={i}
																		onChange={
																			field.FieldName === "noOfSlabs"
																				? (e) => onChangeSlabs(e, i)
																				: handleChange
																		}
																		onBlur={handleBlur}
																		name={`ItemsToDeliver[${i}].${field.FieldName}`}
																		type="text"
																		value={
																			values.ItemsToDeliver[i][field.FieldName]
																		}
																		autoComplete="off"
																		placeholder={field.WatermarkLabel}
																		disabled={!field.IsFieldEditable}
																		isInvalid={
																			errors.ItemsToDeliver?.length > i &&
																			touched.ItemsToDeliver?.length > i &&
																			touched.ItemsToDeliver[i]?.Name &&
																			errors?.ItemsToDeliver[i] &&
																			errors?.ItemsToDeliver[i][field.FieldName]
																		}
																	/>
																</Col>
															);
														},
													)}
													{touched.ItemsToDeliver?.length > i &&
														touched.ItemsToDeliver[i]?.Name &&
														errors.ItemsToDeliver?.length > i && (
															<Row className="pl15">
																<Col
																	id={"error" + i}
																	className="invalid-feedback d-block"
																>
																	{props.appendUrl(
																		errors.ItemsToDeliver[i].Name ||
																			errors.ItemsToDeliver[i].noOfSlabs,
																	)}
																</Col>
															</Row>
														)}
												</Row>
											</div>
										));
									})()}
								{UITypeDesc[UIType]?.showTable &&
									values.ItemsToDeliver.length > 0 && (
										<div>
											<DeliveryDetailsTable
												tableHeader={UITypeDesc[UIType].tableHeader(
													updateQuantity,
												)}
												tableData={values.ItemsToDeliver}
												tableClass={UITypeDesc[UIType].tableClass}
												tableFooter={
													UITypeDesc[UIType]?.tableFooter &&
													UITypeDesc[UIType].tableFooter(DropDownItems, {
														totalItemsWeight,
														MaxItemWeight,
														totalItemsVolume,
														MaxItemVolume,
													})
												}
											/>
										</div>
									)}

								{/* Error message */}
								<div className="invalid-feedback d-block">
									{!Array.isArray(errors[UITypeDesc[UIType]?.name]) &&
									touched[UITypeDesc[UIType]?.name] &&
									errors[UITypeDesc[UIType]?.name]
										? errors[UITypeDesc[UIType]?.name]
										: ""}
								</div>
							</FormGroup>

							{/* specialInstructions */}
							<TextFieldWithLabel
								label={specialInstructionsFieldData.DisplayLabel}
								maxLength={specialInstructionsMaxLength}
								name="SpecialInstructions"
								type="text"
								autoComplete="off"
								placeholder={specialInstructionsFieldData.WatermarkLabel}
								onBlur={handleBlur}
								as="textarea"
								rows={2}
								showRemainingCharCount
							/>

							{/* Static fields : Pickup & Delivery Information */}
							<h2>Pickup &amp; Delivery Information</h2>
							<Row>
								<Col sm="6">
									<TextFieldWithLabel
										groupClass="mb-3"
										label={staticFields[0].DisplayName}
										IsRequired={staticFields[0].IsRequired}
										maxLength={100}
										PlaceHolder={staticFields[0].PlaceHolder}
										name={staticFields[0].MappingDBFieldName}
										type="text"
										autoComplete="off"
										onBlur={handleBlur}
									/>
								</Col>

								<FieldArray
									name={staticFields[1].MappingDBFieldName}
									render={(arrayHelpers) =>
										values.CustomerMobile.map((mob, index) => (
											<Col
												sm="6"
												className={
													AddMultipleCustomerPhoneNumber ? "cust-phone" : ""
												}
												key={index}
											>
												<FormGroup
													className={
														AddMultipleCustomerPhoneNumber ? "mb-0" : ""
													}
												>
													<FormLabel>
														{index === 0 ? (
															<>
																{staticFields[1].DisplayName}
																{staticFields[1].IsRequired && (
																	<span className="text-mandatory">*</span>
																)}
																{staticFields[1].PlaceHolder && (
																	<i className="custom-placeholder">
																		({staticFields[1].PlaceHolder})
																	</i>
																)}
															</>
														) : (
															<>
																Mobile Phone
																<span className="text-mandatory">*</span>
																<i className="custom-placeholder">
																	(We'll also text this number delivery updates)
																</i>
															</>
														)}
													</FormLabel>
													<PhoneInput
														name={`CustomerMobile.${index}`}
														type="text"
														autoComplete="off"
														onBlur={handleBlur}
													/>
													<FormControl.Feedback type="invalid">
														{index === 0
															? "Customer mobile "
															: `Recipient ${index} `}
														{errors.CustomerMobile?.length > index &&
															errors.CustomerMobile[index]}
													</FormControl.Feedback>
													{index > 0 ? (
														<img
															src={xicon}
															alt="Remove"
															onClick={() => {
																arrayHelpers.remove(index)
																dispatch(setInitialStates({
																	...values,
																	CustomerMobile : values.CustomerMobile.filter((item, i)=> i!==index)
																}))
															}}
															className="close"
														/>
													) : AddMultipleCustomerPhoneNumber ? (
														<span
															className={
																"add-btn" +
																(values.CustomerMobile.length === 4
																	? " disabled"
																	: "")
															}
															title="Add additional recipients for text updates"
															onClick={async () =>{
																if(values.CustomerMobile.length < 4) {
																	arrayHelpers.insert(
																		values.CustomerMobile.length,
																		"",
																	)
																	dispatch(setInitialStates({
																		...values,
																		CustomerMobile : [...values.CustomerMobile, ""]
																	}))
																}
															}
															}
														>
															ADD SMS RECIPIENTS
														</span>
													) : (
														<></>
													)}
												</FormGroup>
											</Col>
										))
									}
								/>
							</Row>
							<Row>
								{PartnerLocationSettings.StaticFields?.map((field, index) => (
									<Col sm="6">
										<TextFieldWithLabel
											groupClass="mb-3"
											label={field.DisplayName}
											IsRequired={field.IsRequired}
											PlaceHolder={field.PlaceHolder}
											ControlType={field.ControlType}
											ControlValues={field.ControlValues}
											PhoneInput={field.FieldDataType === "phone"}
											maxLength={field.MaxLength}
											name={`StaticFields[${index}].FieldValue`}
											type="text"
											autoComplete="off"
											onBlur={handleBlur}
											staticField
										/>
									</Col>
								))}
							</Row>

							{PartnerLocationSettings.CustomFields?.length > 0 && (
								<>
									<h2>Additional Information</h2>
									<Row className="customFields">
										{PartnerLocationSettings.CustomFields.map(
											(field, index) => (
												<Col sm="6" key={field.FieldRef}>
													<TextFieldWithLabel
														label={field.DisplayName}
														IsRequired={field.IsRequired}
														PlaceHolder={field.PlaceHolder}
														ControlType={field.ControlType}
														ControlValues={field.ControlValues}
														PhoneInput={field.FieldDataType === "phone"}
														maxLength={100}
														name={`CustomFields[${index}].FieldValue`}
														type="text"
														autoComplete="off"
														onBlur={handleBlur}
														staticField
													/>
												</Col>
											),
										)}
									</Row>
								</>
							)}
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default DeliveryDetailsForm;
