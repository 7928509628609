import moment from 'moment-timezone';
import globalConstants from '../../shared/appConfig/globalConstants';
/* Redux Import Start */
import { store } from '../redux/store';
import { dateUtils } from '../utilities/dateUtils';
import { getStartTimeWithLeadTime } from './getStartTimeWithLeadTime';
import { getWorkingHours } from './getWorkingHours';

export const DeliveryTimeRange = (selectedTime = '', estTime = '') => {
    const { serviceLevel, pickupTime } = store.getState().getEstimate;
    let time = (selectedTime) ? selectedTime : pickupTime;
    const currentPlusTotal = moment(time, globalConstants.HourFormat).add(estTime ? estTime : serviceLevel['TotalPickupTime'], 'minutes');
    return nearestMinutes(globalConstants.RoundToNearestMinuteValue, currentPlusTotal);
}

/**
 * nearestMinutes()
 * This function is used to find the nearest 5 minutes of the selected time
 */
export const nearestMinutes = (interval, someMoment) => {
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    let teletTime = someMoment.clone().minute(roundedMinutes).second(0).format(globalConstants.HourFormat);
    let minTime = moment(teletTime, globalConstants.HourFormat).subtract(globalConstants.LowerTimeMins, 'minutes').format(globalConstants.HourFormat);
    let maxTime = moment(teletTime, globalConstants.HourFormat).add(globalConstants.UpperTimeMins, 'minutes').format(globalConstants.HourFormat);
    return `${minTime} - ${maxTime}`;
}

/**
 * getSlotsForTheDay()
 * This function is used to find the available time slots for the selected date
 */
export const getSlotsForTheDay = (totalSlots, todaysDate) => {
    const availableTimeSlots = [];
    const {WorkingHoursStart, WorkingHoursEnd} = getWorkingHours();
    const min = WorkingHoursStart;
    const max = WorkingHoursEnd;
    const datePassed = new Date(todaysDate);
    const newDate = `${datePassed.getFullYear()}/${('0' + (datePassed.getMonth() + 1)).slice(-2)}/${('0' + datePassed.getDate()).slice(-2)}`
    const minTime = moment(min).utc().format(globalConstants.HourFormat2);
    const minSlotTime = dateUtils.getTimeStampSafari(`${newDate} ${minTime}`);

    const maxTime = moment(max).utc().format(globalConstants.HourFormat2);
    const maxSlotTime = dateUtils.getTimeStampSafari(`${newDate} ${maxTime}`);
    const currentTimeWithBuffer = getStartTimeWithLeadTime();
    totalSlots.map((item) => {
        // Get slot Time ( EPOCH time value )
        const slotDate = item['slot_date'].substr(0, item['slot_date'].indexOf('T')).replace(/-/g, "/");
        const slotTime = dateUtils.getTimeStampSafari(`${slotDate} ${item['time_slot_from']}`);
        // Display all time slots greater than ( current Time + Buffer Time ) where todaysDate is pickup date
        if(moment.utc(item['slot_date']).format(globalConstants.DateFormat2) === newDate && slotTime > currentTimeWithBuffer && slotTime >= minSlotTime && slotTime <= maxSlotTime){
            availableTimeSlots.push(item);
        }
        return true;
    })
    return availableTimeSlots;
}


