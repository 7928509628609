import moment from 'moment-timezone';
import {store} from '../redux/store';

export const dateUtils = {
    convertTimezone: (dateTimeInUTC, timezoneIdentifier, requiredFormat) => {
        return moment.utc(dateTimeInUTC).tz(timezoneIdentifier).format(requiredFormat);
    },

    getDate: date => {
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    },

    getDatePickerDate: (toConvertDate, val="", workinghours) => {
        let { timeZone } = store.getState().getEstimate;
        if(!timeZone) timeZone = moment.tz.guess()
        const newDate = moment(dateUtils.getDate(toConvertDate)).tz(timeZone).format('MM-DD-YYYY');
        const today = moment(new Date()).tz(timeZone).format('MM-DD-YYYY');
        const tomorrow = moment(new Date()).tz(timeZone).add(1,'days').format('MM-DD-YYYY');

        if(newDate === today) return moment(dateUtils.getDate(toConvertDate)).format('[Today] (MMM D)');
        if(newDate === tomorrow) return moment(dateUtils.getDate(toConvertDate)).format('[Tomorrow] (MMM D)');
        return moment(dateUtils.getDate(toConvertDate)).format('dddd (MMM D)');
    },

    getDatePickerDateValues: (toConvertDate, val="", workinghours) => {
        if(toConvertDate){
            return moment(dateUtils.getDate(toConvertDate)).format('MM DD YYYY')
        } else {
            return moment().format('MM DD YYYY')
        }
    },

    getTimeStamp: (input) => {
        const parts = input.trim().split(' ');
        const date = parts[0].split('-');
        const time = (parts[1] ? parts[1] : '00:00:00').split(':');
    
        const d = new Date(date[0],date[1]-1,date[2],time[0],time[1],time[2]);
        return d.getTime() / 1000;
    },

    getTimeStampSafari: (input) => {
        const parts = input.trim().split(' ');
        const date = parts[0].split('/');
        const time = (parts[1] ? parts[1] : '00:00:00').split(':');
    
        const d = new Date(date[0],date[1]-1,date[2],time[0],time[1],time[2]);
        return d.getTime() / 1000;
    },
    
    getYYMMDD: (date) => date.split('/').reverse().join("-"),
    
    getYYMMDDSafari: (date) => date.split('/').reverse().join("/"),

    millisecsToTime: (duration) => {
        let seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
      
        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;
      
        return hours + ":" + minutes + ":" + seconds ;
    },

    timeToMilliSeconds: (h, m, s) => ((h*60*60+m*60+s)*1000)

}
