export const getAddress = (place) => {

    const addressArray = place.address_components,
        address1 = getAddress1(addressArray),
        address2 = getAddress2(addressArray),
        city = getCity(addressArray),
        country = getCountry(addressArray),
        latLng = getLatLng(place.geometry.location),
        state = getState(addressArray),
        zipCode = getZipCode(addressArray);

    let FullAddressObj = {
        'AddressId': place.place_id,
        'Address1': address1,
        'Address2': address2,
        'City': city,
        'Country': country,
        'Location': latLng,
        'State': state,
        'ZipPostalCode': zipCode
    }
    if (FullAddressObj.Address1 === null || FullAddressObj.Address1 === "") {
        FullAddressObj.Address1 = FullAddressObj.Address2;
        FullAddressObj.Address2 = "";
    }

    if (!FullAddressObj.Address1 && !FullAddressObj.Address2) {
        return false
    } else if (!FullAddressObj.City) {
        return false
    } else if (!FullAddressObj.ZipPostalCode) {
        return false
    } else {
        const FullAddress = FullAddressObj.Address1 + ', ' + (FullAddressObj.Address2 ? (FullAddressObj.Address2 + ', ') : '') + FullAddressObj.City + ', ' + (FullAddressObj.State ? (FullAddressObj.State + ', ') : '') + FullAddressObj.ZipPostalCode;
        return {
            FullAddressObj: FullAddressObj,
            FullAddress: FullAddress
        };
    }
}

export const formatAddress = address => {
    return (address.Address1 + ', ' + (address.Address2 ? (address.Address2 + ', ') : '') + address.City + ', ' + (address.Country ? (address.Country + ', ') : '') + (address.State ? (address.State + ', ') : '') + address.ZipPostalCode);
}

const getAddress1 = (addressArray) => {
    let address1 = '';
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if ('establishment' === addressArray[i].types[j]) {
                    address1 = addressArray[i].long_name;
                } if ('street_number' === addressArray[i].types[j]) {
                    address1 += ' ' + addressArray[i].long_name;
                } if ('street_address' === addressArray[i].types[j]) {
                    address1 += ' ' + addressArray[i].long_name;
                } if ('route' === addressArray[i].types[j]) {
                    address1 += ' ' + addressArray[i].long_name;
                }if ('clothing_store' === addressArray[i].types[j]) {
                    address1 += ' ' + addressArray[i].long_name;
                }
            }
        }
    }
    return address1.trim();

};

const getAddress2 = (addressArray) => {
    let address2 = '';
    let found = false;
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if ('sublocality_level_1' === addressArray[i].types[j]) {
                    found = true;
                    address2 = addressArray[i].long_name;
                    break;
                } else if ('sublocality' === addressArray[i].types[j]) {
                    found = true;
                    address2 = addressArray[i].long_name;
                    break;
                }
            }
            if (found) {
                break;
            }
        }
    }
    return address2
};

const getCity = (addressArray) => {
    let city = '';
    
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if ('locality' === addressArray[i].types[j]) {
                    city = addressArray[i].long_name;
                    break;
                } else if ('neighborhood' === addressArray[i].types[j]) {
                    city = addressArray[i].long_name;
                    break;
                } else if ('administrative_area_level_3' === addressArray[i].types[j]) {
                    city = addressArray[i].long_name;
                    break;
                }
            }
        }
    }
    return city
}
const getCountry = (addressArray) => {
    let country = '';

    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if ('country' === addressArray[i].types[j]) {
                    country = addressArray[i].long_name;
                }
            }
        }
    }
    return country
}

const getLatLng = (LatLngObj) => {
    let latLng = {
        Latitude: LatLngObj.lat(),
        Longitude: LatLngObj.lng()
    }

    return latLng
}

const getState = (addressArray) => {
    let state = '';

    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if ('administrative_area_level_1' === addressArray[i].types[j]) {
                    state = addressArray[i].long_name;
                }
            }
        }
    }
    return state
}

const getZipCode = (addressArray) => {
    let zipCode = '';

    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0]) {
            for (let j = 0; j < addressArray[i].types.length; j++) {
                if ('postal_code' === addressArray[i].types[j]) {
                    zipCode = addressArray[i].long_name;
                }
            }
        }
    }
    return zipCode
}

export const getFullAddress = (FullAddressObj) => {
    try {
        let FullAddress = FullAddressObj.Address1 + ', ' + (FullAddressObj.Address2 ? (FullAddressObj.Address2 + ', ') : '') + FullAddressObj.City + ', ' + (FullAddressObj.State ? (FullAddressObj.State + ', ') : '') + FullAddressObj.ZipCode;
        return FullAddress;
    }
    catch (err) {
        console.log(err);
    }
}

export const getFullAddress2 = (FullAddressObj) => {
    let FullAddress =
        (FullAddressObj.address1 ? FullAddressObj.address1 + ", " : "") +
        (FullAddressObj.address2 ? FullAddressObj.address2 + ", " : "") +
        (FullAddressObj.city ? FullAddressObj.city + ", " : "") +
        (FullAddressObj.state ? FullAddressObj.state + ", " : "") +
        (FullAddressObj.zipcode ?? "");
    return FullAddress;
};