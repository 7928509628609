import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import languageConstants from "../../shared/appConfig/languageConstants";
import { currencyUtils } from "../../shared/utilities/currencyUtils";
import TooltipOverlay from "../../pages/TooltipOverlay";

/* Redux Import Start */
import { connect } from "react-redux";
import {
	setServiceLevel,
	setDeliveryTime,
	setDistance,
} from "../../shared/redux/actions/getEstimate.actions";
import { setShowLoader } from "../../shared/redux/actions/common.actions";

/* Redux Import End */

// var moment = require('moment-timezone');
const styles = {
	pickupTimeOptions: {
		height: "300px",
	},
	serviceOptions: {
		maxHeight: "320px",
		maxWidth: "540px",
	},
	serviceOptionsWidth: {
		// width: "540px",
	},
};

class ServiceLevel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuCost: "d-none",
			serviceTitle: "service-title",
		};
		this.serviceLevelAddRef = React.createRef();
	}

	/**
	 * showCost()
	 * On click of dropdown show the cost.
	 */
	showCost = () => {
		this.setState({
			menuCost: "d-show",
		});
	};

	/**
	 * hideCost()
	 * On click of dropdown hide the cost.
	 */
	hideCost = () => {
		this.setState({
			menuCost: "d-none",
		});
	};

	render() {
		const { serviceLevel, serviceLevelValues, classes, showPrice , title} = this.props;
		return (
			<React.Fragment>
				<Form.Group className="service-level-dropdown">
					<Row className="col-padding">
						<Col>
							<Form.Label>
								Service Level
								{serviceLevelValues.length > 0 && (
									<React.Fragment>
										<i
											onClick={this.props.setTooltip.bind(
												this,
												"showServiceLevelTooltip"
											)}
											ref={this.serviceLevelAddRef}
											className="infoIcon"
										/>
										<TooltipOverlay
											target={this.serviceLevelAddRef.current}
											showTooltip={this.props.showServiceLevelTooltip}
											hideTooltip={this.props.hideTooltip.bind(
												this,
												"showServiceLevelTooltip"
											)}
										>
											<p>
												What is the level of service that should be performed
												for this delivery?
											</p>
										</TooltipOverlay>
									</React.Fragment>
								)}
							</Form.Label>
							{serviceLevelValues && serviceLevelValues.length > 0 ? (
								<FormControl
									variant="outlined"
									className={
										this.props.highlighter[0].addrHLDelivery ||
										this.props.highlighter[1].addrHLSolo ||
										this.props.highlighter[2].addrHLDuo
											? "addHighliter"
											: ""
									}
									key={serviceLevel?.ServiceName}
								>
									{serviceLevelValues && serviceLevelValues.length < 2 ? (
										<div className="service-data">
											<div className="service-title">
												{serviceLevel && serviceLevel.ServiceName}
											</div>
											<span className="service-desc">
												{serviceLevel && serviceLevel.PartnerServiceDescription}
											</span>
										</div>
									) : (
										<Select
											MenuProps={{
												classes: {
													list:
														serviceLevelValues.length > 3
															? "service-menu " + classes.serviceOptions
															: "service-menu " + classes.serviceOptionsWidth,
												},
											}}
											required
											displayEmpty
											value={
												serviceLevel != null ? serviceLevel.ServiceRef : ""
											}
											onChange={this.props.setServiceValue}
											size="small"
											onOpen={this.showCost}
											onClose={this.hideCost}
											native={false}
											id="service-level-menu"
											autoWidth
											className={this.props.errors ? "invalid" : ""}
										>
											<MenuItem value="" disabled>
												Select Service
											</MenuItem>
											{serviceLevelValues &&
												serviceLevelValues.map((e, key) => (
													<MenuItem key={key} value={e.ServiceRef}>
														<Row className="col-padding">
															<Col xs="10">
																<span
																	className={
																		this.state.serviceTitle +
																		" d-block space-below"
																	}
																	data-name={e.ServiceName}
																>
																	{e.ServiceName}
																</span>
																<span
																	className=" wrap-desc"
																	data-name={e.ServiceName}
																>
																	{e.PartnerServiceDescription}
																</span>
															</Col>
															<Col xs="2">
																{(showPrice || title === "quoteOnly") &&  <span
																	className={
																		this.state.menuCost +
																		" service-cost float-right"
																	}
																>
																	{currencyUtils.formatCurrency(e.ServiceCost)}
																</span>}
															</Col>
														</Row>
													</MenuItem>
												))}
										</Select>
									)}
								</FormControl>
							) : (
								<div>
									<div className="service-title">
										{languageConstants.serviceLevel.noService.serviceName}
									</div>
									<span className="service-desc">
										{
											languageConstants.serviceLevel.noService
												.serviceDescription
										}
									</span>
								</div>
							)}
						</Col>
					</Row>
					<div className="invalid-feedback d-block">{this.props.errors}</div>
				</Form.Group>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(
	connect(
		({ getEstimate: { serviceLevel, serviceLevelValues },
			common: { showPrice }, }) => ({
			serviceLevel,
			serviceLevelValues,
			showPrice,
			
		}),
		(dispatch) => ({
			setServiceLevel: (serviceLevel) =>
				dispatch(setServiceLevel(serviceLevel)),
			setShowLoader: (showLoader) => dispatch(setShowLoader(showLoader)),
			setDeliveryTime: (deliveryTime) =>
				dispatch(setDeliveryTime(deliveryTime)),
			setDistance: (distance) => dispatch(setDistance(distance)),
		})
	)(ServiceLevel)
);
