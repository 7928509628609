import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../shared/layouts/common/Footer";
import logo from "../assets/images/logo.png";
import success from "../assets/images/success.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import moment from "moment-timezone";
import languageConstants from "../shared/appConfig/languageConstants";
import floorFoundLogo from "../assets/images/Floor_Found_logo.png";
import { getFullAddress2 } from '../shared/modules/Address';

const ScheduleSuccess = () => {
	const { state } = useLocation();
	let history = useHistory();

	useEffect(() => {
		if (!state?.window) {
			history.push("/page-not-found");
		}
	});

	return (
		<React.Fragment>
			<main className="main-wrapper show-delivery-status" id="main-wrapper">
				{/* Header */}
				<header className="delivery-status-header">
					<Container>
						<Row>
							<Col
								className="pr-0 partner-logo d-flex align-items-center"
								md="auto"
							>
								<img src={floorFoundLogo} alt="Partner Logo" />
							</Col>
							<Col className="pl-0 powered-by align-self-center d-block">
								<img src={logo} width="93" className="logo" alt="Bungii Logo" />
							</Col>
						</Row>
					</Container>
				</header>

				<Container className="main-container detailed-information">
					<Row>
						<Col
							xs="12"
							md="4"
							className="d-flex justify-content-center"
						>
							<img width="auto" height="248" src={success} alt="Partner Logo" />
						</Col>
						<Col xs="12" md="6" className="pl-md-3">
							<Row>
								<Col>
									<h1 className="heading">
										Thank you! <br />
										Your pickup has been scheduled.
									</h1>
									<span className="support-text">
										{languageConstants.deliveryWindow.supportInfoText}{" "}
										<a href="tel:+9806555006">(980) 655-5006</a> or email{" "}
										<a href="mailto:support@bungii.com">support@bungii.com</a>
									</span>
								</Col>
							</Row>
							<Row>
								<Col>
									<section className="customer-summary-container grey mt-2">
										<div className="mb-3">
											<label>ORDER ID: </label>{" "}
											<span>{state?.externalId}</span>
										</div>
										<div>
											<label>Pickup Date & Time:</label>
											<p>
												{moment(state?.window?.pickupTime).tz(state?.timezone).format(
													"MMMM D, YYYY",
												)}{" "}
												between{" "}
												{moment(state?.window?.startTime).tz(state?.timezone).format("hh:mm A")} -{" "}
												{moment(state?.window?.endTime).tz(state?.timezone).format("hh:mm A")}
											</p>
										</div>
										<div>
											<label>Pick up items from:</label>
											<p>
												{state?.servicePickupLevel}
											</p>
										</div>
										<div>
											<label>Pickup Address:</label>
											<p>{getFullAddress2(state?.pickupWaypoint)}</p>
										</div>
									</section>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>

				<Footer />
			</main>
		</React.Fragment>
	);
};

export default ScheduleSuccess;
