import globalConstants from "./globalConstants";
import base64EncodedString from "../utilities/base64Encode";

const prcServiceBaseURL = process.env.REACT_APP_PRC_SERVICE_BASE_URL;
const partnerServiceBaseURL = process.env.REACT_APP_PARTNER_SERVICE_BASE_URL;
const authServiceBaseURL = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;
const reportingServiceBaseURL = process.env.REACT_APP_REPORTING_SERVICE_BASE_URL;
const paymentServiceBaseURL = process.env.REACT_APP_PAYMENT_SERVICE_BASE_URL;
const deliveryServiceBaseURL = process.env.REACT_APP_DELIVERY_SERVICE_BASE_URL;
const infraServiceBaseURL = process.env.REACT_APP_INFRA_BASE_URL;

const urlConstants = {
    GoogleMap_URL: `https://maps.googleapis.com/maps/api/js?key=${globalConstants.GoogleMap_Key}&libraries=places`,
    Authorization: globalConstants.AppKey && globalConstants.AppSecret 
        ? `Basic ${base64EncodedString.base64Encode(globalConstants.AppKey, globalConstants.AppSecret)}` 
        : '',
    login: `${authServiceBaseURL}/api/businesspartner/login`,
    appSettings: `${partnerServiceBaseURL}/api/partner/settings`,
    pickupEstimate: `${prcServiceBaseURL}/api/partner/pickupestimate`,
    getpartnerlocationforsubdomain: `${partnerServiceBaseURL}/api/partner/getpartnerlocationforsubdomain`,
    pickupDetails: `${reportingServiceBaseURL}/api/partner/pickupdetails`,
    deliveryInformation: `${prcServiceBaseURL}/api/partner/deliveryinformation`,
    getPaymentToken: `${paymentServiceBaseURL}/api/payment/gettokenforpartner`,
    confirmpickup: `${prcServiceBaseURL}/api/partner/confirmpickup`,
    dashboardMetrics: `${reportingServiceBaseURL}/api/partner/dashboardmetrics`,
    deliveryList: `${reportingServiceBaseURL}/api/partner/deliveries`,
    tripDetails: `${reportingServiceBaseURL}/api/partner/deliverydetails`,
    deliveryListDetails: `${prcServiceBaseURL}/api/partner/deliverystatuslist`,
    cancelPickup: `${prcServiceBaseURL}/api/partner/cancelpickup`,
    logout: `${authServiceBaseURL}/api/businesspartner/logout`,
    authorizeuser: `${authServiceBaseURL}/api/businesspartner/authorizeuser`,
    serviceLevels: `${partnerServiceBaseURL}/api/partner/servicelevels`,
    quickaccesslogin: `${authServiceBaseURL}/api/businesspartner/quickaccesslogin`,
    getSkuData: `${partnerServiceBaseURL}/api/partner/bestBuyItemDetails/`,
    deliveryStatus: `${prcServiceBaseURL}/Pickup/`,
    ratedriver: `${prcServiceBaseURL}/api/pickup/ratedriver`,
    storeTimeSlots: `${prcServiceBaseURL}/api/partner/storeSlots`,
    generateReports: `${reportingServiceBaseURL}/api/partner/getdeliverybydaterange`,
    callDriver: `${prcServiceBaseURL}/api/device/partnerscheduledtripvoicerequest`,
    getWaypoints: `${deliveryServiceBaseURL}/delivery-api/delivery`,
    getWindows: `${deliveryServiceBaseURL}/delivery-api/delivery/window`,
    getAppSettings : `${infraServiceBaseURL}/api/infra/getkeys`,
}

export default urlConstants;
